import axios from 'axios';
import aut from '../auth/aut';
import config from './constant';

var User = aut.authData ();

var UserToken = User ? User.Token : null;

const Request = {
  postMethod (action, object) {
    let accessToken = UserToken;

    const url = config.url + action;

    let formData = new FormData ();

    // append your data
    for (var key in object) {
      formData.append (key, object[key]);
    }

    // By Default Id and token

    formData.append ('token', accessToken);
    formData.append ('user_id', UserToken ? User.id : null);
    // By Default added device type and login type in future use
    /*
        if (!socialLoginUser) {
            formData.append("login_by", config.LOGIN_BY);
        }
        */

    //formData.append("device_type", config.DEVICE_TYPE);
    //formData.append("device_token", config.DEVICE_TOKEN);

    return axios.post (url, formData, {
      headers: {
        Authorization: `Bearer ${UserToken}`,
      },
    });
  },

  postMethodAdmin (action, object) {
    let accessToken = UserToken;

    var url = config.adminUrl + action;

    if (object.full_url) {
      url = object.full_url;
    }

    let formData = new FormData ();

    // append your data
    for (var key in object) {
      formData.append (key, object[key]);
    }

    // By Default Id and token

    formData.append ('token', accessToken);
    formData.append ('user_id', UserToken ? User.id : null);

    if (!UserToken && object.user_id) {
      formData.append ('user_id', object.user_id);
    }

    // By Default added device type and login type in future use
    /*
        if (!socialLoginUser) {
            formData.append("login_by", config.LOGIN_BY);
        }
        */

    //formData.append("device_type", config.DEVICE_TYPE);
    //formData.append("device_token", config.DEVICE_TOKEN);

    return axios.post (url, formData, {
      headers: {
        Authorization: `Bearer ${UserToken}`,
      },
    });
  },

  getMethod (action) {
    const url = config.url + action;

    // console.log(url)

    //let formData = new FormData();

    // By Default Id and token

    //formData.append("id", userId);
    //formData.append("token", accessToken);

    // append your data
    /*
         for (var key in object) {
             formData.append(key, object[key]);
         }
         */

    // By Default added device type and login type in future use

    /*
        formData.append("login_by", apiConstants.LOGIN_BY);
        formData.append("device_type", apiConstants.DEVICE_TYPE);
        formData.append("device_token", apiConstants.DEVICE_TOKEN);
        */
    return axios.get (url, {headers: {Authorization: `Bearer ${UserToken}`}});
  },

  postMethodFullUrl (url, object) {
    let accessToken = UserToken;

    let formData = new FormData ();

    // append your data
    for (var key in object) {
      formData.append (key, object[key]);
    }

    // By Default Id and token

    formData.append ('token', accessToken);
    formData.append ('user_id', UserToken ? User.id : null);

    if (!UserToken && object.user_id) {
      formData.append ('user_id', object.user_id);
    }

    // By Default added device type and login type in future use
    /*
        if (!socialLoginUser) {
            formData.append("login_by", config.LOGIN_BY);
        }
        */

    //formData.append("device_type", config.DEVICE_TYPE);
    //formData.append("device_token", config.DEVICE_TOKEN);

    return axios.post (url, formData, {
      headers: {
        Authorization: `Bearer ${UserToken}`,
      },
    });
  },

  /*methods(action) {

        const url = apiUrl+'/api/'+action;

        return {
            getOne: ({ id }) => axios.get(`${url}/${id}`),
            getAll: (toGet) => axios.post(url, toGet),
            update: (toUpdate) =>  axios.put(url,toUpdate),
            create: (toCreate) =>  axios.put(url,toCreate),
            delete: ({ id }) =>  axios.delete(`${url}/${id}`)
        }
    }*/
};

export default Request;
