import auth from '../auth/aut';

const Permission = () => {
  return (
    auth.authData () &&
    auth.authData ().permission &&
    JSON.parse (auth.authData ().permission).includes (1)
  );
};

export default Permission;
