import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Table, Button, Modal} from 'react-bootstrap';
import Sidebar from '../Sidebar';
import {
  faSkype,
  faInstagram,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';

import {faExclamationTriangle} from '@fortawesome/fontawesome-free-solid';
import AdminHeader from '../AdminHeader';
import '../../assets/css/dashboard.css';
import Request from '../utls/request';
import config from '../utls/constant';
import placeholder from '../../assets/images/placeholder.jpeg';
import auth from '../auth/aut';
import ToastObject from '../shared/toast';

import LoadingOverlay from 'react-loading-overlay';
import axios from 'axios';
import {Link, useHistory} from 'react-router-dom';
import {ExportToExcel} from '../products/ExportToExcel';
import ReactPaginate from 'react-paginate';
import Permission from '../shared/adminPermissionCheck';
import country from '../utls/country';
import PaginatedItems from '../reusableComponent/Pagination';

var User = auth.authData ();

var UserToken = User ? User.Token : null;

const UsersManager = () => {
  const [data, setData] = useState ([]);
  const [show, setShow] = useState (false);

  const [user_id, setUserId] = useState (false);

  const [show_entries, setShowEntries] = useState (20);

  const [isActive, setIsActive] = useState (true);

  //pagination
  const [itemOffset, setItemOffset] = useState (0);
  const [pageCount, setPageCount] = useState (0);
  const [currentItems, setCurrentItems] = useState ([]);

  const [next_page, setNextPage] = useState ([]);

  const [last_select, setSelect] = useState ({
    value: new Date ().getFullYear (),
    name: 'year',
  });

  //const [sort_by, setSortBy] = useState();
  const handleClose = () => setShow (false);

  const confirmDelete = id => {
    setShow (true);
    setUserId (id);
  };

  const history = useHistory ();

  const handleDetails = id => {
    history.push (`/userview/${id}`);
  };

  const getCountry = code => {
    const item = country.countries.country.filter (item => {
      return item.countryCallingCodes[0] === code;
    });

    return item.length > 0 ? item[0].name : 'N/A';
  };

  ///getCountry ('+256');

  const deleteUser = () => {
    setShow (false);
    Request.getMethod (`delete-user/${user_id}`)
      .then (response => {
        ToastObject.ToastSuccess ('User deleted Succesfully!!');
        const object = new Date ();

        getUser ({value: object.getFullYear (), name: 'year'}, 20);

        setShow (false);

        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        console.log (error.response);
      });
  };

  const ShowBy = e => {
    const value = e.target.value;
    setIsActive (true);
    setShowEntries (value);
    setData ([]);
    setCurrentItems ([]);
    getUser (last_select, value * 3);
  };

  const searchUser = e => {
    if (e.target.value.length == 0) {
      const object = new Date ();
      getUser ({value: object.getFullYear (), name: 'year'}, 20);
    }

    if (e.target.value.length >= 3) {
      const body = {
        key: e.target.value,
        user: true,
      };
      Request.postMethod ('search-users', body).then (response => {
        //setData (response.data.user);
        setCurrentItems (response.data.user);
      });
    }
  };

  const exportUsers = () => {
    axios
      .get (`${config.url}export-user`, {
        headers: {
          Authorization: `Bearer ${UserToken}`,
          responseType: 'blob',
        },
      })
      .then (response => {
        console.log (response);
        let blob = new Blob ([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        let link = document.createElement ('a');
        link.href = window.URL.createObjectURL (blob);
        link.download = 'users.xlsx';
        link.click ();
      })
      .catch (error => {
        console.log (error.response);
      });
  };

  const selectSort = e => {
    const object = new Date ();
    const val = e.target.value;

    setData ([]);
    setCurrentItems ([]);

    if (val == 'last_year') {
      setSelect ({value: object.getFullYear () - 1, name: 'year'});

      getUser (
        {value: object.getFullYear () - 1, name: 'year'},
        show_entries * 3
      );
      //alert(year)
      return;
    }

    if (val == 'this_all') {
      setSelect ({value: object.getFullYear (), name: 'all'});
      getUser ({value: object.getFullYear (), name: 'all'}, show_entries * 3);
      return;
    }

    if (val == 'this_year') {
      setSelect ({value: object.getFullYear (), name: 'year'});
      getUser ({value: object.getFullYear (), name: 'year'}, show_entries * 3);
      return;
    }

    if (val == 'this_month') {
      //setMonth()
      setSelect ({
        value: object.getMonth () + 1,
        name: 'month',
      });
      getUser (
        {
          value: object.getMonth () + 1,
          name: 'month',
        },
        show_entries * 3,
        object.getFullYear ()
      );
      return;
    }
    if (val == 'last_month') {
      setSelect ({value: object.getMonth (), name: 'month'});
      getUser (
        {value: object.getMonth (), name: 'month'},
        show_entries * 3,
        object.getFullYear ()
      );
      return;
    }

    if (val == 'today') {
      //setToday();
      setSelect ({value: object.getDate (), name: 'today'});
      getUser ({value: object.getDate (), name: 'today'}, show_entries);
      return;
    }
    if (val == 'online') {
      const time = `${object.getHours ()}:${object.getMinutes () < 10 ? '0' + object.getMinutes () : object.getMinutes ()}: ${object.getSeconds () < 10 ? '0' + object.getSeconds () : object.getSeconds ()}`;

      setSelect ({
        value: time,
        name: 'online',
      });
      getUser (
        {
          value: time,
          name: 'online',
        },
        show_entries * 3
      );
      return;
    }
  };

  const getUserPaginate = (select_data, filter_count, item, url) => {
    // alert(data.length)

    if (item) {
      var body = {
        [select_data.name]: select_data.value,
        show_entries: filter_count,
        year: item,
      };
    } else {
      var body = {
        [select_data.name]: select_data.value,
        show_entries: filter_count,
      };
    }

    // alert("Outside")

    //console.log (body);

    setData (null);
    setIsActive (true);

    Request.postMethodFullUrl (url, body)
      .then (response => {
        /// console.log (response, 'mundruku');

        setNextPage (response.data.user.next_page_url);
        // console.log (response.data.user.data);

        // console.log(data ,'....')

        const newArray = [...data, ...response.data.user.data];

        setData (newArray);

        //console.log (newArray);
        // paginateHelper (newArray);
        //console.log (newArray);

        //alert('ok')
        setIsActive (false);
        ////alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        console.log (error);
      });
  };

  const getUser = (data, filter_count, item) => {
    //alert (filter_count);
    //alert("Trecking")
    if (item) {
      var body = {
        [data.name]: data.value,
        show_entries: filter_count,
        year: item,
      };
    } else {
      var body = {
        [data.name]: data.value,
        show_entries: filter_count,
      };
    }

    //console.log (body);

    setData (null);

    setIsActive (true);

    Request.postMethod ('get-users', body)
      .then (response => {
        console.log (response.data);
        setData (response.data.user.data);
        //console.log(data)

        setNextPage (response.data.user.next_page_url);
        // console.log (response.data.user.data);

        if (data.name == 'all') {
          setCurrentItems (response.data.user.data);
        } else {
          paginateHelper (response.data.user.data);
        }

        //alert('ok')
        setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  const paginateHelper = arrayitem => {
    //console.log (arrayitem, '...............');
    const endOffset = itemOffset + show_entries;

    //alert(itemOffset)

    setCurrentItems (arrayitem.slice (itemOffset, endOffset));
    setPageCount (Math.ceil (arrayitem.length / show_entries));

    //  alert(next_page)

    // alert(currentItems.length)
    //alert(pageCount)
  };

  const handlePageClick = event => {
    //console.log(data)

    //alert(event.selected)

    const newOffset = event.selected * show_entries % data.length;

    //alert (newOffset);

    const endOffset = newOffset + show_entries;

    // alert(itemOffset)
    // console.log(data.splice())
    setCurrentItems (data.slice (newOffset, endOffset));

    setPageCount (Math.ceil (data.length / show_entries));

    // alert(newOffset)
    //alert(newOffset)
    // alert(newOffset)
    //setItemOffset (newOffset);
    var myDiv = document.getElementById ('top_div_item');
    myDiv.scrollTop = 0;

    if (next_page && last_select.name == 'month') {
      const object = new Date ();
      getUserPaginate (
        last_select,
        show_entries * 3,
        object.getFullYear (),
        next_page
      );
      return;
    }

    if (next_page) {
      // alert (next_page);
      getUserPaginate (last_select, show_entries * 3, false, next_page);
    }
  };

  React.useEffect (() => {
    const object = new Date ();

    getUser ({value: object.getFullYear (), name: 'year'}, show_entries * 3);

    // alert('mm')

    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  }, []);

  // Export tableto excel
  const fileName = 'FarmsellProducts';

  return (
    <LoadingOverlay active={isActive} spinner text="Loading data...">
      <div className="admin-bg">
        <div className="main">
          <AdminHeader />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{width: '25vw'}}>
              <Sidebar />
            </div>
            <div className="userManager-cont" id="top_div_item">
              <div>
                <div className="display-admin">
                  <div className="admin-title">USERS MANAGER</div>
                  <div class="input-icons display-admin">
                    <i class="fa fa-user icon" />
                    <input
                      class="input-field"
                      onChange={e => searchUser (e)}
                      type="text"
                      placeholder="Search by user's name, email,..."
                    />
                  </div>
                </div>
                <div className="display-admin" style={{marginBottom: '10px'}}>
                  <div class="display-admin admin-show">
                    <span>Show &nbsp;</span>
                    <select
                      onChange={e => ShowBy (e)}
                      name="show"
                      id="show"
                      className="sort-by"
                    >
                      <option value="20" style={{width: '100px'}}>20</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="25">25</option>
                      <option value="30">30</option>
                      <option value="35">35</option>
                      <option value="40">40</option>
                      <option value="45">45</option>

                      <option value="50">50</option>
                      <option value="55">55</option>

                      <option value="60">60</option>
                      <option value="65">65</option>
                      <option value="70">70</option>
                      <option value="75">75</option>
                      <option value="80">80</option>
                      <option value="85">85</option>
                      <option value="90">90</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>

                    </select>
                    {/* <span>&nbsp; Entries</span>  */}
                  </div>
                  <div class="display-admin admin-show">
                    <span>Sort by &nbsp;</span>
                    <select
                      onChange={e => selectSort (e)}
                      name="show"
                      id="show"
                      className="sort-by"
                    >

                      <option value="this_year">This year</option>
                      <option value="this_all">All users</option>
                      <option value="last_year">Last year</option>
                      <option value="this_month">This month</option>
                      <option value="last_month">Last month</option>
                      <option value="today" style={{width: '100px'}}>
                        Joined Today
                      </option>
                      <option value="online">Online</option>
                    </select> &emsp;
                    <div
                      to="./adminaddadmin/add"
                      onClick={exportUsers}
                      className="add-btn"
                      style={{marginRight: '10px'}}
                    >
                      Bulk Action
                    </div>
                    &emsp;
                    <ExportToExcel apiData={data} fileName={fileName} />
                  </div>
                </div>
              </div>

              <div className="adminScroll">

                <Table borderless hover responsive style={{fontSize: '12px'}}>
                  <thead style={{background: '#E3E9EF', color: '#B3B8BD'}}>
                    <tr style={{textAlign: 'left'}}>
                      <th className="col-sm-1">User ID</th>
                      <th className="col-sm-3" />
                      <th className="col-sm-2">Country</th>
                      <th className="col-sm-2">Registered From</th>

                      <th className="col-sm-2">Registered With</th>

                      <th className="col-sm-2">Socials</th>
                      <th className="col-sm-1">Status</th>
                      <th className="col-sm-2">Online</th>
                      <th className="col-sm-3">Joined</th>
                      <th className="col-sm-1">Action</th>
                    </tr>
                  </thead>
                  {currentItems
                    ? currentItems.map ((cont, index) => {
                        return (
                          <tbody key={index}>
                            <tr
                              style={{textAlign: 'left', cursor: 'pointer'}}
                              //onClick={handleDetails}
                            >
                              <td>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  {index + 1}
                                </div>
                              </td>
                              <td
                                style={{textAlign: 'left'}}
                                onClick={() => handleDetails (cont.id)}
                              >
                                <img
                                  src={
                                    cont.picture
                                      ? `${config.domain_name}/storage/${cont.picture}`
                                      : cont.avatar_google
                                          ? cont.avatar_google
                                          : placeholder
                                  }
                                  alt=""
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%',
                                  }}
                                />
                                {' '}
                                <a
                                  href="#"
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                  }}
                                >
                                  {cont.full_name}
                                </a>
                              </td>
                              <td>

                                {/*
                                <img
                                  src={ugandaFlag}
                                  alt=""
                                  style={{
                                    width: '25px',
                                    height: '15px',
                                    borderRadius: '30%',
                                    paddingRight: '2px',
                                  }}
                                />
                                */}
                                {getCountry (cont.country_code)}
                              </td>
                              <td>
                                {Number (cont.registered_from) === 2 ||
                                  !cont.registered_from
                                  ? 'Mobile'
                                  : 'Web'}
                              </td>
                              <td>
                                {cont.registration_type == 1
                                  ? 'Phone/Email'
                                  : cont.registration_type == 2
                                      ? 'Google'
                                      : 'Facebook'}
                              </td>
                              <td>
                                <FontAwesomeIcon icon={faSkype} />
                                <FontAwesomeIcon
                                  icon={faInstagram}
                                  style={{marginLeft: 4, marginRight: 4}}
                                />
                                <FontAwesomeIcon icon={faFacebookF} />
                              </td>
                              <td>
                                {cont.verification_status == 1
                                  ? <span style={{color: 'green'}}>
                                      Verified
                                    </span>
                                  : 'UnVerified'}
                              </td>
                              <td style={{width: '100px'}}>
                                {cont.online_status == 'online'
                                  ? <span style={{color: 'green'}}>Online</span>
                                  : cont.online_status}
                              </td>

                              <td style={{fontSize: '10px'}}>
                                {cont.created_at}
                              </td>

                              {Permission ()
                                ? <td>
                                    <Button
                                      className="admin-del-btn"
                                      size="sm"
                                      onClick={() => confirmDelete (cont.id)}
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                : null}
                            </tr>
                          </tbody>
                        );
                      })
                    : null}
                </Table>

                {data && data.length == 0
                  ? <h5 style={{textAlign: 'center', marginTop: 100}}>
                      No Result
                    </h5>
                  : null}

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '4rem',
                  }}
                >
                  {currentItems.length !== 0 && last_select.name != 'all'
                    ? <ReactPaginate
                        previousLabel={'prev'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                      />
                    : null}
                </div>

              </div>

            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={{padding:'30px'}}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{color: '#FF0000', fontWeight: 'bold'}}>
              <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION!!!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{color: '#FF0000', fontSize: '11px'}}>
            Once you've deleted the record , the User will no longer be available. This action cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleClose}
              style={{backgroundColor: '#D3D3D3', border: 'none'}}
            >
              No!
            </Button>
            <Button
              variant="primary"
              onClick={deleteUser}
              style={{
                marginRight: '20px',
                backgroundColor: '#3EB971',
                border: 'none',
              }}
            >
              Yes!
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </LoadingOverlay>
  );
};

export default UsersManager;
