import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Table, Button, Form, Row, Col, Modal} from 'react-bootstrap';
import Sidebar from '../Sidebar';

import AdminHeader from '../AdminHeader';
import '../../assets/css/dashboard.css';
import Request from '../utls/request';

import auth from '../auth/aut';
import ToastObject from '../shared/toast';

import LoadingOverlay from 'react-loading-overlay';

import {useHistory} from 'react-router-dom';

var User = auth.authData ();

var UserToken = User ? User.Token : null;

const TreckingNotification = () => {
  const [message, setMessage] = useState ('');
  const [subject, setSubject] = useState ('');
  const [email, setEmail] = useState ('');

  const [error, setError] = useState ('');

  const [data, setData] = useState ([]);
  const [show, setShow] = useState (false);

  const [sending, setSend] = useState (false);

  const [isActive, setIsActive] = useState (true);

  //pagination

  //const [sort_by, setSortBy] = useState();
  const handleClose = () => setShow (false);

  const confirmDelete = id => {};

  const history = useHistory ();

  const handleDetails = id => {
    history.push (`/userview/${id}`);
  };

  ///getCountry ('+256');

  const saveEmail = () => {
    if (!subject || !message) {
      ToastObject.ToastError ('Email subject or the Body can not be empty');

      return;
    }
    const body = {
      title: subject,
      body: message,
    };
    setSend (true);

    Request.postMethod ('send-email', body)
      .then (response => {
        if (response.data) {
          setShow (false);
          ToastObject.ToastSuccess (
            'System is processing email to the respective users!'
          );
          getContactClicks ();
        }
      })
      .catch (error => {
        ToastObject.ToastError ('Something went wrong, try again latter');
      });
  };

  const getContactClicks = () => {
    //alert (filter_count);
    //alert("Trecking")

    Request.getMethod ('get-contact-clicks')
      .then (response => {
        console.log (response.data);

        setData (response.data.contact);

        //alert(JSON.stringify(response.data.email))

        //alert('ok')
        setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
        // alert("Ok")
      });
  };

  React.useEffect (() => {
    getContactClicks ();

    // alert('mm')

    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  }, []);

  // Export tableto excel

  const onChangeInputs = event => {
    setError (null);

    const name = event.target.name;

    if (name === 'message') {
      setMessage (event.target.value);
    }

    if (name === 'subject') {
      setSubject (event.target.value);
    }

    if (name === 'email') {
      setEmail (event.target.value);
    }

    console.log (email, subject, message);
  };

  return (
    <LoadingOverlay active={isActive} spinner text="Loading data...">
      <div className="admin-bg">
        <div className="main">
          <AdminHeader />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{width: '25vw'}}>
              <Sidebar />
            </div>
            <div className="userManager-cont" id="top_div_item">
              <div>

                <div
                  className="display-admin"
                  style={{
                    marginBottom: '10px',
                  }}
                />
              </div>

              <div className="adminScroll">

                <Table borderless hover responsive style={{fontSize: '12px'}}>
                  <thead style={{background: '#E3E9EF', color: '#B3B8BD'}}>
                    <tr style={{textAlign: 'left', width: '100%'}}>
                      <th className="col-sm-3">No</th>

                      <th className="col-sm-3">User</th>
                      <th className="col-sm-3">Product</th>

                      <th className="col-sm-3">Number of Times</th>

                      <th className="col-sm-3">Created</th>
                      <th className="col-sm-3" />

                    </tr>
                  </thead>
                  {data
                    ? data.map ((cont, index) => {
                        return (
                          <tbody key={index}>
                            <tr
                              style={{textAlign: 'left', cursor: 'pointer'}}
                              //onClick={handleDetails}
                            >
                              <td>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  {index + 1}
                                </div>
                              </td>
                              <td
                                style={{textAlign: 'left'}}
                                onClick={() => handleDetails (cont.id)}
                              >

                                {' '}
                                <a
                                  href="#"
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                  }}
                                >
                                  {cont.name}
                                </a>
                              </td>

                              <td>
                                {cont.product}
                              </td>

                              <td style={{width: '100px'}}>
                                {cont.number_of_times}
                              </td>

                              <td style={{fontSize: '10px'}}>
                                {cont.created_at}
                              </td>

                            </tr>
                          </tbody>
                        );
                      })
                    : null}
                </Table>

              </div>

            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={{padding:'30px'}}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Email Notification
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Form>

              <Form.Group className="mb-3" controlId="formGridSubject">
                <Form.Label>Subject*</Form.Label>
                <Form.Control
                  name="subject"
                  onChange={event => onChangeInputs (event)}
                  type="text"
                  style={{borderRadius: '0px'}}
                />
                {error && error.subject !== undefined
                  ? <div className="custom_bootstrap_validation">
                      Subject field can not be empty
                    </div>
                  : ''}
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGridAddress2">
                <Form.Label>Mesasage*</Form.Label>
                <textarea
                  name="message"
                  onChange={event => onChangeInputs (event)}
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  style={{borderRadius: '0px'}}
                />
                {error && error.message !== undefined
                  ? <div className="custom_bootstrap_validation">
                      Message field can not be empty
                    </div>
                  : ''}

              </Form.Group>

            </Form>
          </Modal.Body>
          <Modal.Footer>
            {sending
              ? <Button onClick={saveEmail} disabled>Sending</Button>
              : <Button onClick={saveEmail}>Send Email</Button>}

          </Modal.Footer>
        </Modal>

      </div>
    </LoadingOverlay>
  );
};

export default TreckingNotification;
