import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Login from './Components/auth/login';
import UsersManager from './Components/user/UsersManager';
import DashboardAdmin from './Components/Layout/DashboardAdmin';
import AdminView from './Components/AdminView';
import AdminProducts from './Components/products/AdminProducts';
import AdminProductDetails from './Components/products/AdminProductDetails';
import AdminEditCategory from './Components/AdminEditCategory';
import AdminCategoriesDetails from './Components/AdminCategoriesDetails';
import AdminCategories from './Components/AdminCategories';
import AdminPages from './Components/pages/AdminPages';
import AdminTestimonials from './Components/AdminTestimonials';
import AdminAddTestimonial from './Components/AdminAddTestimonial';
import AdminAddPage from './Components/pages/AdminAddPage';
import SiteSettings from './Components/siteSettings/SiteSettings';
import AdminMainSlide from './Components/siteSettings/AdminMainSlide';
import CategoriesBanner from './Components/siteSettings/CategoriesBanner';
import Languages from './Components/siteSettings/Languages';
import Gif from './Components/siteSettings/Gif';
import {PrivateRoute} from './Components/routes/PrivateRoutes';
import AdminAddAdmin from './Components/AdminAddAdmin';
import {ToastContainer} from 'react-toastify';
import AddCategory from './Components/AddCategory';
import PaginationTest from './Components/PaginationTest';
import ProductType from './Components/ProductType';
import PendingProducts from './Components/products/PendingProducts';

import AdminDetails from './Components/AdminDetails';
import UserView from './Components/user/UserView';
import SinglePendingProduct from './Components/products/SinglePendingProduct';
import AddMainSlide from './Components/siteSettings/AddMainSlide';
import AddCategoriesSlide from './Components/siteSettings/AddCategoriesSlide';
import UserProducts from './Components/products/UserProducts';

import SideBarTest from './Components/sidebarTest/SideBarTest';
import RejectedProducts from './Components/products/RejectedProducts';
import ApprovedProducts from './Components/products/ApprovedProducts';
import ProductsUnderCheck from './Components/products/ProductsUnderCheck';
import ClosedProducts from './Components/products/ClosedProducts';
import Password from './Components/siteSettings/password';
import emailNotification from './Components/notification/emailNotification';
import TreckingNotification from './Components/engagement/TreckingNotification';
import FarmsellAgent from './Components/farmsellAgent';
import Orders from './Components/orders';
import OrderDetails from './Components/orders/orderDetails';

function App () {
  return (
    <div className="App">
      <Router>
        <Switch>

          <PrivateRoute path="/" component={DashboardAdmin} exact />
          <Route path="/login" component={Login} />
          <PrivateRoute path="/usersmanager" component={UsersManager} />
          <PrivateRoute path="/adminview" component={AdminView} />
          <PrivateRoute path="/adminproducts" component={AdminProducts} />
          <PrivateRoute
            path="/adminproductdetails/:id"
            component={AdminProductDetails}
            exact
          />
          <PrivateRoute path="/admincategories" component={AdminCategories} />
          <PrivateRoute
            path="/admineditcategory/:id"
            component={AdminEditCategory}
            exact
          />
          <PrivateRoute
            path="/admincategoriesdetails/:id"
            component={AdminCategoriesDetails}
            exact
          />
          <PrivateRoute path="/adminpages" component={AdminPages} />
          <PrivateRoute
            path="/admintestimonials"
            component={AdminTestimonials}
          />
          <PrivateRoute
            path="/adminaddtestimonial"
            component={AdminAddTestimonial}
          />
          <PrivateRoute path="/adminaddpage/:id" component={AdminAddPage} />
          <PrivateRoute path="/sitesettings" component={SiteSettings} />

          <PrivateRoute path="/password-management" component={Password} />

          <PrivateRoute path="/adminmainslide" component={AdminMainSlide} />
          <PrivateRoute path="/categoriesbanner" component={CategoriesBanner} />
          <PrivateRoute path="/languages" component={Languages} />
          <PrivateRoute path="/gif" component={Gif} />
          <PrivateRoute path="/adminaddadmin/:id" component={AdminAddAdmin} />

          <PrivateRoute path="/admindetails" component={AdminDetails} />
          <PrivateRoute path="/userview/:id" component={UserView} />
          <PrivateRoute path="/addcategory" component={AddCategory} />
          <PrivateRoute path="/paginationtest" component={PaginationTest} />
          <PrivateRoute path="/producttype/:id" component={ProductType} />
          <PrivateRoute path="/pendingproducts" component={PendingProducts} />
          <PrivateRoute
            path="/singlependingproduct/:id"
            component={SinglePendingProduct}
          />
          <PrivateRoute path="/addmainslide" component={AddMainSlide} />
          <PrivateRoute
            path="/addcategoriesslide/:id"
            component={AddCategoriesSlide}
          />

          <PrivateRoute path="/userproducts/:id" component={UserProducts} />
          <PrivateRoute path="/SideBarTest" component={SideBarTest} />
          <PrivateRoute path="/rejectedproducts" component={RejectedProducts} />
          <PrivateRoute path="/approvedproducts" component={ApprovedProducts} />
          <PrivateRoute
            path="/productsundercheck"
            component={ProductsUnderCheck}
          />
          <PrivateRoute path="/closedproducts" component={ClosedProducts} />

          <PrivateRoute
            path="/user-notification"
            component={emailNotification}
          />

          <PrivateRoute
            path="/phone-engagement-statistics"
            component={TreckingNotification}
          />

          <PrivateRoute path="/farmsell-agent" component={FarmsellAgent} />

          <PrivateRoute path="/orders" component={Orders} />
          <PrivateRoute path="/order/:id" component={OrderDetails} />

        </Switch>
      </Router>
      <ToastContainer />
    </div>
  );
}

export default App;
