import config from '../utls/constant';

const ImageGallery = props => {
  const gallery = props.gallery;

  if (Array.isArray (gallery))
    return gallery.map (item => (
      <img
        src={`${config.domain_name}/storage/product/${item.image_path}`}
        className="tpro"
        alt=""
      />
    ));

  return null;
};

export default ImageGallery;
