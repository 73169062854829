
import React, {useState} from 'react';
import {Button, Table} from 'react-bootstrap';
import '../../assets/css/dashboard.css';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import Request from '../utls/request';
import LoadingOverlay from 'react-loading-overlay';
import config from '../utls/constant';
import {useHistory} from 'react-router-dom';
import {ExportToExcel} from './ExportToExcel';
import ReactPaginate from 'react-paginate';

function ProductsUnderCheck () {
  const [data, setData] = useState (null);
  const [isActive, setIsActive] = useState (true);
  const [currentItems, setCurrentItems] = useState ([]);
  const [pageCount, setPageCount] = useState (0);
  const [show_entries, setShowEntries] = useState (50);
  const [next_page, setNextPage] = useState ([]);

  const [itemOffset, setItemOffset] = useState (0);

  const [last_select, setSelect] = useState ({
    value: new Date ().getFullYear (),
    name: 'year',
  });

  const history = useHistory ();

  const setShowNumber = e => {
    setData ([]);
    setCurrentItems ([]);
    setPageCount (0);
    setNextPage (null);
    setShowEntries (e.target.value);
    getProducts ();
    //alert(pageCount)
  };

  const handleDetails = id => {
    // window.location.href = '/singlependingproduct/1';
    history.push (`/singlependingproduct/${id}`);
  };

  const handlePageClick = event => {
    //console.log(data)

    //alert(event.selected)

    const newOffset = event.selected * show_entries % data.length;

    //alert (newOffset);

    const endOffset = newOffset + show_entries;

    // alert(itemOffset)
    // console.log(data.splice())
    setCurrentItems (data.slice (newOffset, endOffset));

    setPageCount (Math.ceil (data.length / show_entries));

    // alert(newOffset)
    //alert(newOffset)
    // alert(newOffset)
    //setItemOffset (newOffset);
    //alert (next_page);
    if (next_page) {
      getProductPaginate (last_select, show_entries, false, next_page);
    }
  };

  const getProductPaginate = (select_data, filter_count, item, url) => {
    // alert(data.length)

    if (item) {
      var body = {
        [select_data.name]: select_data.value,
        show_entries: filter_count,
        year: item,
      };
    } else {
      var body = {
        [select_data.name]: select_data.value,
        show_entries: filter_count,
      };
    }

    // alert("Outside")

    //console.log (body);

    setData (null);
    setIsActive (true);

    const url_param = url + `&id=0`;
    //alert (url_param);

    Request.postMethodFullUrl (url_param, body)
      .then (response => {
        console.log (response.data);

        setNextPage (response.data.data.next_page_url);

        // console.log (response.data.user.data);

        // console.log(data ,'....')
        const newArray = [...data, ...response.data.data.data];

        setData (newArray);
        //console.log (newArray);
        // paginateHelper (newArray);
        //console.log (newArray);

        //alert('ok')
        setIsActive (false);
        ////alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        //setIsActive(false)
        console.log (error);
      });
  };

  
  const searchProduct = e => {
    if (e.target.value.length == 0) {
      const object = new Date ();
      getProducts ({value: object.getFullYear (), name: 'year'}, 20);
    }
  

    if (e.target.value.length >= 3) {
      const body = {
        key: e.target.value,
        user: true,
      };
      Request.postMethod ('search-products', body).then (response => {
        setCurrentItems (response.data.user);
      });
    }
  };

  const paginateHelper = arrayitem => {
    //console.log (arrayitem, '...............');

    const endOffset = itemOffset + show_entries;

    //alert(itemOffset)


    setCurrentItems (arrayitem.slice (itemOffset, endOffset));
    //alert(Math.ceil (arrayitem.length / show_entries))

    setPageCount (Math.ceil (arrayitem.length / show_entries));

    //  alert(next_page)

    // alert(currentItems.length)
    //alert(pageCount)
  };

  const getProducts = (/*data, filter_count, item*/) => {
    /*
    if (item) {
      var body = {
        [data.name]: data.value,
        show_entries: filter_count,
        year: item,
      };
    } else {
      var body = {
        [data.name]: data.value,
        show_entries: filter_count,
      };
    }
    */

    

    Request.postMethod (`get-product/summary`, {
      id: 0,
      count: show_entries * 3,
    })
      .then (response => {
        console.log (response.data, 'mmmdmd');

        //setData (response.data.user.data);
        //console.log(data)

        setNextPage (response.data.data.next_page_url);
        // console.log (response.data.user.data);

        paginateHelper (response.data.data.data);

        setData (response.data.data.data);

        setIsActive (false);
      })
      .catch (error => {
        console.log (error.response);
      });
  };

  React.useEffect (() => {
    getProducts ();
  }, []);

  // Export tableto excel
  const fileName = 'RejectedProducts';

  return (
    <LoadingOverlay active={isActive} spinner text="Loading data...">
      <div className="admin-bg">
        <div className="main">
          <AdminHeader />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="admin-side">
              <Sidebar />
            </div>
            <div className="userManager-cont">
              <div>
                <div className="display-admin">
                  <div className="admin-title">
                    PENDING PRODUCTS
                  </div>
                  <div class="input-icons display-admin">
                    <i class="fa fa-user icon" />
                    <input
                      class="input-field"
                      type="text"
                      placeholder="Search by user's name, email,..."
                      onChange={e => searchProduct (e)}
                    />
                  </div>
                </div>
                <div className="display-admin" style={{marginBottom: '10px'}}>
                  <div class="display-admin admin-show">
                    <span>Show &nbsp;</span>
                    <select
                      onChange={e => setShowNumber (e)}
                      name="show"
                      id="show"
                      className="sort-by"
                    >
                      <option value="50" style={{width: '100px'}}>50</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="40">40</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                    </select>
                    <span>&nbsp; Entries</span>
                  </div>
                  <div class="display-admin admin-show">
                    <span>Sort by &nbsp;</span>
                    <select name="show" id="show" className="sort-by">
                      <option value="joinedToday" style={{width: '100px'}}>
                        Joined Today
                      </option>
                      <option value="thisMonth">This month</option>
                      <option value="thisYear">This year</option>
                      <option value="online">Online</option>
                    </select> &emsp;

                    <ExportToExcel apiData={data} fileName={fileName} />
                  </div>
                </div>
              </div>
              <div>
                <Table borderless responsive style={{fontSize: '12px'}}>
                  <thead
                    style={{
                      background: '#E3E9EF',
                      color: '#B3B8BD',
                      marginBottom: '20px',
                    }}
                  >
                    <tr style={{textAlign: 'left'}}>
                      <th>Check Box</th>
                      <th />
                      <th>Products</th>
                      <th>Product Owner</th>
                      <th>Currency</th>
                      <th>Ratings</th>
                      <th>Country</th>
                      <th>Location</th>
                      <th>Quantity</th>
                      <th>Views</th>
                      <th>Uploaded</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {currentItems
                    ? currentItems.map ((cont, index) => {
                        return (
                          <tbody style={{margin: '30px'}}>
                            <tr key={index} style={{textAlign: 'left'}}>
                              <td>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                </div>
                              </td>
                              <td style={{textAlign: 'left'}}>
                                <img
                                  src={`${config.domain_name}/storage/cover/${cont.picture}`}
                                  alt=""
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%',
                                  }}
                                />
                                {' '}
                                {cont.name}
                              </td>
                              <td>{cont.product_title}</td>
                              <td>{cont.full_name}</td>
                              <td>{cont.currency}</td>
                              <td>{cont.rating}</td>
                              <td>{cont.country}</td>
                              <td>{cont.location}</td>
                              <td>{cont.available_quantity}</td>
                              <td>{cont.view_count}</td>
                              <td>{cont.uploaded}</td>
                              <td style={{margin: '30px'}}>
                                <Button
                                  className="viewDetailsBtn"
                                  onClick={() => handleDetails (cont.id)}
                                >
                                  View Details
                                </Button>
                              </td>
                            </tr>
                          </tbody>
                        );
                      })
                    : null}
                </Table>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingBottom: '4rem',
                  }}
                >
                  {currentItems.length !== 0
                    ? <ReactPaginate
                        previousLabel={'prev'}
                        nextLabel={'next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                      />
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </LoadingOverlay>
  );
}

export default ProductsUnderCheck;

