import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Auth from '../../Components/auth/aut';
import LogoutOut from '../shared/logout';

export const PrivateRoute = ({component: Component, ...rest}) => {
  const setLocalStorageItemWithExpiry = (key, value, expiryTimeInMs) => {
    const now = new Date ();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime () + expiryTimeInMs,
    };
    localStorage.setItem (key, JSON.stringify (item));
  };

  const getLocalStorageItemWithExpiry = key => {
    const itemStr = localStorage.getItem (key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse (itemStr);
    const now = new Date ();
    // compare the expiry time of the item with the current time
    if (now.getTime () > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem (key);
      LogoutOut ();
      return 'log';
    }
    return item.value;
  };

  const onUserNavigate = () => {
    const value = getLocalStorageItemWithExpiry ('expire_session');
    if (value!=='log') {
      setLocalStorageItemWithExpiry ('expire_session', 'testvalue', 900000);
    }
  };

  return (
    <Route
      {...rest}
      render={props => {
        if (Auth.isAuthenticated) {
          onUserNavigate ();
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
