import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid';
import '../assets/css/dashboard.css';
import AdminHeader from './AdminHeader';
import {useParams} from 'react-router-dom';
import Request from './utls/request';
import config from './utls/constant';
import Sidebar from './Sidebar';

import 'react-toastify/dist/ReactToastify.css';
import ToastObject from './shared/toast';
import {Link} from 'react-router-dom';

const AdminEditCategory = props => {
  const [formData, setFormData] = useState ({
    category_name: '',
    id: '',
    image: '',
  });

  let {id} = useParams ();
  // alert(JSON.stringify(useParams()))

  const [data, setData] = useState ({});

  const [submit, setSubmit] = useState (false);
  const [submit_message, setMessage] = useState ('Save Changes');
  //alert(id)

  const onFileUpload = e => {
    setFormData (prevState => ({
      ...prevState,
      [e.target.name]: e.target.files[0],
    }));

    // console.log(filesList)
  };

  const onTextChange = e => {
    setFormData (prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    // console.log(formData)
  };

  const addCategory = () => {
    if (id == 'add') {
      if (!formData.category_name || !formData.image) {
        ToastObject.ToastError ('Category name and Image is required');
      }
    }

    setSubmit (true);
    setMessage ('Sending..');

    // ToastObject.ToastSuccess("Category added Succesfully!!")

    Request.postMethod ('add/category', formData)
      .then (response => {
        //console.log(response)

        if (response.data.success) {
          if (id === 'add') {
            ToastObject.ToastSuccess ('Category added Succesfully!!');
          }

          if (id !== 'add') {
            ToastObject.ToastSuccess ('Category Editted Succesfully!!');
          }
          props.history.push ('/admincategories');
        }
        //console.log(response.data)
      })
      .catch (error => {
        console.log (error.response);
      });
  };

  React.useEffect (() => {
    //alert(id)
    if (id !== 'add')
      Request.getMethod (`get/category-details/${id}`)
        .then (response => {
          setData (response.data.data);

          //alert(response.data.data.id)
          setFormData ({
            category_name: response.data.data.category_name,
            id: response.data.data.id,
            image: '',
          });

          //alert(JSON.stringify(data))
          // console.log(response.data)
        })
        .catch (error => {
          // console.log(error.response)
        });
  }, []);

  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>

          <div className="userManager-cont">
            <div>
              <div className="display-admin">
                <div className="admin-title">
                  CATEGORIES
                  <Link
                    to="/"
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <Link
                    to="/adminpages"
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Categories View <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#272D3B',
                      fontWeight: 'bold',
                    }}
                  >
                    Add Category
                  </span>
                </div>
              </div>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: '#3EB971',
                padding: '10px',
                margin: '0px -20px',
              }}
            >
              <span
                style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: '20px'}}
              >
                {' '}{id == 'add' ? 'Add' : 'Edit'} Category
              </span>

            </div>
            <Form>
              <div style={{paddingTop: '2rem'}}>
                <p style={{fontWeight: '600'}}>Choose Category type</p>

                <Form.Group
                  className="mb-3"
                  controlId="file"
                  style={{marginLeft: '1rem', marginTop: '1rem'}}
                >
                  <Form.Control
                    name="category_name"
                    onChange={e => onTextChange (e)}
                    value={formData.category_name}
                    type="text"
                    placeholder=""
                  />
                </Form.Group>
                {/*
                                <select name="show" id="show" style={{ width: '100%', border: '1px solid #707070', height: '3rem', borderRadius: '4px' }}>
                                <option value="friuts" style={{ width: '100px' }}>Fruits</option>
                                    <option value="animals">Animals</option>
                                    <option value="">etc</option>
                                    <option value="">etc</option>
                                    <option value="">etc</option>
                                    <option value="">etc</option>
                                </select>
                                */}
              </div>

              {data.picture_url || formData.image
                ? <img
                    src={
                      formData.image
                        ? URL.createObjectURL (formData.image)
                        : `${config.domain_name}/storage/banner/${data.picture_url}`
                    }
                    width="80px"
                    height="80px"
                    alt="added"
                  />
                : null}

              <div style={{paddingTop: '2rem'}}>
                <p style={{fontWeight: '600'}}>Upload Image</p>
                <div className="displayFlex gifHide">
                  <Form.Group
                    className="mb-3"
                    controlId="file"
                    style={{marginLeft: '1rem', marginTop: '1rem'}}
                  >
                    <Form.Control
                      name="image"
                      type="file"
                      placeholder="Browse..."
                      onChange={event => onFileUpload (event)}
                    />
                  </Form.Group>
                  <Button
                    style={{
                      background: '#3EB971',
                      height: '70px',
                      width: '200px',
                      border: 'none',
                    }}
                  >
                    Upload Image
                  </Button>
                </div>
              </div>
              <div style={{paddingTop: '2rem'}}>
                <Button className="cat-edit-btn">Reset</Button>
                <Button
                  className="cat-edit-btn"
                  onClick={addCategory}
                  disabled={submit ? true : false}
                >
                  {submit_message}
                </Button>
              </div>
            </Form>

          </div>
        </div>
      </div>

    </div>
  );
};

export default AdminEditCategory;
