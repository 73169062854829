import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid';
import {Link, useHistory} from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import Request from '../utls/request';
import config from '../utls/constant';

const CategoriesBanner = () => {
  const history = useHistory ();

  const [data, setData] = useState (null);
  const [isActive, setIsActive] = useState (true);
  const [data_status, setDataStatus] = useState (true);

  const handleAddCategoriesSlide = (id) => {

    history.push (`/addcategoriesslide/${id}`);

  };

  const getCategories = () => {
    Request.getMethod ('get/category/list')
      .then (response => {
        setData (response.data.category_view_list);

        setIsActive (false);
        setDataStatus(false)
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  React.useEffect (() => {
    getCategories ();
  }, []);

  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>
          <div className="userManager-cont">
            <div>
              <div className="display-admin">
                <div className="admin-title">
                  SITE SETTINGS
                  <Link
                    to="/"
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#272D3B',
                    }}
                  >
                    Categories{' '}
                  </span>
                </div>
              </div>
            </div>
            <div className="site-info">
              <Row>
                <Col md={3}>
                  <div className="site-set-side" style={{}}>
                    <p><Link to="./sitesettings">SITE INFORMATION</Link></p>
                    <p><Link to="./password-management">Password Settings</Link></p>
                    <p><Link to="./adminmainslide">MAIN SLIDES</Link></p>
                    <p style={{background: '#3EB971', color: '#fff'}}>
                      CATEGORIES' BANNERS
                    </p>
                    <p><Link to="./languages">LANGUAGE</Link></p>
                    <p><Link to="./gif">GIF</Link></p>
                  </div>
                </Col>
                <Col />
                <Col md={8}>
                  <div
                    className="displayFlex"
                    style={{marginBottom: '2rem', marginRight: '0.3rem'}}
                  >
                    <span className="site-set-m-title">CATEGORIES </span>

                    {/*
                    <Button
                      onClick={handleAddCategoriesSlide}
                      className="mainslide-btn"
                      style={{background: '#3EB971'}}
                    >
                      Add Slide
                    </Button>
                    */}
                  </div>

                  <Col className="autoscroll">

                    {data
                      ? data.map ((category, index) => (
                          <Row>
                            <Col md={9}>
                              <div style={{display: 'block'}}>
                                <div className="site-set-m-slide" style={{}}>
                                  <img
                                    src={`${config.domain_name}/storage/banner/${category.picture_url}`}
                                    alt=""
                                    style={{width: '10rem', height: '5rem'}}
                                  />
                                  &emsp;<span>  {category.category_name}</span>
                                </div>
                              </div>
                            </Col>
                            <Col md={2}>
                              <Button
                                onClick={()=>handleAddCategoriesSlide(category.category_id)}
                                className="mainslide-btn"
                                style={{
                                  background: '#3EB971',
                                  marginTop: '1.5rem',
                                }}
                              >
                                Add Slide
                              </Button>

                            </Col>
                          </Row>
                        ))
                      : null}

                {data_status
                  ? <div className="d-flex justify-content-center">
                      <div className="spinner-border" role="status" />
                    </div>
                  : null}


                  </Col>
                  
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default CategoriesBanner;
