import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Row, Col, Button, Modal} from 'react-bootstrap';
import {
  faChevronRight,
  faExclamationTriangle,
} from '@fortawesome/fontawesome-free-solid';
import {Link} from 'react-router-dom';
import AdminHeader from './AdminHeader';
import Request from './utls/request';
import {useParams} from 'react-router-dom';
import config from './utls/constant';
import auth from './auth/aut';
import ToastObject from './shared/toast';
//import { confirmAlert } from 'react-confirm-alert'; // Import
//import 'react-confirm-alert/src/react-confirm-alert.css';
import Sidebar from './Sidebar';
import LoadingOverlay from 'react-loading-overlay';

const AdminCategoriesDetails = props => {
  const [show, setShow] = useState (false);
  const [isActive, setIsActive] = useState (true);

  const [deleting, setDelete] = useState ('Delete');

  const handleClose = () => setShow (false);
  const handleShow = () => setShow (true);

  let {id} = useParams ();
  // alert(JSON.stringify(useParams()))

  const [data, setData] = useState ({});
  //alert(id)

  const deleteCategory = () => {
    handleClose (false);

    setDelete ('Deleting..');

    Request.getMethod (`category/delete/${id}`)
      .then (response => {
        if (response.data.success) {
          ToastObject.ToastSuccess ('Category Deleted Succesfully!!');

          props.history.push ('/admincategories');
        }
      })
      .catch (error => {});
  };

  const DeleteConfirm = () => {
    setShow (true);
    /*
            confirmAlert({
                title: 'Delete Confirmation',
                message: 'Are you sure you want to perform this action',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => deleteCategory()
                    },
                    {
                        label: 'No',
                        onClick: ()=>{}
                    }
                ]
            });
            */
  };

  React.useEffect (() => {
    Request.getMethod (`get/category-details/${id}`)
      .then (response => {
        setData (response.data.data);

        setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        console.log (error.response);
      });
  }, []);

  return (
    <LoadingOverlay active={isActive} spinner text="Loading data...">
      <div className="admin-bg">
        <div className="main">
          <AdminHeader />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="admin-side">
              <Sidebar />
            </div>
            {/* <Col></Col> */}

            <div className="userManager-cont">
              <div>
                <div className="display-admin">
                  <div className="admin-title">
                    CATEGORIES
                    <Link
                      to="/"
                      className="admib-nav"
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        textDecoration: 'none',
                        borderLeft: '1px solid #D3D3D3',
                        paddingLeft: '3px',
                        marginLeft: '3px',
                        color: '#D3D3D3',
                      }}
                    >
                      Home <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                    <Link
                      to="/adminpages"
                      className="admib-nav"
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        textDecoration: 'none',
                        borderLeft: '1px solid #D3D3D3',
                        paddingLeft: '3px',
                        marginLeft: '3px',
                        color: '#D3D3D3',
                      }}
                    >
                      Categories View <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#272D3B',
                        fontWeight: 'bold',
                      }}
                    >
                      Categories Details
                    </span>
                  </div>
                </div>
                <div className="display-admin" style={{marginBottom: '10px'}}>
                  <div className="display-admin admin-show">
                    {/* <span>Show &nbsp;</span>
                                    <select name="show" id="show" style={{ width: '100px', border: '1px solid #707070', height: '25px', borderRadius: '6px' }}>
                                        <option value="5" style={{ width: '100px' }}>5</option>
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                    </select>
                                    <span>&nbsp; Entries</span>*/}
                  </div>
                  {auth.authData ().permission &&
                    (JSON.parse (auth.authData ().permission).includes (1) ||
                      JSON.parse (auth.authData ().permission).includes (2))
                    ? <div className="display-admin admin-show">
                        <Link to={`/producttype/${id}`}>
                          <Button
                            className="admin-del-btn"
                            size="sm"
                            style={{background: '#3EB971'}}
                          >
                            View Sub Category
                          </Button>
                        </Link>{' '}
                        &emsp;
                      </div>
                    : null}
                </div>
              </div>
              <div className="autoscroll">
                <Row>

                  <Col md={4}>
                    <img
                      src={`${config.domain_name}/storage/banner/${data.picture_url}`}
                      className="Mpro"
                      alt=""
                    />

                  </Col>
                  <Col />

                  <Col md={7} style={{}}>

                    <div style={{}}>
                      {/*
                                        <h5 style={{ fontWeight: '900' }}>Fruit</h5>
                                        */}
                      <Row>
                        <Col>
                          <p style={{fontWeight: '500'}}>Category Name</p>
                        </Col>
                        <Col><p>{data.category_name}</p></Col>
                      </Row>
                      <Row>
                        <Col>
                          <p style={{fontWeight: '500'}}>Created Date</p>
                        </Col>
                        <Col><p>{data.created_at}</p></Col>
                      </Row>
                      <Row>
                        <Col>
                          <p style={{fontWeight: '500'}}>Updated Date</p>
                        </Col>
                        <Col><p>{data.updated_at}</p></Col>
                      </Row>
                      <Row>
                        <Col>
                          <p style={{fontWeight: '500'}}>No. of Products</p>
                        </Col>
                        <Col><p>{data.product}</p></Col>
                      </Row>
                      <Row>
                        <Col>
                          <p style={{fontWeight: '500'}}>Sub Categories</p>
                        </Col>
                        <Col>
                          <p>
                            (Mangoes, Apples, Melon, Jackfruit, Citrus, Vegetable,...)
                          </p>
                        </Col>
                      </Row>

                    </div>
                    {auth.authData ().permission &&
                      (JSON.parse (auth.authData ().permission).includes (1) ||
                        JSON.parse (auth.authData ().permission).includes (2))
                      ? <div style={{}}>
                          <Button
                            disabled={show ? true : false}
                            className="cat-details-btn"
                            onClick={DeleteConfirm}
                          >
                            {deleting}
                          </Button>
                          <Link to={`/admineditcategory/${data.id}`}>
                            <Button
                              className="cat-details-btn"
                              style={{background: '#F7B618'}}
                            >
                              Edit
                            </Button>
                          </Link>
                          <Button className="cat-details-btn">Approve</Button>
                        </div>
                      : null}
                  </Col>

                </Row>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={{padding:'30px'}}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{color: '#FF0000', fontWeight: 'bold'}}>
              <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION!!!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{color: '#FF0000', fontSize: '11px'}}>
            Once you've deleted the record , the Category will no longer be available. This action cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleClose}
              style={{backgroundColor: '#D3D3D3', border: 'none'}}
            >
              No!
            </Button>
            <Button
              variant="primary"
              onClick={deleteCategory}
              style={{
                marginRight: '20px',
                backgroundColor: '#3EB971',
                border: 'none',
              }}
            >
              Yes!
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

    </LoadingOverlay>
  );
};

export default AdminCategoriesDetails;
