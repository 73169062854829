import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Col, Row, Card} from 'react-bootstrap';
import team2 from '../../assets/images/team2.png';

import '../../assets/css/dashboard.css';

import userimg from '../../assets/images/userimg.png';
import visitors from '../../assets/images/visitors.png';
import providers from '../../assets/images/providers.png';
import products from '../../assets/images/products.png';
import aysha from '../../assets/images/aysha.png';
import paul from '../../assets/images/paul.png';
import AdminHeader from '../AdminHeader';
import ApexPieChart from '../graph/ApexPieChart';
import ProductPiePerMonth from '../graph/ProductPiePerMonth';
import UserandProductsGraph from '../graph/UserandProductsGraph';
import SiteViewsandVisits from '../SiteViewsandVisits';
import Request from '../utls/request';
import config from '../utls/constant';
import Placeholder from '../../assets/images/placeholder.jpeg';
import Sidebar from '../Sidebar';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid';

const UserLatest = props => {
  const users = props.data.map ((newUser, index) => {
    return (
      <div className="displayFlex newUserCard-body" key={index + 'user'}>
        <div className="displayFlexNew">
          <img
            src={
              newUser.picture
                ? `${config.domain_name}/storage/${newUser.picture}`
                : newUser.avatar_google ? newUser.avatar_google : Placeholder
            }
            alt=""
            className="newUserCard-img"
          />
          <div>
            <span style={{fontWeight: 'bold', fontSize: '12px'}}>
              {newUser.full_name}
            </span>
            <br />
            <span style={{color: '#52575D'}}>{newUser.email}</span>
          </div>
        </div>

        <span>{newUser.created_at}</span>
      </div>
    );
  });

  return users;
};

const LatestProduct = props => {
  //alert(JSON.stringify(props))

  const users = props.data.map ((newUser, index) => {
    return (
      <div
        className="displayFlex newUserCard-body"
        key={index + 'latest_product'}
      >
        <div className="displayFlexNew">
          <img
            src={`${config.domain_name}/storage/cover/${newUser.picture}`}
            alt=""
            className="newUserCard-img"
          />
          <div>
            <span style={{fontWeight: 'bold', fontSize: '12px'}}>
              {newUser.product_title}
            </span>
            <br />
            <span style={{color: '#52575D'}}>{newUser.email}</span>
          </div>
        </div>

        <span>{newUser.created_at}</span>
      </div>
    );
  });

  return users;
};

const DashboardAdmin = () => {
  const [data, setData] = useState (null);

  const [userGraph, setUserGraph] = useState (false);

  const [productGraph, setProductGraph] = useState (false);

  const history = useHistory ();

  const handleApprovedProducts = id => {
    history.push (`/approvedproducts`);
  };

  const handleRejectedProducts = id => {
    history.push (`/rejectedproducts`);
  };

  const handleProductCheck = id => {
    history.push (`/productsundercheck`);
  };

  const handleClosedProduct = id => {
    history.push (`/closedproducts`);
  };

  const getStatistics = () => {
    Request.getMethod ('statistics/count')
      .then (response => {
        setData (response.data);
        // console.log(response.data)
      })
      .catch (error => {
        console.log (error.response);
      });
  };

  const ObjectToArray = item => {
    var arr = [];

    for (const key in item) {
      // console.log(item,'..')
      arr.push (item[key]);
    }

    return arr;
  };

  const getUserProductsStat = () => {
    Request.getMethod ('get/user-product-statistics/2022')
      .then (response => {
        console.log (response);
        //setData (response.data);
        let user = response.data.ucount;
        let product = response.data.pcount;

        //console.log(ObjectToArray(product))
        //console.log(user)

        setUserGraph (ObjectToArray (user));
        setProductGraph (ObjectToArray (product));
        //console.log(userGraph)
        // console.log (userGraph, 'mmmmm');
      })
      .catch (error => {
        console.log (error.response);
      });
  };

  React.useEffect (() => {
    getStatistics ();
    getUserProductsStat ();
  }, []);

  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>
          <div className="userManager-cont">
            <div>
              <div className="display-admin" style={{marginBottom: '10px'}}>
                <div className="admin-title">
                  DASHBOARD
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#272D3B',
                      fontWeight: 'bold',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                </div>
                <select
                  name="show"
                  id="show"
                  style={{
                    width: '100px',
                    border: 'none',
                    height: '25px',
                    borderRadius: '5px',
                    background: '#3EB971',
                    color: '#fff',
                  }}
                >
                  <option value="joinedToday" style={{width: '100px'}}>
                    Export
                  </option>
                  <option value="thisMonth">Word</option>
                  <option value="thisYear">Excel</option>
                  <option value="online">PDF</option>
                </select>
              </div>
            </div>

            <div className="autoscrol">

              <Row>
                <Col md={3} className="col-card">
                  <Card className="dash-card">
                    <Card.Img
                      variant="top"
                      className="admin-card-img"
                      src={userimg}
                      alt=""
                    />
                    <Card.Body>
                      <Card.Title className="admin-card-title">
                        Users
                      </Card.Title>
                      <Card.Text className="admin-card-text">
                        <div>
                          <img
                            src={team2}
                            className="admindash-card-u"
                            alt=""
                          />
                          <img
                            src={aysha}
                            className="admindash-card-u"
                            alt=""
                            style={{marginLeft: '-10px'}}
                          />
                          <img
                            src={paul}
                            className="admindash-card-u"
                            alt=""
                            style={{marginLeft: '-10px'}}
                          />
                        </div>
                        <span className="admin-card-m" style={{}}>
                          {data ? data.user : 0}
                        </span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3} className="col-card">
                  <Card className="dash-card">
                    <Card.Img
                      variant="top"
                      className="admin-card-img"
                      src={visitors}
                      alt=""
                    />
                    <Card.Body>
                      <Card.Title className="admin-card-title">
                        Orders
                      </Card.Title>
                      <Card.Text className="admin-card-text">
                        <div>
                          <img
                            src={team2}
                            className="admindash-card-u"
                            alt=""
                          />
                          <img
                            src={aysha}
                            className="admindash-card-u"
                            alt=""
                            style={{marginLeft: '-10px'}}
                          />
                          <img
                            src={paul}
                            className="admindash-card-u"
                            alt=""
                            style={{marginLeft: '-10px'}}
                          />
                        </div>
                        <span className="admin-card-m" style={{}}>
                          {data ? data.order : 0}
                        </span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3} className="col-card">
                  <Card className="dash-card">
                    <Card.Img
                      variant="top"
                      className="admin-card-img"
                      src={products}
                      alt=""
                    />
                    <Card.Body>
                      <Card.Title className="admin-card-title">
                        Products
                      </Card.Title>
                      <Card.Text className="admin-card-text">
                        <div>
                          <img
                            src={team2}
                            alt=""
                            className="admindash-card-u"
                          />
                          <img
                            src={aysha}
                            alt=""
                            className="admindash-card-u"
                            style={{marginLeft: '-10px'}}
                          />
                          <img
                            src={paul}
                            alt=""
                            className="admindash-card-u"
                            style={{marginLeft: '-10px'}}
                          />
                        </div>
                        <span className="admin-card-m" style={{}}>
                          {data ? data.product : 0}
                        </span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={3} className="col-card">
                  <Card className="dash-card">
                    <Card.Img
                      variant="top"
                      className="admin-card-img"
                      src={providers}
                      alt=""
                    />
                    <Card.Body>
                      <Card.Title className="admin-card-title">
                        Providers
                      </Card.Title>
                      <Card.Text className="admin-card-text">
                        <div>
                          <img
                            src={team2}
                            alt=""
                            className="admindash-card-u"
                          />
                          <img
                            src={aysha}
                            alt=""
                            className="admindash-card-u"
                            style={{marginLeft: '-10px'}}
                          />
                          <img
                            src={paul}
                            alt=""
                            className="admindash-card-u"
                            style={{marginLeft: '-10px'}}
                          />
                        </div>
                        <span className="admin-card-m" style={{}}>
                          {data ? data.provider : 0}
                        </span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>

                <Col className="stat">
                  <span
                    onClick={handleApprovedProducts}
                    className="dash-product-card"
                  >
                    <h1 style={{marginBottom: '0px'}}>
                      {' '}{data ? data.approved : 0}
                    </h1>
                    <p style={{marginBottom: '0px'}}> Approved </p>
                  </span>
                </Col>
                <Col className="stat">
                  <span
                    onClick={handleProductCheck}
                    className="dash-product-card"
                  >
                    <h1 style={{marginBottom: '0px'}}>
                      {data ? data.pending : 0}
                    </h1>
                    <p style={{marginBottom: '0px'}}> Checking </p>
                  </span>
                </Col>
                <Col className="stat">
                  <span
                    onClick={handleRejectedProducts}
                    className="dash-product-card"
                  >
                    <h1 style={{marginBottom: '0px'}}>
                      {data ? data.rejected : 0}{' '}
                    </h1>
                    <p style={{marginBottom: '0px'}}> Rejected </p>
                  </span>
                </Col>
                <Col className="stat">
                  <span
                    onClick={handleClosedProduct}
                    className="dash-product-card"
                  >
                    <h1 style={{marginBottom: '0px'}}>
                      {' '}{data ? data.closed : 0}{' '}
                    </h1>
                    <p style={{marginBottom: '0px'}}> Closed </p>
                  </span>
                </Col>

              </Row>

              <div style={{height: '30vh', marginTop: '40px'}}>

                <Row>
                  <Col md={7}>
                    <div className="graph-line" style={{height: '40vh'}}>
                      <UserandProductsGraph
                        stat={{user: userGraph, product: productGraph}}
                      />
                    </div>
                  </Col>
                  <Col md={5}>
                    <div
                      className="pie-frame overflow"
                      style={{height: '40vh'}}
                    >
                      <ApexPieChart />
                      <ProductPiePerMonth />
                    </div>

                  </Col>
                </Row>

                <Row>
                  <Col>
                    <div className="siteview-chat graph-line">
                      <SiteViewsandVisits />
                    </div>
                  </Col>
                </Row>
                <Row xs={1} md={4} className="g-4">

                  {Array.from ({length: 3}).map ((_, idx) => (
                    <Col sm={1} md={4} key={idx}>

                      <Card className="newUserCard dash-card">
                        <Card.Header className="newUserCard-header" style={{}}>
                          {idx === 0
                            ? 'NEW USERS'
                            : idx === 1 ? 'NEW PROVIDERS' : 'NEW PRODUCTS'}

                        </Card.Header>
                        <Card.Body>
                          <Card.Text style={{fontSize: '11px'}}>

                            {idx === 0 && data
                              ? <UserLatest data={data.latest_user.data} />
                              : null}
                            {idx === 2 && data
                              ? <LatestProduct
                                  data={data.latest_product.data}
                                />
                              : null}

                            {idx === 1 && data
                              ? <UserLatest data={data.user_provider.data} />
                              : null}

                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default DashboardAdmin;
