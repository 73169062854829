const config = {
  //remote url

  //url: "https://farmsell.org/API/public/api/user/",

  url: 'https://farmsell.org/API/public/api/admin/',
  domain_name: 'https://farmsell.org/API/public',

  //Local Url
  // url: "https://farmsell.org/API/public/api/user/",

  /*
  url: 'http://127.0.0.1:8000/api/admin/',
  domain_name: 'http://127.0.0.1:8000',
  */

  //Login API constants

  LOGIN_BY: 'manual',

  DEVICE_TYPE: 'web',

  DEVICE_TOKEN: '123456',
};

export default config;
