import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Table, Button} from 'react-bootstrap';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid';
//import PageContent from './PageContent';
import '../../assets/css/dashboard.css';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {Link} from 'react-router-dom';
import Request from '../utls/request';
import PageModal from './pageModal';
import ToastObject from '../shared/toast';

const AdminPages = () => {
  const [PageContent, setPageContent] = useState (null);
  const [isActive, setIsActive] = useState (true);

  const [modalShow, setModalShow] = useState(false);

  const handleClick = () => {
    window.location.href = '/admintestimonials';
  };

  const handleAddPage = (id) => {
    window.location.href = `/adminaddpage/${id}`;
  };

  const checkChange=(e, id)=>{

    if(e.target.value==='delete'){
      deletePage(id);
    }

  }

  const deletePage=(id)=>{

    const confirm=window.confirm('Are you sure you want to perform this action?');


    if(confirm){

    Request.getMethod(`delete-page/${id}`)
    .then(response=>{
        if(response.data.result){
            ToastObject.ToastSuccess('Deleted succesfully');
            getPages();
        }
    })
    .catch(error=>{
      console.log(error)
    })
  }

  }

  const getPages = () => {  const deletePage=(id)=>{

    const confirm=window.confirm('Are you sure you want to perform this action?');

    if(confirm)
    Request.getMethod(`delete-page/${id}`)
    .then(response=>{
        if(response.data.result){
            ToastObject.ToastSuccess('Deleted succesfully');
        }
    })

  }
    
    setModalShow(false);
    Request.getMethod ('get-page')
      .then (response => {
        console.log (response.data);
        setPageContent (response.data.page);
        setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  React.useEffect (() => {
    getPages ();
  }, []);

  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>
          <div className="userManager-cont">
            <div>
              <div className="display-admin">
                <div className="admin-title">
                  PAGES
                  <Link
                    to="/"
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#272D3B',
                      fontWeight: 'bold',
                    }}
                  >
                    Page View
                  </span>
                </div>
                <div className="input-icons display-admin">
                  <i className="fa fa-user icon" />
                  <input
                    className="input-field"
                    type="text"
                    placeholder="Search by user's name, email,..."
                  />
                </div>
              </div>
              <div className="display-admin" style={{marginBottom: '10px'}}>
                <div className="display-admin admin-show">
                  <span>Show &nbsp;</span>
                  <select name="show" id="show" className="sort-by">
                    <option value="5" style={{width: '100px'}}>5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                  </select>
                  <span>&nbsp; Entries</span>
                </div>
                <div className="display-admin admin-show">
                  <span onClick={() => setModalShow(true)} className="add-btn ">
                    Add Page
                  </span>
                </div>
              </div>
            </div>
            <div className="overflow" style={{height: '440px'}}>
              <Table borderless hover responsive style={{fontSize: '12px'}}>
                <thead style={{background: '#E3E9EF', color: '#B3B8BD'}}>
                  <tr style={{textAlign: 'left'}}>
                    <th>S No</th>
                    <th>Header</th>
                    <th>Section Type</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>

                {PageContent && PageContent.length > 0
                  ? PageContent.map ((cont, index) => {
                      return (
                        <tbody key={index+'item'}>
                          {/* <Link to="admintestimonials"> */}
                          <tr
                            key={index}
                            style={{textAlign: 'left', cursor: 'pointer'}}
                          
                          >
                            <td> {index+1} </td>
                            <td><a href='#' style={{textDecoration:'none'}} onClick={()=>handleAddPage(cont.id)}> {cont.name}</a> </td>
                            <td>{cont.sectionType}</td>
                            <td>
                              <span
                                style={{
                                  background: '#3EB971',
                                  color: '#fff',
                                  fontSize: '12px',
                                  fontWeight: '700',
                                  padding: '3px',
                                  borderRadius: '4px',
                                }}
                              >
                                approved
                              </span>
                            </td>
                            <td>
                              <select
                                name="show"
                                id="show"
                                style={{
                                  width: '65px',
                                  border: '1px solid #EC2A82',
                                  height: '20px',
                                  borderRadius: '4px',
                                  color: '#EC2A82',
                                }}
                                onChange={(e)=>checkChange(e, cont.id)}
                              >
                                <option value="0" style={{width: '100px'}}>
                                  Action
                                </option>
                                <option value="delete" style={{width: '100px'}}>
                                  Delete
                                </option>
                                <option value="suspend">Suspend</option>
                                <option value="edit">Edit</option>
                              </select>
                            </td>
                          </tr>
                          {/* </Link> */}
                        </tbody>
                      );
                    })
                  : null}
              </Table>

             <PageModal
             show={modalShow}
             onHide={() => setModalShow(false)}
             getPage={getPages}

             />
            </div>

          </div>
          
        </div>

        
      </div>

      

    </div>
  );
};

export default AdminPages;
