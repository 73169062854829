import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid';
import {Link} from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {useHistory} from 'react-router-dom';
import Request from '../utls/request';
import ToastObject from '../shared/toast';
import config from '../utls/constant';

const AdminMainSlide = () => {
  const history = useHistory ();

  const [imageAttached, setImageAttached] = useState ([]);

  const [data, setData] = useState ([]);

  const [data_status, setDataStatus] = useState (true);

  const attachImage = e => {
    const image = e.target.files[0];

    setImageAttached ([...imageAttached, image]);
  };

  const removeImage = index => {
    var spliced_array = [...imageAttached];
    spliced_array.splice (index, 1);

    setImageAttached (spliced_array);
  };

  const handleAddMainSlide = () => {
    history.push ('/addmainslide');
  };

  const deleteBanner = id => {
    var ele = document.getElementById ('delete_btn_' + id);
    console.log (ele);
    ele.innerHTML = 'Deleting...';
    ele.disabled = true;

    Request.getMethod (`delete-main-banner/${id}`).then (response => {
      if (response.data.result) {
        ToastObject.ToastSuccess ('Banner Deleted');
        window.location.reload ();
      }
    });
  };

  const saveBanner = index => {
    var ele = document.getElementById ('save_btn_' + index);
    console.log (ele);
    ele.innerHTML = 'Saving...';
    ele.disabled = true;

    const body = {
      picture: imageAttached[index],
    };

    Request.postMethod ('add-main-banner', body)
      .then (response => {
        if (response.data.result) {
          ToastObject.ToastSuccess ('Banner added succesfully');
          window.location.reload ();
        }
      })
      .catch (error => {});
  };

  const getBanners = () => {
    Request.getMethod ('get-main-banner')
      .then (response => {
        // alert (JSON.stringify (response.data));
        //console.log (response.data);
        setData (response.data.banner);
        setDataStatus (false);
        // setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        console.log (error.response);
      });
  };

  React.useEffect (() => {
    getBanners ();
  }, []);
  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>
          {/* <Col></Col> */}
          <div className="userManager-cont">
            <div>
              <div className="display-admin">
                <div className="admin-title" style={{position: 'fixed'}}>
                  SITE SETTINGS
                  <Link
                    to="/"
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#272D3B',
                      fontWeight: 'bold',
                    }}
                  >
                    Main Slides
                  </span>
                </div>
              </div>
            </div>

            {/* commented out
           <div className='overflow'  style={{ height: '70vh' }} >
           */}
            <div style={{height: '70vh'}}>

              <Row>
                <Col md={3}>
                  <div
                    className="site-set-side"
                    style={{position: 'fixed', marginTop: '2rem'}}
                  >
                    <p><Link to="./sitesettings">SITE INFORMATION</Link></p>
                    <p><Link to="./password-management">Password Settings</Link></p>
                    <p style={{background: '#3EB971', color: '#fff'}}>
                      MAIN SLIDES
                    </p>
                    <p>
                      <Link to="./categoriesbanner">CATEGORIES' BANNERS</Link>
                    </p>
                    <p><Link to="./languages">LANGUAGE</Link></p>
                    <p><Link to="./gif">GIF</Link></p>
                  </div>
                </Col>
                <Col />

                <Col md={8}>

                  <input
                    onChange={event => attachImage (event)}
                    type="file"
                    id="file_banner"
                    name="image2"
                    accept="image/gif,image/jpeg,image/jpg,image/png"
                    multiple=""
                    data-original-title="upload photos"
                    hidden
                  />
                  <div
                    className="displayFlex"
                    style={{marginBottom: '2rem', marginRight: '0.3rem'}}
                  >
                    <span className="site-set-m-title">MAIN SLIDES</span>
                    <label for="file_banner">
                      <Button
                        className="mainslide-btn"
                        style={{background: '#3EB971', cursor: 'pointer'}}
                      >
                        <label style={{cursor: 'pointer'}} for="file_banner">
                          Add Slide
                        </label>
                      </Button>
                    </label>
                  </div>
                  {/* auto overflow code  commented out

                                         <Col style={{ height:'50vh', overflowY:'auto'}}>
                                         */}
                  <Col>

                    {imageAttached.map ((item, index) => (
                      <Row>
                        <Col md={9}>
                          <div style={{display: 'block'}}>
                            <div className="site-set-m-slide">
                              <img
                                src={URL.createObjectURL (item)}
                                alt=""
                                style={{width: '10rem', height: '5rem'}}
                              />
                              {
                                // &emsp;<span>Second Slide</span>
                              }
                            </div>
                          </div>
                        </Col>
                        <Col md={2}>
                          <Button
                            className="mainslide-btn"
                            onClick={() => removeImage (index)}
                            style={{background: '#3EB971'}}
                          >
                            Remove Slide
                          </Button>
                          <Button
                            onClick={e => saveBanner (index)}
                            className={`mainslide-btn ${'save_btn_' + index}`}
                            id={`save_btn_${index}`}
                            style={{background: '#F7B617'}}
                          >
                            Save Slide
                          </Button>
                        </Col>
                      </Row>
                    ))}

                    {data.map (item => (
                      <Row>
                        <Col md={9}>
                          <div style={{display: 'block'}}>
                            <div className="site-set-m-slide">
                              <img
                                src={`${config.domain_name}/storage/banner/${item.url}`}
                                alt=""
                                style={{width: '10rem', height: '5rem'}}
                              />
                              {
                                // &emsp;<span>Second Slide</span>
                              }
                            </div>
                          </div>
                        </Col>
                        <Col md={2}>
                          <Button
                            className="mainslide-btn"
                            style={{background: '#3EB971', marginTop: '1.5rem'}}
                            onClick={() => deleteBanner (item.id)}
                            id={`delete_btn_${item.id}`}
                          >
                            Remove Slide
                          </Button>

                        </Col>
                      </Row>
                    ))}

                  </Col>

                </Col>
                {data_status
                  ? <div className="d-flex justify-content-center">
                      <div className="spinner-border" role="status" />
                    </div>
                  : null}

              </Row>

            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default AdminMainSlide;
