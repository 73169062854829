import React from 'react';
import ReactApexChart from 'react-apexcharts'

const SiteViewsandVisits = () => {

    let options = {
        plotOptions: {
          bar: {
            dataLabels: {
              position: "top"
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May",  "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
          position: "bottom",
          labels: {
            offsetY: 0
          },
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          tooltip: {
            enabled: false,
            offsetY: -35
          }
        },
        fill: {
          colors: '#3EB971'
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: false
          },
          labels: {
            show: true
          }
        },
        title: {
          text: "SITE VIEWS AND VISITS",
          floating: true,
          offsetY: 0,
          align: "left",
          style: {
            color: "#444"
          }
      },
      subtitle: {
        text: "Hey, see how the site being visited when you are away",
        align: 'left',
        margin: 50,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '12px',
          fontWeight:  'normal',
          fontFamily:  undefined,
          color:  '#9699a2'
        },
      },
        chart: {
          animations: {
            enabled: true
          }
        }
      }
      let series = [
        {
          name: "views & Visits",
          data: [89, 12, 98, 10, 35, 70, 55, 89, 77, 20, 50, 67]
        }
      ]
   
    return (
        <div className="donut">
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height="400"
            />
        </div>
    );
}

export default SiteViewsandVisits;