import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { faChevronRight } from '@fortawesome/fontawesome-free-solid';
import tomato from '../../assets/images/tomato.png';
import { Link } from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';

const Gif = () => {
    return (
        <div className='admin-bg'>
            <div className='main' >
                <AdminHeader />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='admin-side'>
                        <Sidebar />
                    </div>
                    <div className='userManager-cont' >
                        <div>
                            <div className='display-admin'>
                                <div className='admin-title'>
                                    SITE SETTINGS
                                    <Link to="/" className='admib-nav' style={{ fontSize: '14px', fontWeight: '400', textDecoration:'none', borderLeft:'1px solid #D3D3D3', paddingLeft:'3px', marginLeft:'3px', color:'#D3D3D3' }}>Home <FontAwesomeIcon icon={faChevronRight} /></Link>
                                    <span style={{ fontSize: '14px', fontWeight: '400', color:'#272D3B', fontWeight:'bold' }}>Categories Slides</span>
                                </div>
                            </div>
                        </div>
                        <div className='site-info' >
                            <Row>
                                <Col md={3}>
                                    <div className='site-set-side' style={{}}>
                                        <p><Link to="./sitesettings">SITE INFORMATION</Link></p>
                                        <p><Link to="./password-management">Password Settings</Link></p>
                                        <p><Link to="./adminmainslide">MAIN SLIDES</Link></p>
                                        <p><Link to="./categoriesbanner">CATEGORIES' BANNERS</Link></p>
                                        <p><Link to="./languages">LANGUAGE</Link></p>
                                        <p style={{background:'#3EB971', color:'#fff'}}>GIF</p>
                                    </div>
                                </Col>
                                <Col></Col>
                                <Col md={8}>
                                    <div className='displayFlex' style={{marginBottom:'2rem', marginRight:'0.3rem'}}>
                                        <span className='site-set-m-title'>GIF</span>
                                        <Button className='mainslide-btn' style={{background:'#3EB971'}}>Save Changes</Button>
                                    </div>
                                    
                                    <div className='autoscroll'>
                                        <div style={{marginBottom:'20px'}}>
                                            <p style={{fontWeight:'600'}}>Upload GIF here</p>
                                            
                                            <Form>
                                                <div className='displayFlex gifHide' >
                                                    <Form.Group className="mb-3" controlId="file" style={{marginLeft:'1rem', marginTop:'1rem'}}>
                                                        <Form.Control type="file" placeholder="Browse..." />
                                                    </Form.Group>
                                                    <Button style={{ background: '#3EB971', height: '70px', width: '200px', border: 'none' }}>Upload</Button>
                                                </div>
                                                <p>Use GIF formate</p>
                                                <div style={{textAlign:'right'}}>
                                                    <Button className='mainslide-btn' style={{background:'#FF0000', marginRight:'10px'}}>Delete</Button>
                                                    <Button className='mainslide-btn' style={{background:'#3EB971'}}>Save Changes</Button>
                                                </div>
                                            </Form>
                                            
                                            <p style={{ fontWeight: '600' }}>Create Quick GIF</p>
                                            <p>GIF review</p>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="file" style={{marginLeft:'1rem', marginTop:'1rem'}}>
                                                    <Form.Control as="textarea" />
                                                </Form.Group>
                                            
                                                <p style={{ fontWeight: '600' }}>Background</p>

                                                <div className='displayFlex' >
                                                    <div>
                                                        <span style={{background:'#3EB971', color:'#3EB971', padding:'1rem', borderRadius:'50%', boxShadow:'0px 3px 6px #00000029'}}>....</span> &emsp;
                                                        <span>Sold color</span>
                                                    </div>
                                                    <div>
                                                        <span className='gradient' style={{ color:'#3EB971', padding:'1rem', borderRadius:'50%', boxShadow:'0px 3px 6px #00000029'}}>....</span> &emsp;
                                                        <span>Gradient color</span>
                                                    </div>
                                                    <div>
                                                        <img src={tomato} alt='' style={{width:'40px', borderRadius:'50%', boxShadow:'0px 3px 6px #00000029'}} />&emsp;
                                                        <span>Image Background</span>
                                                    </div>
                                                </div>
                                                <div style={{marginTop:'30px'}} >
                                                    <p style={{ fontWeight: '600' }}>Text</p>
                                                    <p>Write Header Text Here</p>
                                                    <Form.Group className="mb-3" controlId="file" style={{marginLeft:'1rem', marginTop:'1rem'}}>
                                                        <Form.Control as="textarea" />
                                                    </Form.Group>
                                                    <div className='displayFlex' >
                                                        <Form.Check 
                                                            type="radio"
                                                            id="rightToLeft"
                                                            label="Right to Left"
                                                        />
                                                        <Form.Check 
                                                            type="radio"
                                                            id="leftToRight"
                                                            label="Left To Right"
                                                        />
                                                        <Form.Check 
                                                            type="radio"
                                                            id="playAutomatically"
                                                            label="Play Automatically"
                                                        />
                                                        <Form.Check 
                                                            type="radio"
                                                            id="loopPlayback"
                                                            label="Loop Playback"
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{marginTop:'30px'}} >
                                                    <p>Write SubHeader Text Here</p>
                                                    <Form.Group className="mb-3" controlId="file" style={{marginLeft:'1rem', marginTop:'1rem'}}>
                                                        <Form.Control as="textarea" />
                                                    </Form.Group>
                                                    <div className='displayFlex' >
                                                        <Form.Check 
                                                            type="radio"
                                                            id="rightToLeft"
                                                            label="Right to Left"
                                                        />
                                                        <Form.Check 
                                                            type="radio"
                                                            id="leftToRight"
                                                            label="Left To Right"
                                                        />
                                                        <Form.Check 
                                                            type="radio"
                                                            id="playAutomatically"
                                                            label="Play Automatically"
                                                        />
                                                        <Form.Check 
                                                            type="radio"
                                                            id="loopPlayback"
                                                            label="Loop Playback"
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{textAlign:'right', marginTop:'30px'}}>
                                                    <Button className='mainslide-btn' style={{background:'#F7B618', marginRight:'10px'}}>Discard GIF</Button>
                                                    <Button className='mainslide-btn' style={{background:'#3EB971'}}>Create GIF</Button>
                                                </div>
                                            </Form>
                                        </div>
                                        
                                        
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Gif;