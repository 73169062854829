import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Row, Col, Button, Modal} from 'react-bootstrap';
import {
  faChevronRight,
  faExclamationTriangle,
} from '@fortawesome/fontawesome-free-solid';
import tpro from '../../assets/images/tpro.png';
import AdminHeader from '../AdminHeader';
import ProgressBar from '@ramonak/react-progress-bar';
import ReactStars from 'react-rating-stars-component';
import Sidebar from '../Sidebar';
import Request from '../utls/request';
import {useParams} from 'react-router-dom';
import config from '../utls/constant';
import LoadingOverlay from 'react-loading-overlay';
import ToastObject from '../shared/toast';
import {useHistory} from 'react-router-dom';
import ImageGallery from './ImageGallery';

const AdminProductDetails = () => {
  const {id} = useParams ();
  const [data, setData] = useState ({});
  const [isActive, setIsActive] = useState (true);
  const [show, setShow] = useState (false);

  const [gallery, setGallery] = useState (false);
  const handleClose = () => setShow (false);

  const history = useHistory ();

  const confirmDelete = () => {
    setShow (true);
  };

  const getProductDetail = () => {
    Request.getMethod (`product-view/${id}`)
      .then (response => {
        console.log (response.data);
        setData (response.data.details[0]);
        setGallery (response.data.gallery);
        setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  const deleteProduct = () => {
    setShow (false);
    Request.getMethod (`delete-product/${id}`)
      .then (response => {
        ToastObject.ToastSuccess ('Product deleted Succesfully!!');

        history.push ('/adminproducts');
        //getPro();
        //setShow(false)

        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        //console.log (error.response);
      });
  };

  React.useEffect (() => {
    getProductDetail ();
  }, []);

  return (
    <LoadingOverlay active={isActive} spinner text="Loading data...">
      <div className="admin-bg">
        <div className="main">
          <AdminHeader />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="admin-side">
              <Sidebar />
            </div>
            {/* <Col></Col> */}
            <div className="userManager-cont">
              <div style={{paddingTop: '1rem'}}>
                <div className="display-admin">
                  <div className="admin-title">
                    PRODUCTS
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        borderLeft: '1px solid #D3D3D3',
                        paddingLeft: '3px',
                        marginLeft: '3px',
                        color: '#D3D3D3',
                      }}
                    >
                      Home <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        borderLeft: '1px solid #D3D3D3',
                        paddingLeft: '3px',
                        marginLeft: '3px',
                        color: '#D3D3D3',
                      }}
                    >
                      Products View <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                    <span style={{fontSize: '14px', fontWeight: '400'}}>
                      Products Details
                    </span>
                  </div>
                </div>
              </div>
              <div className="autoscroll" style={{paddingTop: '1rem'}}>

                <Row>
                  <Col md={2}>
                    <div style={{display: 'block', textAlign: 'center'}}>

                      <ImageGallery gallery={gallery} />

                    </div>
                  </Col>
                  <Col md={4}>
                    <img
                      src={`${config.domain_name}/storage/product/${data.picture}`}
                      className="Mpro"
                      alt=""
                    />
                    <div style={{textAlign: 'right'}}>
                      <Button
                        className="product-details-btn"
                        onClick={confirmDelete}
                      >
                        Delete
                      </Button>
                      <Button className="product-details-btn">SUSPEND</Button>
                    </div>
                  </Col>
                  <Col md={6} style={{}}>

                    <div>
                      <h4 style={{fontWeight: '900'}}>{data.product_title}</h4>
                      <Row>
                        <Col>
                          <h6 style={{fontWeight: '700'}}>Product Owner</h6>
                        </Col>
                        <Col><h6>{data.full_name}</h6></Col>
                      </Row>
                      <Row>
                        <Col>
                          <h6 style={{fontWeight: '700'}}>Posted On</h6>
                        </Col>
                        <Col><h6>{data.created_at}</h6></Col>
                      </Row>

                      <Row>
                        <Col>
                          <h6 style={{fontWeight: '700'}}>Phone</h6>
                        </Col>
                        <Col>
                          <h6>
                            {' '}{data.country_code + (data.phone&&data.phone.substring (1))}
                          </h6>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <h6 style={{fontWeight: '700'}}>Email</h6>
                        </Col>
                        <Col><h6>{data.email}</h6></Col>
                      </Row>
                      <Row>
                        <Col><h6 style={{fontWeight: '700'}}>Location</h6></Col>
                        <Col><h6>{data.address}</h6></Col>
                      </Row>
                      {/*
                                        <Row>
                                            <Col><h6 style={{ fontWeight: '700' }}>Location</h6></Col>
                                            <Col><h6>Kampala</h6></Col>
                                        </Row>
                                        */}
                      <Row>
                        <Col><h6 style={{fontWeight: '700'}}>Price</h6></Col>
                        <Col><h6>{data.price}</h6></Col>
                      </Row>
                      <Row>
                        <Col><h6 style={{fontWeight: '700'}}>Viewed:</h6></Col>
                        <Col><h6>{data.view_count}</h6></Col>
                      </Row>
                    </div>
                    <br />
                    <h6 style={{fontWeight: '700'}}>Rate & Reviews</h6>
                    <Row>
                      <Col sm={4}>
                        <div>
                          <span style={{fontWeight: '600', fontSize: '50px'}}>
                            3.7
                          </span>
                          <ReactStars
                            count={5}
                            size={18}
                            value="3"
                            isHalf="true"
                            activeColor="#EFBA1E"
                          />
                          <p style={{fontSize: '15px'}}>46,375</p>
                        </div>
                      </Col>
                      <Col sm={8}>
                        <div style={{marginTop: '20px'}}>
                          <ProgressBar
                            height="13px"
                            className="ratingProgressBar"
                            completed={94}
                            bgColor="#EFBA1E"
                            isLabelVisible="false"
                            labelAlignment="center"
                          />
                        </div>
                        <div style={{marginTop: '4px'}}>
                          <ProgressBar
                            height="13px"
                            className="ratingProgressBar"
                            completed={70}
                            bgColor="#EFBA1E"
                            isLabelVisible="false"
                            labelAlignment="center"
                          />
                        </div>
                        <div style={{marginTop: '4px'}}>
                          <ProgressBar
                            height="13px"
                            className="ratingProgressBar"
                            completed={50}
                            bgColor="#EFBA1E"
                            isLabelVisible="false"
                            labelAlignment="center"
                          />
                        </div>
                        <div style={{marginTop: '4px'}}>
                          <ProgressBar
                            height="13px"
                            className="ratingProgressBar"
                            completed={30}
                            bgColor="#EFBA1E"
                            isLabelVisible="false"
                            labelAlignment="center"
                          />
                        </div>
                        <div style={{marginTop: '4px'}}>
                          <ProgressBar
                            height="13px"
                            className="ratingProgressBar"
                            completed={20}
                            bgColor="#EFBA1E"
                            isLabelVisible="false"
                            labelAlignment="center"
                          />
                        </div>
                        <div style={{marginTop: '4px'}}>
                          <ProgressBar
                            height="13px"
                            className="ratingProgressBar"
                            completed={10}
                            bgColor="#EFBA1E"
                            isLabelVisible="false"
                            labelAlignment="center"
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

      </div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{padding:'30px'}}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color: '#FF0000', fontWeight: 'bold'}}>
            <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION!!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color: '#FF0000', fontSize: '11px'}}>
          Once you've deleted the record , the User will no longer be available. This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            style={{backgroundColor: '#D3D3D3', border: 'none'}}
          >
            No!
          </Button>
          <Button
            variant="primary"
            onClick={deleteProduct}
            style={{
              marginRight: '20px',
              backgroundColor: '#3EB971',
              border: 'none',
            }}
          >
            Yes!
          </Button>
        </Modal.Footer>
      </Modal>

    </LoadingOverlay>
  );
};

export default AdminProductDetails;
