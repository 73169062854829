import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Row, Col, Form, Button, Modal} from 'react-bootstrap';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid';

//import tpro from '../../assets/images/tpro.png';
import AdminHeader from '../AdminHeader';
//import ProgressBar from '@ramonak/react-progress-bar';
//import ReactStars from 'react-rating-stars-component';
import Sidebar from '../Sidebar';
import Request from '../utls/request';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom';

import config from '../utls/constant';
import ImageGallery from '../products/ImageGallery';

const OrderDetails = () => {
  const [data, setData] = useState ({});
  const [isActive, setIsActive] = useState (true);

  const [show_category, setShowCategory] = useState (false);

  const {id} = useParams ();

  const history = useHistory ();

  const getProductDetail = () => {
    Request.getMethod (`order/${id}`)
      .then (response => {
        //alert (JSON.stringify (response.data.order));
        setData (response.data.order);
        //setGallery (response.data.gallery);
        setIsActive (false);
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  React.useEffect (() => {
    getProductDetail ();
  }, []);

  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>
          {/* <Col></Col> */}
          <div className="userManager-cont">
            <div>
              <div className="display-admin">
                <div className="admin-title">
                  Orders
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Order view <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span style={{fontSize: '14px', fontWeight: '400'}}>
                    Order details
                  </span>
                </div>
              </div>
            </div>
            <div className="overflow" style={{paddingTop: '1rem'}}>
              <Row>
                <Col md={2}>
                  <div style={{display: 'block', textAlign: 'center'}}>

                    <ImageGallery gallery={data.gallery} />
                  </div>
                </Col>
                <Col md={4}>
                  <img
                    src={`${config.domain_name}/storage/product/${data.product ? data.product.picture : ''}`}
                    className="Mpro"
                    alt=""
                  />
                  <div style={{display: 'flex', justifyContent: 'center'}} />
                </Col>
                <Col md={6} style={{}}>

                  <div>
                    <h4 style={{fontWeight: '900'}}>{data.product_title}</h4>
                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Product name</h6>
                      </Col>
                      <Col>
                        <h6>
                          {data.product ? data.product.product_title : ''}
                        </h6>
                      </Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Buyer</h6>
                      </Col>
                      <Col style={{display: 'flex', alignItems: 'center'}}>
                        <Col>
                          <h6>{data.buyer ? data.buyer.full_name : ''}</h6>
                        </Col>
                      </Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Seller</h6>
                      </Col>
                      <Col>
                        <h6>{data.seller ? data.seller.full_name : ''}</h6>
                      </Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Seller Email</h6>
                      </Col>
                      <Col><h6>{data.seller ? data.seller.email : ''}</h6></Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Seller Phone</h6>
                      </Col>
                      <Col>
                        <h6>{data.seller ? data.seller.phone : ''}</h6>
                      </Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Buyer Email</h6>
                      </Col>
                      <Col><h6>{data.buyer ? data.buyer.email : ''}</h6></Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Buyer Phone</h6>
                      </Col>
                      <Col><h6>{data.buyer ? data.buyer.phone : ''}</h6></Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Status</h6>
                      </Col>
                      <Col>

                        {data.order_status == 1
                          ? <span style={{color: 'green'}}>
                              Accepted
                            </span>
                          : data.order_status == 2
                              ? <span style={{color: 'red'}}>
                                  Cancelled
                                </span>
                              : <span style={{color: 'yellow'}}>
                                  Pending
                                </span>}
                      </Col>
                    </Row>
                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Date ordered</h6>
                      </Col>
                      <Col><h6>{data.created_at}</h6></Col>
                    </Row>
                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col><h6 style={{fontWeight: '700'}}>Location</h6></Col>
                      <Col><h6>{data.address}</h6></Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col><h6 style={{fontWeight: '700'}}>Price</h6></Col>
                      <Col><h6>{data.amount}</h6></Col>
                    </Row>
                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Open Status:</h6>
                      </Col>
                      <Col>
                        {data.order_status == 1
                          ? <span style={{color: 'green'}}>
                              Opened
                            </span>
                          : <span style={{color: 'red'}}>
                              Unopened
                            </span>}
                      </Col>
                    </Row>
                  </div>
                  <br />
                  {/*
                  <h6 style={{fontWeight: '700'}}>Rate & Reviews</h6>
                  */}

                </Col>
              </Row>
            </div>
          </div>

        </div>
      </div>

    </div>
  );
};

export default OrderDetails;
