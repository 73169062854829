import React from 'react';
import ReactApexChart from 'react-apexcharts';

const UserandProductsGraph = props => {
  let stat = props.stat;

  const series = [
    {
      name: 'Users',
      data: stat.user ? stat.user : [],
    },
    {
      name: 'Products',
      data: stat.product ? stat.product : [],
    },
  ];
  const options = {
    chart: {
      // height: 230,
      type: 'line',
      stacked: false,
      offsetY: -15,
      // height: '50%',
      zoom: {
        enabled: true,
      },
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    fill: {
      colors: undefined,
      opacity: 0,
      type: 'solid',
    },

    markers: {
      size: 5,
    },

    title: {
      text: 'Users & Products',
      align: 'left',
      margin: 0,
      offsetX: 0,
      offsetY: 0,
      floating: true,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238',
      },
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },

    xaxis: {
      // type: 'datetime',
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      // offsetX: 10,
      // offsetY: -50,
    },
    // tooltip: {
    //   x: {
    //     format: 'dd/MM/yy HH:mm'
    //   },
    // },
  };

  return (
    <div
      style={{
        padding: '20px',
        width: '100%',
        height: '30vh',
      }}
    >
      <ReactApexChart options={options} series={series} type="area" />

    </div>
  );
};

export default UserandProductsGraph;
