import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Table, Button, Modal} from 'react-bootstrap';
import Sidebar from '../Sidebar';

import AdminHeader from '../AdminHeader';
import '../../assets/css/dashboard.css';

import '../../assets/css/modal.css';
import Request from '../utls/request';

import auth from '../auth/aut';

import LoadingOverlay from 'react-loading-overlay';

import {useHistory} from 'react-router-dom';

import ReactPaginate from 'react-paginate';

var User = auth.authData ();

var UserToken = User ? User.Token : null;

const Orders = () => {
  const [message, setMessage] = useState ('');
  const [subject, setSubject] = useState ('');
  const [email, setEmail] = useState ('');

  const [error, setError] = useState ('');

  const [data, setData] = useState ([]);
  const [show, setShow] = useState (false);

  const [total, setTotal] = useState (1);
  const [page, setPage] = useState (1);

  const [viewData, setViewData] = useState ({});

  const [isActive, setIsActive] = useState (true);

  //pagination

  const history = useHistory ();

  const handleDetails = id => {
    history.push (`/order/${id}`);
  };

  ///getCountry ('+256');

  const exportToCSV = () => {};

  const scrollToTop = id => {
    const element = document.getElementById (id);
    element.scrollTop = 0;
  };

  const getOrders = id => {
    //alert (filter_count);
    //alert("Trecking")

    setIsActive (true);

    Request.getMethod (`orders?page=${id}`)
      .then (response => {
        //console.log (response.data);
        // alert(JSON.stringify(response.data))

        setData (response.data.orders.data);
        setTotal (response.data.orders.total);
        setPage (response.data.orders.current_page + 1);
        scrollToTop ('top_div_item');
        //alert(response.data.orders.total)

        //alert(JSON.stringify(response.data.email))

        //alert('ok')
        setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
        // alert("Ok")
      });
  };

  const handlePageClick = event => {
    getOrders (event.selected + 1);
    //console.log (event.selected);
  };

  React.useEffect (() => {
    getOrders (1);

    // alert('mm')

    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  }, []);

  // Export tableto excel

  const setView = data => {
    setViewData ({...viewData, ...data});
    setShow (true);
  };

  return (
    <LoadingOverlay active={isActive} spinner text="Loading data...">
      <div className="admin-bg">
        <div className="main">
          <AdminHeader />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{width: '25vw'}}>
              <Sidebar />
            </div>

            <div className="userManager-cont" id="top_div_item">

              <div className="adminScroll">

                <Table borderless hover responsive style={{fontSize: '12px'}}>
                  <thead style={{background: '#E3E9EF', color: '#B3B8BD'}}>
                    <tr style={{textAlign: 'left', width: '100%'}}>
                      <th className="col-sm-3">No</th>

                      <th className="col-sm-3">Product name</th>
                      <th className="col-sm-3">Qunatity</th>

                      <th className="col-sm-3">
                        Order status
                      </th>

                      <th className="col-sm-3">Date Ordered</th>
                      <th className="col-sm-3" />

                    </tr>
                  </thead>
                  {data
                    ? data.map ((cont, index) => {
                        return (
                          <tbody key={index}>
                            <tr
                              style={{textAlign: 'left', cursor: 'pointer'}}
                              //onClick={handleDetails}
                            >
                              <td>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  {index + 1}
                                </div>
                              </td>
                              <td
                                style={{textAlign: 'left'}}
                                onClick={() => setView (cont)}
                              >

                                {' '}
                                <a
                                  href="#"
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                  }}
                                >
                                  {cont.product_name}
                                </a>
                              </td>

                              <td>
                                {cont.qty}
                              </td>

                              <td>
                                {cont.order_status == 1
                                  ? <span style={{color: 'green'}}>
                                      Accepted
                                    </span>
                                  : cont.order_status == 2
                                      ? <span style={{color: 'red'}}>
                                          Cancelled
                                        </span>
                                      : <span style={{color: 'gray'}}>
                                          Pending
                                        </span>}
                              </td>

                              <td style={{fontSize: '10px'}}>
                                {cont.created_at}
                              </td>

                              <td>
                                <Button
                                  className="admin-del-btn"
                                  size="sm"
                                  onClick={() => handleDetails (cont.id)}
                                >
                                  View Email
                                </Button>
                              </td>

                            </tr>
                          </tbody>
                        );
                      })
                    : null}
                </Table>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingBottom: 100,
                  }}
                >

                  {data.length !== 0
                    ? <ReactPaginate
                        previousLabel={'Prev'}
                        nextLabel={'Next'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={total / 20}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                      />
                    : null}
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>
    </LoadingOverlay>
  );
};

export default Orders;
