import React, { useState, useEffect } from 'react';
import axios from 'axios';

function PaginationTest() {

    const [posts, setPosts] = useState()

    useEffect(() => {
        axios.get("https://jsonplaceholder.typicode.com/todos").then(res => {
            console.log(res.data)
            setPosts(res.data)
            // setPaginatedPost(_(response.data).slice(0).take(pageSize).value())
        });
    }, []);

  return (
      <div>
        {
            !posts ? ("No data found") : (
                <table>
                      <thead>
                          <tr>
                              <th>id</th>
                              <th>userId</th>
                              <th>title</th>
                              <th>completed</th>
                          </tr>
                      </thead>
                      <tbody>
                          {
                              posts.map((post, index) => {
                                  <tr>
                                      <th>{post.id} </th>
                                      <th>{post.userId}</th>
                                      <th>{post.title}</th>
                                  </tr>
                              })
                          }
                      </tbody>
                </table>
            )
        }
      </div>
  )
}

export default PaginationTest