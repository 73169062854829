import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Button, Form} from 'react-bootstrap';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid';
import '../assets/css/dashboard.css';
import AdminHeader from './AdminHeader';
import Sidebar from './Sidebar';
import {Link} from 'react-router-dom';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Request from './utls/request';
import ToastObject from './shared/toast';
import {useHistory} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {faExclamationTriangle} from '@fortawesome/fontawesome-free-solid';

function ProductType () {
  //const [type, setType] = useState ('mmmmmm');
  const [add, setAdd] = useState ([]);

  const [product_type, setProductTypeName] = useState ('');
  const history = useHistory ();
  const [show, setShow] = useState (false);
  const handleClose = () => setShow (false);

  const [send_id, setSendId] = useState ('');

  const [category_id, setCategoryId] = useState ('');
  const [edit_mode, setEditMode] = useState (false);
  const [category, setCategory] = useState (null);
  const [category_name, setCategoryName] = useState ('');
  const [message, setMessage] = useState ('Save Changes');

  let {id} = useParams ();
  //console.log(id)

  // alert(id)

  const removeProductTpe = (index, id) => {
    if (id) {
      //removeSubInEdit (id);
      setShow (true);
      setSendId ({
        id: id,
        index: index,
      });
      return;
    }
    var spliced_array = [...add];
    spliced_array.splice (index, 1);

    setAdd (spliced_array);
  };

  const removeSubInEdit = () => {
    setShow (false);
    var spliced_array = [...add];
    spliced_array.splice (send_id.index, 1);

    setAdd (spliced_array);

    Request.getMethod (`subcategory/delete/${send_id.id}`)
      .then (response => {
        if (response.data.result) {
          ToastObject.ToastSuccess ('Sub Category removed !!');
        }
        //alert(JSON.stringify(data))
        //getCategoriesForEdit ();
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  const textChange = e => {
    //console.log (e);
    // return
    const val = e.target.value;
    setProductTypeName (val);
  };

  const displayType = () => {
    const data_object = {
      name: product_type,
      id: '',
    };

    //var new_array = add;

    //new_array.push (data_object);

    // setType(e.target.value)
    setAdd (oldArray => [...oldArray, data_object]);

    setProductTypeName ('');
    //console.log (add);

    //console.log(e.target.value)
  };

  // const addType = () => {
  //     setType.push(input)
  //     setAdd(true)
  // }

  const onCategoryChange = e => {
    setCategoryId (e.target.value);
    setAdd ([]);
    getCategoriesForEdit (e.target.value);

    //alert (e.target.value);
  };

  const getCategories = () => {
    Request.getMethod ('get/category/list').then (response => {
      // console.log(response)
      setCategory (response.data.category_view_list);
      console.log (response.data.category_view_list);
    });
  };

  const getCategoriesForEdit = item => {
    //alert(id)
    if (!item) {
      setEditMode (true);
    }
    if (id !== 'add') {
      setCategoryId (id);
    } else {
    }
    const category_id = item ? item : id;
    //alert (category_id);
    const x=[];

    setAdd(x);

    console.log(add)

    Request.getMethod (`get/subcategory/${category_id}`).then (response => {
      //console.log (response.data);
      // console.log (response.data);

      
      if (!item) {
        setCategoryName (response.data.name);
      }
      
    
      if (response.data.data.length > 0)
        setAdd (response.data.data);
    });
  };

  const subMit = () => {
    const body = {
      category_id: category_id,
      sub_product: JSON.stringify (add),
    };

    // console.log (body);
    if (!category_id || add.length == 0) {
      ToastObject.ToastError (
        'The category or the Sub Category field can not be empty'
      );
      return;
    }

    setMessage ('Saving....');

    Request.postMethod ('add/subcategory', body)
      .then (response => {
        //console.log(response)
        setMessage ('Save Changes');
        //return
        if (response.data.result) {
          ToastObject.ToastSuccess ('Sub Category added !!!');
        }
        history.push ('/admincategories');
      })
      .catch (error => {
       // console.log (error.response);
        setMessage ('Save Changes');
        ToastObject.ToastError ('An error occured please try again latter');
      });
  };

  React.useEffect (() => {
    if (id !== 'add') {
      getCategoriesForEdit (false);
    } else {
      getCategories ();
    }
  }, []);

  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>

          <div className="userManager-cont">
            <div>
              <div className="display-admin">
                <div className="admin-title">
                  CATEGORIES
                  <Link
                    to="/"
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <span
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Categories View <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#272D3B',
                      fontWeight: 'bold',
                    }}
                  >
                    {edit_mode ? 'Edit' : 'Add'} Sub Category
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#3EB971',
                padding: '10px',
                margin: '0px -20px',
              }}
            >
              <span
                style={{color: '#FFFFFF', fontWeight: 'bold', fontSize: '20px'}}
              >
                Enter Sub Category
              </span>

            </div>
            <Form>
              <div style={{paddingTop: '2rem'}}>
                <p style={{fontWeight: '600'}}>Select Category</p>

                <select
                  name="show"
                  id="show"
                  style={{
                    width: '100%',
                    border: '1px solid #B9B9B9',
                    height: '50px',
                    borderRadius: '3px',
                  }}
                  onChange={e => onCategoryChange (e)}
                >
                  {edit_mode
                    ? <option value="0" style={{width: '100px'}}>
                        {category_name}
                      </option>
                    : <option value="0" style={{width: '100px'}}>
                        Select Category
                      </option>}

                  {category
                    ? category.map (item => (
                        <option
                          value={item.category_id}
                          key={item.id + 'option'}
                        >
                          {item.category_name}
                        </option>
                      ))
                    : null}

                </select>
              </div>

              <div style={{paddingTop: '2rem'}}>
                <p style={{fontWeight: '600'}}>Enter Sub Category</p>
                <div className="displayFlex ">
                  <Form.Group style={{width: '100%', borderRight: 'none'}}>
                    <Form.Control
                      name="category_name"
                      type="text"
                      placeholder=""
                      value={product_type}
                      style={{
                        borderTopRightRadius: '0%',
                        borderBottomRightRadius: '0%',
                        height: '50px',
                      }}
                      className="type-txt"
                      onChange={e => textChange (e)}
                    />
                  </Form.Group>
                  <Button
                    className="add-pt-btn add"
                    id="add-btn"
                    onClick={() => displayType ()}
                  >
                    ADD
                  </Button>

                </div>

                { add.map ((item, index) => (
                  <div
                    className="my-added-types"
                    style={{marginTop: '1rem'}}
                    key={index + 'item-time'}
                  >
                    <span>{item.name}</span>
                    <span>
                      <RemoveCircleOutlineIcon
                        onClick={() => removeProductTpe (index, item.id)}
                      />
                    </span>
                  </div>
                ))}
                <div>
                  <span id="displayProductTypes" />
                </div>
              </div>

              <div style={{paddingTop: '2rem'}}>
                <Button
                  size="sm"
                  className="reset-btn"
                  style={{marginRight: '10px'}}
                >
                  Reset
                </Button>
                <Button
                  onClick={subMit}
                  disabled={message !== 'Save Changes'}
                  size="sm"
                  className="addCatBtn"
                  style={{}}
                >
                  {message}
                </Button>
              </div>
            </Form>

          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{padding:'30px'}}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color: '#FF0000', fontWeight: 'bold'}}>
            <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION!!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color: '#FF0000', fontSize: '11px'}}>
          This product type may be containing products, Are you sure you want to perform this action?
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            style={{backgroundColor: '#D3D3D3', border: 'none'}}
          >
            No!
          </Button>
          <Button
            variant="primary"
            onClick={removeSubInEdit}
            style={{
              marginRight: '20px',
              backgroundColor: '#3EB971',
              border: 'none',
            }}
          >
            Yes!
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProductType;
