import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Table} from 'react-bootstrap';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid';
import '../../assets/css/dashboard.css';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import Request from '../utls/request';
import config from '../utls/constant';
import LoadingOverlay from 'react-loading-overlay';

import {ExportToExcel} from './ExportToExcel';
import {useHistory} from 'react-router-dom';
import {useParams} from 'react-router-dom';

const UserProducts = () => {
  const _export = React.useRef (null);

  const history = useHistory ();

  const {id} = useParams ();

  const [data, setData] = useState (null);
  const [isActive, setIsActive] = useState (true);
  const [show_entries, setShowEntries] = useState (20);

  const [last_select, setSelect] = useState ({
    value: new Date ().getFullYear (),
    name: 'year',
  });
  
  const handleDetails = id => {
    history.push (`/adminproductdetails/${id}`);
  };

  const searchProduct = e => {
    if (e.target.value.length == 0) {
      const object = new Date ();
      getProducts ({value: object.getFullYear (), name: 'year'}, 20);
    }

    if (e.target.value.length >= 3) {
      const body = {
        key: e.target.value,
        user: true,
      };
      Request.postMethod ('search-products', body).then (response => {
        setData (response.data.user);
      });
    }
  };

  const selectSort = e => {
    const object = new Date ();
    const val = e.target.value;

    if (val == 'last_year') {
      setSelect ({value: object.getFullYear () - 1, name: 'year'});

      getProducts (
        {value: object.getFullYear () - 1, name: 'year'},
        show_entries
      );
      //alert(year)
      return;
    }

    if (val == 'this_year') {
      setSelect ({value: object.getFullYear (), name: 'year'});
      getProducts ({value: object.getFullYear (), name: 'year'}, show_entries);
      return;
    }

    if (val == 'this_month') {
      //setMonth()
      setSelect ({
        value: object.getMonth () + 1,
        name: 'month',
      });
      getProducts (
        {
          value: object.getMonth () + 1,
          name: 'month',
        },
        show_entries,
        object.getFullYear ()
      );
      return;
    }
    if (val == 'last_month') {
      setSelect ({value: object.getMonth (), name: 'month'});
      getProducts (
        {value: object.getMonth (), name: 'month'},
        show_entries,
        object.getFullYear () - 1
      );
      return;
    }

    if (val == 'today') {
      //setToday();
      setSelect ({value: object.getDate (), name: 'today'});
      getProducts ({value: object.getDate (), name: 'today'}, show_entries);
      return;
    }
    if (val == 'online') {
      const time = `${object.getHours ()}:${object.getMinutes () < 10 ? '0' + object.getMinutes () : object.getMinutes ()}: ${object.getSeconds () < 10 ? '0' + object.getSeconds () : object.getSeconds ()}`;

      setSelect ({
        value: time,
        name: 'online',
      });
      getProducts (
        {
          value: time,
          name: 'online',
        },
        show_entries
      );
      return;
    }
  };

  const ShowBy = e => {
    const value = e.target.value;
    getProducts (last_select, value);
  };

  const getProducts = () => {
    Request.getMethod (`get-user-product/${id}`)
      .then (response => {
        setData (response.data.product);
        setIsActive (false);
        //setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  React.useEffect (() => {
    const object = new Date ();
    getProducts ({value: object.getFullYear (), name: 'year'}, 20);
  }, []);

  // Export tableto excel
  const fileName = 'FarmsellProducts';

  return (
    <LoadingOverlay active={isActive} spinner text="Loading data...">
      <div className="admin-bg">
        <div className="main">
          <AdminHeader />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="admin-side">
              <Sidebar />
            </div>
            <div className="userManager-cont">
              <div>
                <div className="display-admin">
                  <div className="admin-title">
                    PRODUCTS
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        borderLeft: '1px solid #D3D3D3',
                        paddingLeft: '3px',
                        marginLeft: '3px',
                        color: '#D3D3D3',
                      }}
                    >
                      Home <FontAwesomeIcon icon={faChevronRight} />
                    </span>
                    <span style={{fontSize: '14px', fontWeight: '400'}}>
                      Products View
                    </span>
                  </div>
                  <div class="input-icons display-admin">
                    <i class="fa fa-user icon" />
                    <input
                      class="input-field"
                      type="text"
                      placeholder="Search by user's name, email,..."
                      onChange={e => searchProduct (e)}
                    />
                  </div>
                </div>
                <div className="display-admin" style={{marginBottom: '10px'}}>
                  <div class="display-admin admin-show">
                    <span>Show &nbsp;</span>
                    <select
                      onChange={e => ShowBy (e)}
                      name="show"
                      id="show"
                      className="sort-by"
                    >
                      <option value="20" style={{width: '100px'}}>20</option>
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                      <option value="25">25</option>
                      <option value="30">30</option>
                      <option value="35">35</option>
                      <option value="40">40</option>
                      <option value="45">45</option>

                      <option value="50">50</option>
                      <option value="55">55</option>

                      <option value="60">60</option>
                      <option value="65">65</option>
                      <option value="70">70</option>
                      <option value="75">75</option>
                      <option value="80">80</option>
                      <option value="85">85</option>
                      <option value="90">90</option>
                      <option value="100">100</option>
                      <option value="150">150</option>
                      <option value="200">200</option>

                    </select>
                    <span>&nbsp; Entries</span>
                  </div>
                  <div class="display-admin admin-show">
                    <span>Sort by &nbsp;</span>
                    <select
                      onChange={e => selectSort (e)}
                      name="show"
                      id="show"
                      className="sort-by"
                    >
                      <option value="this_year">This year</option>
                      <option value="last_year">Last year</option>
                      <option value="this_month">This month</option>
                      <option value="last_month">Last month</option>
                      <option value="today" style={{width: '100px'}}>
                        Published Today
                      </option>
                      <option value="online">Online</option>
                    </select> &emsp;
                    <ExportToExcel apiData={data} fileName={fileName} />
                  </div>
                </div>
              </div>
              <div>
                <Table borderless hover responsive style={{fontSize: '12px'}}>
                  <thead style={{background: '#E3E9EF', color: '#B3B8BD'}}>
                    <tr style={{textAlign: 'left'}}>
                      <th>Check Box</th>
                      <th />
                      <th>Products</th>
                      <th>Product Owner</th>
                      <th>Currency</th>
                      <th>Ratings</th>
                      <th>Country</th>
                      <th>Location</th>
                      <th>Quantity</th>
                      <th>Views</th>
                      <th>Uploaded</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  {data
                    ? data.map ((cont, index) => {
                        //console.log (cont);
                        return (
                          <tbody>
                            {/* <Link to='/adminproductdetails'> */}
                            <tr
                              key={index}
                              style={{textAlign: 'left', cursor: 'pointer'}}
                              onClick={() => handleDetails (cont.id)}
                            >
                              <td>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                </div>
                              </td>
                              <td style={{textAlign: 'left'}}>
                                <img
                                  src={`${config.domain_name}/storage/cover/${cont.picture}`}
                                  alt=""
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%',
                                  }}
                                />
                              </td>
                              <td>{cont.product_title}</td>
                              <td>{cont.full_name}</td>
                              <td>{cont.currency}</td>
                              <td>{cont.rating}</td>
                              <td>{cont.country}</td>
                              <td>{cont.location}</td>
                              <td>{cont.available_quantity}</td>
                              <td>{cont.view_count}</td>
                              <td>{cont.uploaded}</td>
                              <td>
                                <select
                                  name="show"
                                  id="show"
                                  style={{
                                    width: '65px',
                                    border: '1px solid #EC2A82',
                                    height: '20px',
                                    borderRadius: '5px',
                                    color: '#EC2A82',
                                  }}
                                >
                                  <option value="0" style={{width: '100px'}}>
                                    Action
                                  </option>
                                  <option
                                    value="delete"
                                    style={{width: '100px'}}
                                  >
                                    Delete
                                  </option>
                                  <option value="suspend">Suspend</option>
                                  <option value="edit">Edit</option>
                                </select>
                              </td>
                            </tr>
                            {/* </Link> */}
                          </tbody>
                        );
                      })
                    : null}
                </Table>

                {data && data.length == 0
                  ? <h5 style={{textAlign: 'center', marginTop: 100}}>
                      No Result
                    </h5>
                  : null}
              </div>
            </div>
          </div>
        </div>

      </div>

    </LoadingOverlay>
  );
};

export default UserProducts;
