import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid';
import {Link} from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import Request from '../utls/request';
import ToastObject from '../shared/toast';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import config from '../utls/constant';

const Password = () => {
  const [formData, setFormData] = useState ({
    old: '',
    password: '',
    confirm_password: '',
  });

  const [error, setError] = useState ({
    confirm: '',
    password: '',
    old: '',
  });
  const [validated, setValidated] = useState (false);

  const [formFileData, setFormFileData] = useState ({
    site_logo: '',

    site_logo_footer: '',
    site_logo_fav: '',
  });

  const [message, setMessage] = useState ('Save');

  const onTextChange = e => {
    if (e.target.name == 'password')
      setError (prev => ({...prev, password: ''}));

    if (e.target.name == 'old') setError (prev => ({...prev, old: ''}));

    if (e.target.name == 'confirm_password')
      setError (prev => ({...prev, confirm: ''}));

    setFormData (prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    // console.log(formData)
  };

  const removeImage = index => {
    if (index == 1) {
      setFormFileData (prevState => ({
        ...prevState,
        site_logo: '',
      }));
    }

    if (index == 2) {
      setFormFileData (prevState => ({
        ...prevState,
        site_logo_footer: '',
      }));
    }

    if (index == 3) {
      setFormFileData (prevState => ({
        ...prevState,
        site_logo_fav: '',
      }));
    }
  };

  const onFileChange = e => {
    setFormFileData (prevState => ({
      ...prevState,
      [e.target.name]: e.target.files[0],
    }));

    //console.log(formFileData)
  };

  const getSettings = () => {
    Request.getMethod ('get-settings')
      .then (response => {
        console.log (response.data);
        if (response.data.setting.length !== 0) {
          //alert("ene")
          setFormData (response.data.setting[0]);
        }
      })
      .catch (error => {});
  };

  const savePassword = () => {
    //alert ('ok');
    const form = document.getElementById ('form_body');

    if (!formData.old || !formData.password || !formData.confirm_password) {
      if (!formData.old)
        setError (prev => ({...prev, old: 'field is required'}));

      if (!formData.password)
        setError (prev => ({...prev, password: 'field is required'}));

      if (!formData.confirm)
        setError (prev => ({...prev, confirm: 'field is required'}));

      return;
    }

    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (regex.exec (formData.password) == null) {
      setError (prev => ({
        ...prev,
        password: 'password should contain at least eight characters, including at least one number and includes both lower and uppercase letters and special characters',
      }));
      return;
    }

    if (formData.password !== formData.confirm_password) {
      setError (prev => ({
        ...prev,
        confirm: 'Password does not match',
      }));
      return;
    }

    const body = formData;

    body.password_old = formData.old;

    setMessage ('Sending..');

    Request.postMethod ('password/update', body)
      .then (response => {
        console.log (response);

        if (response.data.error) {
          setError (prev => ({
            ...prev,
            old: response.data.error,
          }));
          setMessage ('Save');
          return;
        }
        ToastObject.ToastSuccess (response.data.result);

        localStorage.clear ('user');
        localStorage.clear ('Token');

        window.location.reload ();

        // setMessage ('Save');
      })
      .catch (error => {
        ToastObject.ToastError ('Oops an error occured, try again latter');
        setMessage ('Save');
        console.log (error.response);
      });

    //console.log (body);
  };

  React.useEffect (() => {
    getSettings ();
  }, []);

  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>
          <div className="userManager-cont">
            <div>
              <div className="display-admin">
                <div className="admin-title">
                  SITE SETTINGS
                  <Link
                    to="/"
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#272D3B',
                      fontWeight: 'bold',
                    }}
                  >
                    Site Information
                  </span>
                </div>
              </div>
            </div>
            <div>
              <Row>
                <Col md={3}>
                  <div className="site-set-side" style={{}}>
                    <p><Link to="./sitesettings">SITE INFORMATION</Link></p>
                    <p style={{background: '#3EB971', color: '#fff'}}>
                      Password Settings
                    </p>
                    <p><Link to="./adminmainslide">MAIN BANNERS</Link></p>
                    <p>
                      <Link to="./categoriesbanner">CATEGORIES' BANNERS</Link>
                    </p>
                    <p><Link to="./languages">LANGUAGE</Link></p>
                    <p><Link to="./gif">GIF</Link></p>
                  </div>
                </Col>
                <Col />
                <Col md={8} className="autoscroll">
                  <p className="site-set-m-title">Password Reset</p>
                  <Form id="form_body" noValidate validated={validated}>
                    <Row>

                      <Col className="col-10">
                        <Form.Group
                          className="mb-6"
                          controlId="validationCustom01"
                        >
                          <Form.Label>Old Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="old"
                            placeholder="Input old password"
                            value={formData.old}
                            onChange={e => onTextChange (e)}
                            required
                          />
                          {error.old
                            ? <Form.Control.Feedback
                                type="invalid"
                                style={{display: 'block'}}
                              >
                                {error.old}
                              </Form.Control.Feedback>
                            : null}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row className="mt-2">
                      <Col className="col-10">
                        <Form.Group className="mb-6" controlId="formBasicEmail">
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Input password"
                            value={formData.password}
                            onChange={e => onTextChange (e)}
                            required
                          />
                          {error.password
                            ? <Form.Control.Feedback
                                type="invalid"
                                style={{display: 'block'}}
                              >
                                {error.password}
                              </Form.Control.Feedback>
                            : null}
                        </Form.Group>

                      </Col>

                    </Row>

                    <Row className="mt-2">

                      <Col className="col-10 ">
                        <Form.Group
                          className="mb-6"
                          controlId="confirm_password"
                        >
                          <Form.Label>Confirm Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="confirm_password"
                            placeholder="Input Confirm password"
                            value={formData.confirm_password}
                            onChange={e => onTextChange (e)}
                            required
                          />

                          {error.confirm
                            ? <Form.Control.Feedback
                                type="invalid"
                                style={{display: 'block'}}
                              >
                                {error.confirm}
                              </Form.Control.Feedback>
                            : null}

                        </Form.Group>
                      </Col>
                    </Row>
                    <div style={{display: 'flex', justifyContent: 'center'}}>
                      <Button
                        className="site-set-btn mt-4"
                        onClick={savePassword}
                        size="sm"
                        style={{width: 200, marginRight: '5rem'}}
                        disabled={message !== 'Save' ? true : false}
                      >

                        {message}

                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Password;
