import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { faChevronRight } from '@fortawesome/fontawesome-free-solid';
import { Link } from "react-router-dom";
import AdminHeader from './AdminHeader';
import aut from './auth/aut';
import Request from './utls/request';
import Sidebar from './Sidebar';
import LoadingOverlay from 'react-loading-overlay';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const AdminAddAdmin = (props) => {



    const [formData, setFormData] = useState({
        email: '',
        first_name: '',
        last_name: ''

    });
    const [permission, setPermission] = useState([]);




    let { id } = useParams();
    
    
    const [message, setMessage] = useState(`${id == 'add' ? "Send Invite" : 'Save Changes'}`);
    const [loading, setLoader] = useState(false);
    const [checked, setChecked] = useState({
        perm1: false,
        perm2: false,
        perm3: false,

        perm4: false
    });

    const [error, setError] = useState({
        email: '',
        last_name: '',
        first_name: '',
        permission: ''

    });

    const checkBoxHandler = (e) => {
        const status = checked[e.target.name];
        const num = parseInt(e.target.value)
        const index = permission.indexOf(num);

        setError({
            email: '',
            last_name: '',
            first_name: '',
            permission: ''
        })

        // console.log(index)
        if (!status) {
            let item = permission;

            item.push(num);
            setPermission(item);
            setChecked(
                prevState => ({
                    ...prevState,
                    [e.target.name]: true
                })
            )

        }
        else {
            if (index !== -1) {
                let item = permission;
                item.splice(index, 1);
                setPermission(item);
                setChecked(
                    prevState => ({
                        ...prevState,
                        [e.target.name]: false
                    })
                )
            }

        }

        //console.log(permission)


    }

    const checkAll = () => {
        setError({
            email: '',
            last_name: '',
            first_name: '',
            permission: ''
        })

        const permission = [1, 2, 3, 4];

        setChecked({
            perm1: true,
            perm2: true,
            perm3: true,

            perm4: true
        })
        setPermission(permission)
        console.log(permission)
    }

    const onTextChange = (e) => {

        setFormData(
            prevState => ({
                ...prevState,
                [e.target.name]: e.target.value
            })
        )

        setError({
            email: '',
            last_name: '',
            first_name: '',
            permission: ''
        })

        // console.log(formData)
    }

    const addAdmin = (event) => {

        event.preventDefault();
        if (!formData.email && !formData.last_name && !formData.first_name && permission.length == 0) {
            setError({
                email: 'Email field can not be empty',
                last_name: 'Last Name  field can not empty',
                first_name: 'First Name  field can not empty',
                permission: 'Please make sure to atlest check on of the permission field'

            })
            return
        }
        if (!formData.email) {
            setError({
                email: 'Email field can not be empty'

            })
            return
        }

        if (!formData.last_name) {
            setError({

                last_name: 'Last Name field can not empty'

            })
            return
        }
        if (!formData.first_name) {
            setError({

                first_name: 'First Name field can not empty'

            })
            return
        }

        setLoader(true)
        setMessage("Sending...")


        let body = formData;
        body.permission = JSON.stringify(permission);
        body.user_type = 'admin';
        body.full_name = body.first_name + ' ' + body.last_name;
        var details = '';
        let super_admin = permission.length == 4 ? true : false;
        let editor = permission.includes(2) ? true : false;
        let analyst = permission.includes(3) ? true : false;
        let advertiser = permission.includes(4) ? true : false;

        if (advertiser) {
            details = 'an Advertiser '
        }
        if (editor) {
            details = 'an editor '
        }
        if (analyst) {
            details = 'an Analyst '
        }

        if (editor && analyst && advertiser) {
            details = 'an editor, Analyst and Advertiser '
        }
        if (editor && analyst) {
            details = 'an editor and Analyst '
        }

        if (advertiser && analyst) {
            details = 'an Advertiser and Analyst '
        }
        if (advertiser && editor) {
            details = 'an Advertiser and Editor '
        }

        if (super_admin) {
            details = 'a super Administrator ';
        }



        body.details = details;


        //  console.log(body)

        Request.postMethod('add-admin', body)
            .then(response => {
                console.log(response)
                if (response.data.error || response.data.message || response.data.user_type_error) {
                    setError({
                        email: response.data.error.email[0]
                    })
                    setLoader(false)
                    setMessage("Send Invite");
                    return
                }

                // localStorage.setItem('user', JSON.stringify(response.data.user));
                //localStorage.setItem('Token', response.data.access_token);
                //aut.login();

                props.history.push('/adminview');


            })
            .catch(error => {
                setLoader(false)
                console.log(error.response)
                //console.log(error.response)
            })
    }





    React.useEffect(() => {




        if (id !== 'add')
            Request.getMethod(`get-user/${id}`)
                .then(response => {
                    const permis = response.data.data.permission ? JSON.parse(response.data.data.permission) : [];

                   // console.log(response, '...')
                    var empty_perm = [];
                    if (permis.indexOf(1) !== -1)
                        empty_perm.push(1)

                    if (permis.indexOf(2) !== -1)
                        empty_perm.push(2)

                    if (permis.indexOf(3) !== -1)
                        empty_perm.push(3)

                    if (permis.indexOf(4) !== -1)
                        empty_perm.push(4)

                    setPermission(empty_perm)

                    setChecked({
                        perm1: permis.indexOf(1) !== -1 ? true : false,
                        perm2: permis.indexOf(2) !== -1 ? true : false,
                        perm3: permis.indexOf(3) !== -1 ? true : false,

                        perm4: permis.indexOf(4) !== -1 ? true : false
                    })

                    const name = response.data.data.full_name.split(" ");

                    setFormData({
                        email: response.data.data.email,
                        first_name: name[0],
                        last_name: name[1],
                        id: response.data.data.id
                    })


                    //alert(JSON.stringify(data))
                    // console.log(response.data)

                })
                .catch(error => {
                    // console.log(error.response)
                })
    },
        [])














    return (
        <div className='admin-bg'>
            <div className='main' >
                <AdminHeader />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='admin-side'>
                        <Sidebar />
                    </div>
                        <div className='userManager-cont'>
                            <div>
                                <div className='display-admin'>
                                    <div className='admin-title'>
                                        ADMINS' MANAGER
                                        <Link to="/" className='admib-nav' style={{ fontSize: '14px', fontWeight: '400', textDecoration:'none', borderLeft:'1px solid #D3D3D3', paddingLeft:'3px', marginLeft:'3px', color:'#D3D3D3' }}>Home <FontAwesomeIcon icon={faChevronRight} /></Link>
                                        <Link to="/adminview" style={{ fontSize: '14px', fontWeight: '400', textDecoration:'none', color:'#D3D3D3' }}>Admin View <FontAwesomeIcon icon={faChevronRight} /></Link>
                                        <span style={{ fontSize: '14px', fontWeight: '400', color:'#272D3B', fontWeight:'bold' }}>Add Admin</span>
                                    </div>
                                </div>
                            </div>


                        <div >
                            <Form>
                                <Form.Label style={{ fontWeight: 'bold' }}>Admin</Form.Label><br />
                                <Form.Text style={{ color: '#272D3B' }}>
                                    Give Admin access to your website by sending them an invitation. If you're working with a designer, developer, or marketer, Analyst, etc. Assign them specific pages they can access.
                                </Form.Text>

                                <Row>
                                    <Col md={6}>
                                        <Form.Label style={{ fontWeight: 'bold' }}>First Name</Form.Label>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control
                                                name="first_name"
                                                value={formData.first_name}
                                                onChange={(e) => onTextChange(e)}
                                                type="text" style={{ border: '1px solid #707070', height: '37px', borderRadius: '2px' }} />
                                        </Form.Group>
                                        <div class="custom_validation">
                                            {error.first_name}
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Label style={{ fontWeight: 'bold' }}>Last Name</Form.Label>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control name="last_name"
                                            value={formData.last_name}
                                                onChange={(e) => onTextChange(e)}
                                                type="text" style={{ border: '1px solid #707070', height: '37px', borderRadius: '2px' }} />
                                        </Form.Group>

                                        <div class="custom_validation">
                                            {error.last_name}
                                        </div>
                                    </Col>
                                </Row>
                                <Form.Text id="passwordHelpBlock" muted>
                                    Enter the Admin's first name and last name as they appear on their government-issued ID.
                                </Form.Text>


                                <Row>
                                    <Col md={6}>
                                        <Form.Label style={{ fontWeight: 'bold' }}>Email</Form.Label>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={(e) => onTextChange(e)}
                                                style={{ border: '1px solid #707070', height: '37px', borderRadius: '2px' }} />
                                        </Form.Group>

                                        <div class="custom_validation">
                                            {error.email}
                                        </div>
                                    </Col>
                                    {/*
                                    <Col md={6}>
                                        <Form.Label style={{ fontWeight: 'bold' }}>Email</Form.Label>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="text" style={{ border: '1px solid #707070', height: '37px', borderRadius: '2px' }} />
                                        </Form.Group>
                                    </Col>
                                    */
                                    }
                                </Row>

                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Form.Text>
                                        Enter the Admin's first name and last name as they appear on their government-issued ID.
                                    </Form.Text>
                                    <span onClick={checkAll} style={{ cursor: 'pointer' }} className='select-all'>Select all</span>
                                </div>
                                <br />
                                <div style={{ marginLeft: '10px' }}>
                                    <Row>
                                        <Form.Check
                                            type="checkbox"
                                            label="Main Admin"
                                            value={1}
                                            name='perm1'
                                            checked={checked.perm1}
                                            onChange={(e) => checkBoxHandler(e)}
                                            style={{ fontWeight: '700' }}
                                        />
                                        <Form.Text style={{ color: '#0E0E0E' }}>
                                            &emsp;Enter the Admin's first name and last name as they appear on their government-issued ID.
                                        </Form.Text>
                                    </Row>

                                    <Row>
                                        <Form.Check
                                            type="checkbox"
                                            label="Analyst"
                                            name='perm3'
                                            value={3}
                                            checked={checked.perm3}
                                            style={{ fontWeight: '700' }}
                                            onChange={(e) => checkBoxHandler(e)}
                                        />
                                        <Form.Text style={{ color: '#0E0E0E' }}>
                                            &emsp;An analyst has the right to view all the features on the site butcan't perfomany action
                                        </Form.Text>
                                    </Row>
                                    <Row>
                                        <Form.Check
                                            type="checkbox"
                                            label="Editor"
                                            name='perm2'
                                            value={2}
                                            checked={checked.perm2}
                                            style={{ fontWeight: '700' }}
                                            onChange={(e) => checkBoxHandler(e)}
                                        />
                                        <Form.Text style={{ color: '#0E0E0E' }}>
                                            &emsp;An editor has the right to perform any action on the site, editing, adding, deleting, etc.
                                        </Form.Text>
                                    </Row>
                                    <Row>
                                        <Form.Check
                                            type="checkbox"
                                            label="Advitiser"
                                            value={4}
                                            checked={checked.perm4}
                                            name='perm4'
                                            onChange={(e) => checkBoxHandler(e)}
                                            style={{ fontWeight: '700' }}
                                        />
                                        <Form.Text style={{ color: '#0E0E0E' }}>
                                            &emsp;An advitiser has the right foronly adverts' managementbut can't performany action.
                                        </Form.Text>

                                        <div class="custom_validation">
                                            {error.permission}
                                        </div>
                                    </Row>
                                </div>
                                <Row style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
                                    {
                                        !loading ?
                                            <Button style={{ border: 'none', background: '#E5E5E5', color: '#000000', width: '8rem' }}>Cancel</Button>
                                            : null
                                    }

                                    <Button onClick={addAdmin} style={{ border: '1px solid #000000', background: '#FFFFFF', color: '#000000', width: '8rem', marginLeft: '10px' }}
                                        disabled={loading}
                                    >{message}</Button>
                                </Row>
                            </Form>
                        </div>


                    </div>
                </div>
            </div>

        </div>
    )
}

export default AdminAddAdmin;