import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faClone, faCog, faCubes,faCheckCircle, faInfoCircle, faShieldAlt, faSignOutAlt, faSlidersH, faUser, faUserNinja } from '@fortawesome/fontawesome-free-solid';
import team2 from '../assets/images/team2.png';
import { Link } from 'react-router-dom';
import '../assets/css/dashboard.css';
import AdminHeader from './AdminHeader';
import adminImage from '../assets/images/abdul.png';
import Sidebar from './Sidebar';

const AdminDetails = () => {

    return (
        <div className='admin-bg'>
            <div className='main' >
                <AdminHeader />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='admin-side'>
                        <Sidebar />
                    </div>



                   <div className='userManager-cont' >
                            <div>
                                <div className='display-admin'>
                                    <div className='admin-title'>
                                        ADMINS' MANAGER
                                        <span style={{ fontSize: '14px', fontWeight: '400', borderLeft:'1px solid #D3D3D3', paddingLeft:'3px', marginLeft:'3px', color:'#D3D3D3' }}>Home <FontAwesomeIcon icon={faChevronRight} /></span>
                                        <span style={{ fontSize: '14px', fontWeight: '400', borderLeft:'1px solid #D3D3D3', paddingLeft:'3px', marginLeft:'3px', color:'#D3D3D3' }}>Admin View<FontAwesomeIcon icon={faChevronRight} /></span>
                                        <span style={{ fontSize: '14px', fontWeight: '400' }}>Admin Details</span>
                                    </div>
                                    <div>
                                        <button style={{border:'0',borderRadius:'5px'}} >SAVE CHANGES</button>
                                    </div>
                                </div>
                                
                            </div>
                            <div className='content-container' >
                       <div className='profile-details'>
                            <div className='personal-details'>
                                <img src={adminImage}  alt="Admin image"/>
                               <ul className=' ml-4 details'>
                                   <li><h5>MIKE MARY</h5></li>
                                   <li className=''><span className='email'>mikemary@gmail.com</span></li>
                                   <l1 className='pt-2'><span className='font-weight-bold'>Country:</span> Uganda </l1>
                                   <li className='pt-2'><span className='font-weight-bold'>Online Status:</span> 5mins</li>
                               </ul>
                            </div>
                            <div className='bio-details'>
                               <div className='details-head'> <h5>details</h5></div>
                                <div>
                                    <span>Bio(Optional)</span>
                                    <p>
                                        
                                    </p>
                                </div>
                            </div>
                            <div className='notification-details'>
                                <div className='details-head'><h5>Notifications</h5></div>
                                    <span><FontAwesomeIcon icon={faCheckCircle} style={{color:'#3291bd',size:'15px', padding:'2px 10px'}} />Allow important notifications to be sent by E-mail</span>
                                    <p>Let the Admin know the important changes made by the main Admin on his account by sending E-mail on his personal or company email</p>
                                </div>
                            <div className='reset-password-details'>
                                <div className='details-head'> <h5>Reset password</h5></div>
                                    <p> You can help MIKE reset their password by emailing instructions to <span className='email'>mikemary@gmail.com</span></p>
                                    <span><button className='btn' style={{color:"black",border:"1px solid black"}}>Send Instructions</button></span>
                                </div>
                            <div className='permission-details'>
                                <div className='details-head'> <h5>Permission</h5></div>
                                    <ul>
                                        <li >
                                            <div className='row d-flex justify-content-starts'>
                                                <input className='col-md-1' type="checkbox" id="" name="" value="" />
                                                <div className='col-md-11'>
                                                    <span>Main Admin</span>
                                                    <p>Main Admin is the owner of the websiteand has all the access to the site.</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                        <div className='row d-flex justify-content-starts'>
                                        <input className='col-md-1' type="checkbox" id="" name="" value="" />
                                            <div className='col-md-11'>
                                                <span>Analyst</span>
                                                <p>An Analyst has the right to view all the features on the site but can not perform any action</p>
                                        
                                            </div>
                                        </div>
                                            </li>
                                        <li>
                                            <div className='row d-flex justify-content-starts'>
                                                <input className='col-md-1' type="checkbox" id="" name="" value="" />
                                                <div className='col-md-11'>
                                                    <span>Editor</span>
                                                    <p>An Editor has the right to perform any action on the site,editing,adding,deletiing,etc</p>
                                                
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className='row d-flex justify-content-starts'>
                                                <input className='col-md-1' type="checkbox" id="" name="" value="" />
                                                <div className='col-md-11'>
                                                    <span>Advertiser</span>
                                                    <p>An Advertiser has the right for all Adverts' managment but can not perform any action </p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            <div className='account-access-details'>
                            <div className='details-head'><h5>Manage account access</h5></div>
                                <div className='pt-2'>
                                    <h6>Suspend Access</h6>
                                    <p>This account will no longer have access to your site.You can restore access at any time</p>
                                    <button className='btn' style={{color:"black",border:"1px solid black"}}> Suspend Access</button>
                                </div>
                                <div className='pt-4'>
                                    <h6>Remove Mike Mary</h6>
                                    <p>Removed Admin member will be permanently removed from the site. This acn not be reversed</p>
                                    <span><button className='btn btn-danger'>Remove Mike</button></span>
                                </div>
                                
                            </div>
                       </div>
                                                
                          </div> 
                        </div>
                </div>
            </div>
            
        </div>
    )
}
export default AdminDetails;