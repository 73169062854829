import React from 'react';
import ReactApexChart from 'react-apexcharts'

const ApexPieChart = () => {

        
        const series = [44, 55, 41, 17, 15, 44, 55, 41, 17, 15, 18, 15, 40]
        
        const options = {
            chart: {
                width: 280,
                type: 'donut',
            },
            theme: {
                mode: 'light', 
                monochrome: {
                    enabled: true,
                    color: '#00CCF2',
                    shadeTo: '#F5FBFF',
                    shadeIntensity: 0
                },
            },
            plotOptions: {
                pie: {
                    // startAngle: -90,
                    // endAngle: 270
                //    size: 600
                }
            },
            dataLabels: {
                enabled: false
            },
            labels: ['Jan', 'Feb', 'Mar', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            fill: {
                type: 'gradient',
            },
            legend: {
                position: 'right'
            },
            title: {
            text: 'Users per month'
            },
            responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                width: 200
                },
                legend: {
                    position: 'bottom',
                }
            }
            }]
        }
 
        return (
            <div >
                <ReactApexChart options={options} series={series} type="donut" style={{width:'250px'}} />
            </div>
        );
}

export default ApexPieChart;
    