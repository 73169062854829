import Request from '../utls/request';
import ToastObject from './toast';

const LogoutOut = () => {
  Request.getMethod ('logout')
    .then (response => {
      if (response.data.result) {
        ToastObject.ToastSuccess ('Logout succesfull');
        localStorage.clear ('user');
        localStorage.clear ('Token');

        window.location.reload ();
      }
    })
    .catch (error => {
      ToastObject.ToastError ('An error occured during logout');
      localStorage.clear ('user');
      localStorage.clear ('Token');

      window.location.reload ();
    });
};

export default LogoutOut;
