import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { faChevronRight } from '@fortawesome/fontawesome-free-solid';
import { Link } from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';

const Languages = () => {
    return (
        <div className='admin-bg'>
            <div className='main' >
                <AdminHeader />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='admin-side'>
                        <Sidebar />
                    </div>
                    <div className='userManager-cont' >
                        <div>
                            <div className='display-admin'>
                                <div className='admin-title'>
                                    SITE SETTINGS
                                    <Link to="/" className='admib-nav' style={{ fontSize: '14px', fontWeight: '400', textDecoration:'none', borderLeft:'1px solid #D3D3D3', paddingLeft:'3px', marginLeft:'3px', color:'#D3D3D3' }}>Home <FontAwesomeIcon icon={faChevronRight} /></Link>
                                    <span style={{ fontSize: '14px', fontWeight: '400', color:'#272D3B', fontWeight:'bold' }}>Categories Slides</span>
                                </div>
                            </div>
                        </div>
                        <div className='site-info' >
                            <Row>
                                <Col md={3}>
                                    <div className='site-set-side' style={{}}>
                                        <p><Link to="./sitesettings">SITE INFORMATION</Link></p>
                                        <p><Link to="./password-management">Password Settings</Link></p>
                                        <p><Link to="./adminmainslide">MAIN SLIDES</Link></p>
                                        <p><Link to="./categoriesbanner">CATEGORIES' BANNERS</Link></p>
                                        <p style={{background:'#3EB971', color:'#fff'}}>LANGUAGE</p>
                                        <p><Link to="./gif">GIF</Link></p>
                                    </div>
                                </Col>
                                <Col></Col>
                                <Col md={8} style={{  }}>
                                    <div className='displayFlex' style={{marginBottom:'2rem', marginRight:'0.3rem'}}>
                                        <span className='site-set-m-title'>LANGUGES</span>
                                        <Button className='mainslide-btn' style={{background:'#3EB971'}}>Save Changes</Button>
                                    </div>
                                    
                                    <div className='autoscroll' style={{ overflowY: 'auto', fontSize: '13px' }}>
                                        <div style={{marginBottom:'20px'}}>
                                            <p style={{fontWeight:'600'}}>Default Site Language</p>
                                            <p>This is the Language that your online site visitors see if their preferred language isn't available.</p>
                                            <div className='displayFlex language' >
                                                <div>
                                                    <span style={{fontWeight:'600'}}>English</span> <br/>
                                                    <span>Online Site Language</span>
                                                </div>
                                                <select name="show" id="show" style={{width:'8.4rem', border:'1px solid #707070', height:'25px', borderRadius:'4px'}}>
                                                    <option value="0" style={{width:'100px'}}>Change Language</option>
                                                    <option value="english">English</option>
                                                    <option value="chinese">Chinese</option>
                                                    <option value="swahili">Swahili</option>
                                                    <option value="Luganga">Luganda</option>
                                                    <option value="arabic">Arabic</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div style={{marginBottom:'20px'}}>
                                            <p style={{fontWeight:'600'}}>Add Site Language options</p>
                                            <p>Add Languages' option so that online visitors choose their preferred Language.</p>
                                            <div className='displayFlex language' >
                                                <select name="show" id="show" style={{width:'8.4rem', border:'1px solid #707070', height:'25px', borderRadius:'4px'}}>
                                                    <option value="0" style={{width:'100px'}}>Change Language</option>
                                                    <option value="english">English</option>
                                                    <option value="chinese">Chinese</option>
                                                    <option value="swahili">Swahili</option>
                                                    <option value="Luganga">Luganda</option>
                                                    <option value="arabic">Arabic</option>
                                                </select>
                                                <Button className='mainslide-btn' style={{background:'#3EB971'}}>Hide Options</Button>
                                            </div>
                                        </div>
                                        <div style={{marginBottom:'20px'}}>
                                            <p style={{fontWeight:'600'}}>Account Language</p>
                                            <p>When you are logged into your Admin Account, this is the Language you see. This doesn't affect the Language online Visitors see.</p>
                                            <div className='displayFlex language' >
                                                <div>
                                                    <span style={{fontWeight:'600'}}>English</span> <br/>
                                                    <span>Online Site Language</span>
                                                </div>
                                                <select name="show" id="show" style={{width:'8.4rem', border:'1px solid #707070', height:'25px', borderRadius:'4px'}}>
                                                    <option value="0" style={{width:'100px'}}>Change Language</option>
                                                    <option value="english">English</option>
                                                    <option value="chinese">Chinese</option>
                                                    <option value="swahili">Swahili</option>
                                                    <option value="Luganga">Luganda</option>
                                                    <option value="arabic">Arabic</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default Languages;