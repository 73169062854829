import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { faChevronRight } from '@fortawesome/fontawesome-free-solid';
import '../assets/css/dashboard.css';
import AdminHeader from './AdminHeader';
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

function AddCategory() {
  return (
      <div className='admin-bg'>
          <div className='main' >
                <AdminHeader />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='admin-side'>
                        <Sidebar />
                    </div>


                    <div className='userManager-cont'>
                        <div>
                            <div className='display-admin'>
                                <div className='admin-title'>
                                    CATEGORIES
                                    <Link to="/" className='admib-nav' style={{ fontSize: '14px', fontWeight: '400', textDecoration:'none', borderLeft:'1px solid #D3D3D3', paddingLeft:'3px', marginLeft:'3px', color:'#D3D3D3' }}>Home <FontAwesomeIcon icon={faChevronRight} /></Link>
                                    <Link to="/admincategories" className='admib-nav' style={{ fontSize: '14px', fontWeight: '400', textDecoration:'none', borderLeft:'1px solid #D3D3D3', paddingLeft:'3px', marginLeft:'3px', color:'#D3D3D3' }}>Categories View <FontAwesomeIcon icon={faChevronRight} /></Link>
                                    <span style={{ fontSize: '14px', fontWeight: '400', color:'#272D3B', fontWeight:'bold' }}>Add Category</span>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', background: '#3EB971', padding: '10px', margin: '0px -20px' }}>
                            <span style={{ color: '#FFFFFF', fontWeight: 'bold', fontSize: '20px' }}>Add Category</span>
                            <div style={{ textAlign: 'right' }}>
                                <Button size="lg" style={{ background: '#FFFFFF', color: '#272D3B', border: 'none', fontWeight: 'bold' }}>Add Category</Button>
                            </div>
                        </div>
                        <Form>
                            <div style={{ paddingTop: '2rem' }}>
                                <p style={{ fontWeight: '600' }}>Choose Category type</p>

                                <Form.Group className="mb-3" controlId="file" style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                                    <Form.Control
                                    name='category_name'
                                    type="text" placeholder="" />
                                </Form.Group>
                            </div>


                            <div style={{ paddingTop: '2rem' }}>
                                <p style={{ fontWeight: '600' }}>Upload Image</p>
                                <div className='displayFlex gifHide' >
                                    <Form.Group className="mb-3" controlId="file" style={{ marginLeft: '1rem', marginTop: '1rem' }}>
                                        <Form.Control type="file" placeholder="Browse..."
                                        />
                                    </Form.Group>
                                    <Button style={{ background: '#3EB971', height: '70px', width: '200px', border: 'none' }}>Upload Image</Button>
                                </div>
                            </div>
                            <div style={{ paddingTop: '2rem' }}>
                                <Button className='cat-edit-btn'>Reset</Button>
                                <Button className='cat-edit-btn'>Save Changes</Button>
                            </div>
                        </Form>

                    </div>
                </div>
            </div>
      </div>
  )
}

export default AddCategory