import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Row, Col, Button, Card} from 'react-bootstrap';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid';
import {Link} from 'react-router-dom';
import '../assets/css/dashboard.css';
import AdminHeader from './AdminHeader';
import Request from './utls/request';
import config from './utls/constant';
import Sidebar from './Sidebar';
import LoadingOverlay from 'react-loading-overlay';
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';

const AdminCategories = () => {
  const [data, setData] = useState (null);
  const [isActive, setIsActive] = useState (true);

  const history = useHistory ();

  

  React.useEffect (() => {
    Request.getMethod ('get/category/list')
      .then (response => {
        setData (response.data.category_view_list);

        setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  }, []);

  const handleAddCategory = () => {
    history.push ('/admineditcategory/add');
    // window.location.href="/admineditcategory/add"
  };

  const handleAddProductType = () => {
    history.push ('/producttype/add');
    // window.location.href="/producttype/add"
  };

  return (
    <LoadingOverlay active={isActive} spinner text="Loading data...">
      <div className="admin-bg">
        <div className="main">
          <AdminHeader />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="admin-side">
              <Sidebar />
            </div>
            <div className="userManager-cont">
              <div>
                <div className="display-admin">
                  <div className="admin-title">
                    CATEGORIES
                    <Link
                      to="/"
                      className="admib-nav"
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        textDecoration: 'none',
                        borderLeft: '1px solid #D3D3D3',
                        paddingLeft: '3px',
                        marginLeft: '3px',
                        color: '#D3D3D3',
                      }}
                    >
                      Home <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#272D3B',
                        fontWeight: 'bold',
                      }}
                    >
                      Categories View
                    </span>
                  </div>
                  <div className="input-icons display-admin">
                    <i className="fa fa-user icon" />
                    <input
                      className="input-field"
                      type="text"
                      placeholder="Search by user's name, email,..."
                    />
                  </div>
                </div>
                <div className="display-admin" style={{marginBottom: '10px'}}>
                  <div className="display-admin admin-show">
                    <span>Show &nbsp;</span>
                    <select
                      name="show"
                      id="show"
                      style={{
                        width: '100px',
                        border: '1px solid #707070',
                        height: '35px',
                        borderRadius: '11px',
                      }}
                    >
                      <option value="5" style={{width: '100px'}}>5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                    <span>&nbsp; Entries</span>
                  </div>
                  <div class="display-admin admin-show">
                    <Button
                      onClick={handleAddCategory}
                      size="sm"
                      className="addCatBtn"
                      style={{marginRight: '10px'}}
                    >
                      Add Category
                    </Button>
                    <Button
                      onClick={handleAddProductType}
                      size="sm"
                      className="addCatBtn"
                      style={{}}
                    >
                      Add Sub Category
                    </Button>
                  </div>
                </div>
              </div>
              <div className='autoscroll'>
                <Row style={{overflow: 'auto'}}>
                  {data
                    ? data.map ((category, index) => {
                        return (
                          <Col md={3} key={index}>
                            <Card className="category-card">
                              <Card.Img
                                variant="top"
                                src={`${config.domain_name}/storage/banner/${category.picture_url}`}
                                style={{height: '6rem'}}
                              />
                              <Card.Body style={{textAlign: 'center'}}>
                                <Card.Title className="category-card-title">
                                  {category.category_name}
                                </Card.Title>
                                <Link
                                  to={`/admincategoriesdetails/${category.category_id}`}
                                >
                                  <Button className="category-card-btn">
                                    See More
                                  </Button>
                                </Link>
                              </Card.Body>
                            </Card>
                          </Col>
                        );
                      })
                    : null}

                </Row>
              </div>
            </div>
          </div>
        </div>

      </div>

    </LoadingOverlay>
  );
};

export default AdminCategories;
