

class Auth {


    constructor() {
        const Token = localStorage.getItem('Token');

       

        this.isAuthenticated = Token ? true : false;
    }


    login() {
        this.isAuthenticated = true;
    }

    authData() {
        const Token = localStorage.getItem('Token');

        let user = JSON.parse(localStorage.getItem('user'));

        if (Token && user) {
            user.Token = Token;

            return user;
        }

        return null;

    }



    logOut() {
        this.isAuthenticated = false;
    }

    isAuthenticated() {



        return this.isAuthenticated;
    }
}

export default new Auth();