import React from 'react';
import ReactApexChart from 'react-apexcharts'

const ProductPiePerMonth = () => {

        
        const series = [44, 55, 41, 17, 15, 44, 55, 41, 17, 15, 18, 15, 40]
        const options = {
            chart: {
                width: 280,
                type: 'donut',
            },
            plotOptions: {
                pie: {
                    // startAngle: -90,
                    // endAngle: 270
                }
            },
            theme: {
                mode: 'light', 
                // palette: 'palette1', 
                monochrome: {
                    enabled: true,
                    color: '#EC2A82',
                    shadeTo: 'dark',
                    shadeIntensity: 0
                },
            },
            dataLabels: {
                enabled: false
            },
            labels: ['Jan', 'Feb', 'Mar', 'April', 'May', 'Jun', 'Jul', 'Aug', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
            fill: {
                type: 'gradient',
            },
            legend: {
                // formatter: function(val, opts) {
                //     return val + " - " + opts.w.globals.series[opts.seriesIndex]
                // }
            },
            title: {
                text: 'Product per month'
            },
            responsive: [{
                breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'left'
                }
            }
            }]
        }
   
        return (
            <div>
                <ReactApexChart options={options} series={series} type="donut" style={{width:'250px'}} />
            </div>
        );
}

export default ProductPiePerMonth;
    