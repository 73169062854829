import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useRef } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import { faChevronRight } from '@fortawesome/fontawesome-free-solid';
import AdminHeader from './AdminHeader';
import 'arch-editor/dist/arch-editor.css';

import JoditEditor from "jodit-react";
import Sidebar from './Sidebar';
import { Link } from 'react-router-dom';

const AdminAddTestimonial = () => {
    const editor = useRef(null);
    const [content, setContent] = useState("");
    const config = {
        readonly: false,
        height: 300,
        width: 800
    };
    const handleUpdate = (event) => {
        const editorContent = event.target.innerHTML;
        setContent(editorContent);
    };
    return (
         <div className='admin-bg'>
            <div className='main' >
                <AdminHeader />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className='admin-side'>
                        <Sidebar />
                    </div>
                    <div className='userManager-cont' >
                        <div>
                            <div className='display-admin'>
                                <div className='admin-title'>
                                    PAGES
                                    <Link to="/" className='admib-nav' style={{ fontSize: '14px', fontWeight: '400', textDecoration:'none', borderLeft:'1px solid #D3D3D3', paddingLeft:'3px', marginLeft:'3px', color:'#D3D3D3' }}>Home <FontAwesomeIcon icon={faChevronRight} /></Link>
                                    <Link to="/adminpages" className='admib-nav' style={{ fontSize: '14px', fontWeight: '400', textDecoration:'none', borderLeft:'1px solid #D3D3D3', paddingLeft:'3px', marginLeft:'3px', color:'#D3D3D3' }}>Page View <FontAwesomeIcon icon={faChevronRight} /></Link>
                                    <span style={{ fontSize: '14px', fontWeight: '400', color:'#272D3B', fontWeight:'bold' }}>Add Page</span>
                                </div>
                                <div class="display-admin">
                                    <Button className='page-btn' size="sm" style={{background:'#3EB971'}}>Save</Button>
                                    <Button className='page-btn' size="sm" style={{ background: '#3EB971' }}>Discard</Button>
                                </div>
                            </div>
                        </div>
                        
                        <div className='overflow'  style={{ height: '57vh' }} >
                            <Form>
                                <div className='displayFlex gifHide' style={{marginBottom:'1rem'}} >
                                    <Form.Group className="mb-3" controlId="file" style={{marginLeft:'1rem', marginTop:'1rem'}}>
                                        <Form.Control type="file" placeholder="Browse..." />
                                    </Form.Group>
                                    <Button style={{ background: '#3EB971', height: '62px', width: '200px', border: 'none', borderRadius:'1px' }}>UPLOAD IMAGE</Button>
                                </div>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Control type="text" style={{ border:'1px solid #707070', height:'37px', borderRadius:'2px' }} placeholder="Name" />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <select name="show" id="show" style={{width:'90%', border:'1px solid #707070', height:'37px', borderRadius:'2px'}}>
                                            <option value="aboutUs" style={{width:'100px'}}>Select Location</option>
                                            <option value="aboutUs">About Us</option>
                                            <option value="socialMedia">Social Media</option>
                                            <option value="supportCenter">Support Center</option>
                                            <option value="usefulLinks">Useful Links</option>
                                            <option value="Add Header">Add Header</option>
                                        </select>
                                    </Col>
                                </Row>
                                
                                
                                <Row>
                                    <p style={{fontWeight:'bold', fontSize:'14px'}}>Write Testimonial here</p>
                                    <JoditEditor
                                        ref={editor}
                                        value={content}
                                        config={config}
                                        onBlur={handleUpdate}
                                        onChange={(newContent) => {}}
                                    />
                                </Row>
                            </Form>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
    )
}

export default AdminAddTestimonial;