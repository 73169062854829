import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Row, Col} from 'react-bootstrap';
import {
  faChevronRight,
  faTimes,
  faPlusCircle,
} from '@fortawesome/fontawesome-free-solid';
import fruitSlide from '../../assets/images/fruitSlide.png';
import {Link} from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import {faClose} from '@fortawesome/free-solid-svg-icons';

const AddMainSlide = () => {
  const [imageAttached, setImageAttached] = useState ([]);

  const attachImage = e => {
    const image = e.target.files[0];

    setImageAttached ([...imageAttached, image]);
  };

  const removeImage = index => {
    var spliced_array = [...imageAttached];
    spliced_array.splice (index, 1);
    setImageAttached (spliced_array);
  };

  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>
          {/* <Col></Col> */}
          <div className="userManager-cont">
            <div>
              <div className="display-admin">
                <div className="admin-title">
                  SITE SETTINGS
                  <Link
                    to="/"
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#272D3B',
                      fontWeight: 'bold',
                    }}
                  >
                    Add Main Slides
                  </span>
                </div>
              </div>
            </div>
            <div style={{height: '70vh'}}>
              <Row>
                <Col md={3}>
                  <div className="site-set-side" style={{}}>
                    <p><Link to="./sitesettings">SITE INFORMATION</Link></p>
                    <p><Link to="./password-management">Password Settings</Link></p>
                    <p style={{background: '#3EB971', color: '#fff'}}>
                      MAIN SLIDES
                    </p>
                    <p>
                      <Link to="./categoriesbanner">CATEGORIES' BANNERS</Link>
                    </p>
                    <p><Link to="./languages">LANGUAGE</Link></p>
                    <p><Link to="./gif">GIF</Link></p>
                  </div>
                </Col>
                <Col />

                <Col md={8}>
                  <div className="add-slide-top-btn-div">
                    <button className="add-slide-top-btn slide-discard-btn">
                      Discard
                    </button>
                    <button className="add-slide-top-btn slide-save-btn">
                      Save
                    </button>
                  </div>

                  <div
                    className="overflow"
                    style={{height: '60vh', overflowX: 'hidden'}}
                  >

                    <Row style={{height: 160}}>

                      {imageAttached.map ((item, index) => (
                        <Col md={3}>
                          <div className="top-banner artist-collection-photo">
                            <FontAwesomeIcon
                              icon={faClose}
                              className="close"
                              onClick={() => removeImage (index)}
                            />
                            <img
                              src={URL.createObjectURL (item)}
                              alt=""
                              className="img-thumbnail"
                            />
                          </div>
                        </Col>
                      ))}

                      <Col md={2}>
                        <label
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 50,
                            marginLeft: 10,
                            flexDirection: 'column',
                          }}
                          for="file_product"
                        >

                          <FontAwesomeIcon
                            style={{fontSize: 50, cursor: 'pointer'}}
                            icon={faPlusCircle}
                          />
                          <input
                            onChange={event => attachImage (event)}
                            type="file"
                            id="file_product"
                            name="image2"
                            accept="image/gif,image/jpeg,image/jpg,image/png"
                            multiple=""
                            data-original-title="upload photos"
                            hidden
                          />

                        </label>
                      </Col>

                    </Row>
                    {/*
                                            <div className='top-banner'>
                                            <img src = {fruitSlide} alt='' />
                                        </div>
                                       


                                

                    <div className="add-slide-btn-div">
                      <button className="add-slide-btn change-image-btn">
                        Change Image
                      </button>
                      <button className="add-slide-btn remove-image-btn">
                        Remove Image
                      </button>
                    </div>
                     */}

                    <label>Heading</label>
                    <Row className="add-slide-lower">
                      <Col md={8}>
                        <input className="add-slide-lower-input" type="text" />
                      </Col>
                      <Col md={4}>
                        <div className="color-div">
                          <input type="color" />
                          <label style={{fontSize: '11px', color: '#272D3B'}}>
                            Text Color <br /> <span>#D9D9D9</span>
                          </label>
                        </div>
                      </Col>
                    </Row>

                    <label>Subheading</label>
                    <Row className="add-slide-lower">
                      <Col md={8}>
                        <input className="add-slide-lower-input" type="text" />
                      </Col>
                      <Col md={4}>
                        <div className="color-div">
                          <input type="color" />
                          <label style={{fontSize: '11px', color: '#272D3B'}}>
                            Text Color <br /> <span>#D9D9D9</span>
                          </label>
                        </div>
                      </Col>
                    </Row>

                    <label>Text Alignment</label>
                    <Row className="add-slide-lower">
                      <Col md={8}>
                        <select
                          className="add-slide-lower-input"
                          style={{background: 'none'}}
                        >
                          <option />
                          <option>Center Alignment</option>
                          <option>Left Alignment</option>
                          <option>Right Alignment</option>
                        </select>
                      </Col>
                      <Col md={4} />
                    </Row>

                    <hr />

                    <label>Button 1 Label</label>
                    <Row className="add-slide-lower">
                      <Col md={8}>
                        <input className="add-slide-lower-input" type="text" />
                      </Col>
                      <Col md={4} />
                    </Row>

                    <label>Button 1 Link</label>
                    <Row className="add-slide-lower">
                      <Col md={8}>
                        <input className="add-slide-lower-input" type="text" />
                      </Col>
                      <Col md={4} />
                    </Row>

                    <label>Button 1 Style</label>
                    <Row className="add-slide-lower">
                      <Col md={8}>
                        <select
                          className="add-slide-lower-input"
                          style={{background: 'none'}}
                        >
                          <option />
                          <option>White</option>
                          <option>Primary</option>
                          <option>Secondary</option>
                        </select>
                      </Col>
                      <Col md={4} />
                    </Row>
                    <label>Button 2 Label</label>
                    <Row className="add-slide-lower">
                      <Col md={8}>
                        <input className="add-slide-lower-input" type="text" />
                      </Col>
                      <Col md={4} />
                    </Row>

                    <label>Button 2 Link</label>
                    <Row className="add-slide-lower">
                      <Col md={8}>
                        <input className="add-slide-lower-input" type="text" />
                      </Col>
                      <Col md={4} />
                    </Row>

                    <label>Button 2 Style</label>
                    <Row className="add-slide-lower">
                      <Col md={8}>
                        <select
                          className="add-slide-lower-input"
                          style={{background: 'none'}}
                        >
                          <option />
                          <option>White</option>
                          <option>Primary</option>
                          <option>Secondary</option>
                        </select>
                      </Col>
                      <Col md={4} />
                    </Row>

                    <hr />

                    <div style={{marginBottom: '30px'}}>
                      <h3>OVERLAY</h3><br />

                      <input
                        type="checkbox"
                        id="overlay"
                        name="overlay"
                        value="Show Overlay"
                      />
                      <label for="overlay">&emsp; Show Overlay</label>
                      <br />
                      <br />

                      <div style={{display: 'flex', alignItems: 'center'}}>
                        <input type="color" value="#3EB971" />&emsp;
                        <span style={{fontSize: '11px', color: '#272D3B'}}>
                          Overlay Color <br /> <span>#D9D9D9</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

     

    </div>
  );
};

export default AddMainSlide;
