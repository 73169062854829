import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Row, Col, Form, Button, Modal} from 'react-bootstrap';
import {
  faChevronRight,
  faExclamationTriangle,
} from '@fortawesome/fontawesome-free-solid';

//import tpro from '../../assets/images/tpro.png';
import AdminHeader from '../AdminHeader';
//import ProgressBar from '@ramonak/react-progress-bar';
//import ReactStars from 'react-rating-stars-component';
import Sidebar from '../Sidebar';
import Request from '../utls/request';
import {useParams} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import ToastObject from '../shared/toast';
import config from '../utls/constant';
import ImageGallery from './ImageGallery';

const SinglePendingProduct = () => {
  const [data, setData] = useState ({});
  const [isActive, setIsActive] = useState (true);

  const [show, setShow] = useState (false);

  const [message, setMessage] = useState ('APPROVE');
  const [gallery, setGallery] = useState (false);
  const [show_category, setShowCategory] = useState (false);
  const [categories, setCategories] = useState ([]);
  const [category_value, setCategoryValue] = useState (false);
  const [change_cat, setChangeCat] = useState (false);

  const [reject_message, setRejectMessage] = useState ('REJECT');
  const {id} = useParams ();

  const handleCloseCategory = () => {
    setShowCategory (false);
  };

  const history = useHistory ();

  const selectElement = () => {
    const elem = categories.map (item => {
      return <option value={item.id}>{item.category_name}</option>;
    });
    return elem;
  };

  const getProductDetail = () => {
    Request.getMethod (`product-view/${id}`)
      .then (response => {
        //console.log (response.data, '...');
        setData (response.data.details[0]);
        setGallery (response.data.gallery);
        setIsActive (false);
        setCategories (response.data.categories);
        setCategoryValue (response.data.details[0].category_id);
        //alert(response.data.details[0].category_id)
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  const handleClose = () => setShow (false);

  const deleteProduct = () => {
    setShow (false);
    Request.getMethod (`delete-product/${id}`)
      .then (response => {
        ToastObject.ToastSuccess ('Product deleted Succesfully!!');

        history.push ('/rejectedproducts');
        //getPro();
        //setShow(false)

        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        //console.log (error.response);
      });
  };

  const confirmDelete = () => {
    setShow (true);
  };

  const approveProduct = () => {
    setMessage ('sending..');

    Request.getMethod (`approve-product/${id}`)
      .then (response => {
        if (response.data.result) {
          ToastObject.ToastSuccess ('Product approved !!!');
          history.push (`/pendingproducts`);
        }
      })
      .catch (error => {
        console.log (error);
      });
  };

  const rejectProduct = () => {
    setRejectMessage ('sending..');

    Request.getMethod (`reject-product/${id}`)
      .then (response => {
        if (response.data.result) {
          ToastObject.ToastSuccess ('Product Rejected Successfully!!!');
          history.push (`/pendingproducts`);
        }
      })
      .catch (error => {
        setRejectMessage ('REJECT');
        ToastObject.ToastError (
          'Something went wrong, please try again latter'
        );
        console.log (error);
      });
  };

  const saveCategory = () => {
    const body = {
      product_id: id,
      category_id: category_value,
    };

    // console.log(body)
    if (data.category_id == category_value) {
      ToastObject.ToastError ('No change detected');

      return;
    }

    setChangeCat (true);

    Request.postMethod ('change-category', body)
      .then (response => {
        if (response) {
          window.location.reload ();
        }
      })
      .catch (error => {
        console.log (error.response);
      });

    //alert(category_value)
  };

  React.useEffect (() => {
    getProductDetail ();
  }, []);

  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>
          {/* <Col></Col> */}
          <div className="userManager-cont">
            <div>
              <div className="display-admin">
                <div className="admin-title">
                  PRODUCTS
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Products View <FontAwesomeIcon icon={faChevronRight} />
                  </span>
                  <span style={{fontSize: '14px', fontWeight: '400'}}>
                    Single Pending Product
                  </span>
                </div>
              </div>
            </div>
            <div
              className="overflow"
              style={{height: '80vh', paddingTop: '1rem'}}
            >
              <Row>
                <Col md={2}>
                  <div style={{display: 'block', textAlign: 'center'}}>

                    <ImageGallery gallery={gallery} />
                  </div>
                </Col>
                <Col md={4}>
                  <img
                    src={`${config.domain_name}/storage/product/${data.picture}`}
                    className="Mpro"
                    alt=""
                  />
                  <div style={{display: 'flex', justifyContent: 'center'}}>

                    {data.is_approved == 0 || data.is_approved == 2
                      ? <Button
                          className="product-details-btn"
                          style={{
                            color: 'red',
                            minWidth: '45%',
                            minWidth: '45%',
                          }}
                          onClick={approveProduct}
                          disabled={message !== 'APPROVE'}
                        >
                          {message}
                        </Button>
                      : null}

                    {data.is_approved == 2
                      ? <Button
                          className="product-details-btn-second"
                          onClick={confirmDelete}
                          style={{borderColor: 'white', minWidth: '45%'}}
                        >
                          DELETE
                        </Button>
                      : null}

                    {data.is_approved == 0 || data.is_approved == 1
                      ? <Button
                          className="product-details-btn-second"
                          onClick={rejectProduct}
                          style={{borderColor: 'white', minWidth: '45%'}}
                          disabled={reject_message != 'REJECT'}
                        >
                          {reject_message}
                        </Button>
                      : null}

                  </div>
                </Col>
                <Col md={6} style={{}}>

                  <div>
                    <h4 style={{fontWeight: '900'}}>{data.product_title}</h4>
                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Product Owner</h6>
                      </Col>
                      <Col><h6>{data.full_name}</h6></Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Category</h6>
                      </Col>
                      <Col style={{display: 'flex', alignItems: 'center'}}>
                        <h6>{data.catgory_name}</h6><Button
                          variant="primary"
                          onClick={() => setShowCategory (true)}
                          style={{
                            backgroundColor: '#3EB971',
                            border: 'none',
                            marginLeft: 5,
                          }}
                        >
                          Change{' '}
                        </Button>
                      </Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Phone</h6>
                      </Col>
                      <Col>
                        <h6>
                          {' '}{' '}
                          {' '}
                          {data.country_code +
                            (data.phone && data.phone.substring (1))}
                        </h6>
                      </Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Email</h6>
                      </Col>
                      <Col><h6>{data.email}</h6></Col>
                    </Row>
                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col>
                        <h6 style={{fontWeight: '700'}}>Posted On</h6>
                      </Col>
                      <Col><h6>{data.created_at}</h6></Col>
                    </Row>
                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col><h6 style={{fontWeight: '700'}}>Location</h6></Col>
                      <Col><h6>{data.address}</h6></Col>
                    </Row>

                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col><h6 style={{fontWeight: '700'}}>Price</h6></Col>
                      <Col><h6>{data.price}</h6></Col>
                    </Row>
                    <Row style={{alignItems: 'center', padding: 6}}>
                      <Col><h6 style={{fontWeight: '700'}}>Viewed:</h6></Col>
                      <Col><h6>{data.view_count}</h6></Col>
                    </Row>
                  </div>
                  <br />
                  {/*
                  <h6 style={{fontWeight: '700'}}>Rate & Reviews</h6>
                  */}

                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{padding:'30px'}}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color: '#FF0000', fontWeight: 'bold'}}>
            <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION!!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color: '#FF0000', fontSize: '11px'}}>
          Once you've deleted the record , the User will no longer be available. This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            style={{backgroundColor: '#D3D3D3', border: 'none'}}
          >
            No!
          </Button>
          <Button
            variant="primary"
            onClick={deleteProduct}
            style={{
              marginRight: '20px',
              backgroundColor: '#3EB971',
              border: 'none',
            }}
          >
            Yes!
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={show_category} centered onHide={handleCloseCategory}>
        <Modal.Header closeButton>
          <Modal.Title>Change Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Group className="mb-3">
            <Form.Label>Select Category</Form.Label>
            <Form.Select
              onChange={e => setCategoryValue (e.target.value)}
              value={category_value}
            >
              {selectElement ()}
            </Form.Select>
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCategory}>
            Close
          </Button>

          {change_cat
            ? <Button
                variant="primary"
                style={{
                  backgroundColor: '#3EB971',
                  border: 'none',
                  marginLeft: 5,
                }}
                onClick={saveCategory}
                disabled
              >
                Saving ....
              </Button>
            : <Button
                variant="primary"
                style={{
                  backgroundColor: '#3EB971',
                  border: 'none',
                  marginLeft: 5,
                }}
                onClick={saveCategory}
              >
                Save Changes
              </Button>}

        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default SinglePendingProduct;
