import React, {useState} from 'react';
import {Modal, Button, Form} from 'react-bootstrap';
import ToastObject from '../shared/toast';
import Request from '../utls/request';

function PageModal (props) {
  const [name, setName] = useState ('');
  const [isLoading, setLoading] = useState (false);
  const [message, setMessage] = useState ('Add Page');

  const setNameText = e => {
    setName (e.target.value);
  };



  const addPage = () => {
    const body = {
      name: name,
    };
    //props.getPage ();
    //return

    setMessage ('Sending..');
    setLoading (true);

    Request.postMethod ('save-page', body)
      .then (response => {
        if (response.data.result) {
          props.getPage ();
          ToastObject.ToastSuccess ('Page added succesfully');
        }

        setMessage ('Add Page');
        setName ('');
        setLoading (false);
      })
      .catch (error => {
        setMessage ('Add Page');
        setLoading (false);
      });
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        closeButton
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{marginLeft: '10rem'}}
        >
          Add Page
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={e => e.preventDefault ()}>

          <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Page Name</Form.Label>
            <Form.Control
              type="Text"
              value={name}
              placeholder="Title"
              onChange={setNameText}
            />
          </Form.Group>

          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button
              variant="success"
              onClick={addPage}
              type="submit"
              disabled={isLoading}
            >
              {message}
            </Button>
          </div>
        </Form>
      </Modal.Body>

    </Modal>
  );
}

export default PageModal;
