import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Table, Button, Modal} from 'react-bootstrap';
import {
  faSkype,
  faInstagram,
  faFacebookF,
} from '@fortawesome/free-brands-svg-icons';
import {
  faChevronRight,
  faClone,
  faCog,
  faCubes,
  faExclamationTriangle,
  faInfoCircle,
  faShieldAlt,
  faSignOutAlt,
  faSlidersH,
  faUser,
  faUserNinja,
} from '@fortawesome/fontawesome-free-solid';

import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import '../assets/css/dashboard.css';
import AdminHeader from './AdminHeader';
import config from './utls/constant';
import Request from './utls/request';
import placeholder from '../assets/images/placeholder.jpeg';
import auth from './auth/aut';
import ToastObject from './shared/toast';
import ugandaFlag from '../assets/images/ugandaFlag.png';
import Sidebar from './Sidebar';
import LoadingOverlay from 'react-loading-overlay';
import {ExportToExcel} from './products/ExportToExcel';

const AdminView = props => {
  const history = useHistory ();

  const handleDetails = id => {
    props.history.push (`adminaddadmin/${id}`);
  };

  const [data, setData] = useState (null);
  const [show, setShow] = useState (false);
  const [isActive, setIsActive] = useState (true);

  const [user_id, setUserId] = useState (false);

  const handleClose = () => setShow (false);
  const handleShow = () => setShow (true);

  const redirectEdit = (e, id) => {
    const check_char = e.target.value[0];
    if (check_char == 'e') {
      //remove string
      const id = Number (e.target.value.replace ('e', ''));

      props.history.push (`adminaddadmin/${id}`);
      // alert("Mundruku");
      return;
    }
    if (check_char == 'd') {
      setShow (true);
      setUserId (id);
      return;
    }
  };

  const deleteAdmin = () => {
    Request.getMethod (`delete-user/${user_id}`)
      .then (response => {
        ToastObject.ToastSuccess ('Admin deleted Succesfully!!');
        getAdmin ();
        setShow (false);

        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        console.log (error.response);
      });
  };

  const getAdmin = () => {
    Request.getMethod ('get-admins')
      .then (response => {
        //console.log(response.data)
        setData (response.data.user.data);
        setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  React.useEffect (() => {
    getAdmin ();
  }, []);

  // Export tableto excel
  const fileName = 'FarmsellAdminList';

  return (
    <LoadingOverlay active={isActive} spinner text="Loading data...">
      <div className="admin-bg">
        <div className="main">
          <AdminHeader />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className="admin-side">
              <Sidebar />
            </div>
            <div className="userManager-cont">
              <div>
                <div className="display-admin">
                  <div className="admin-title">
                    ADMINS' MANAGER
                    <Link
                      to="/"
                      className="admib-nav"
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        textDecoration: 'none',
                        borderLeft: '1px solid #D3D3D3',
                        paddingLeft: '3px',
                        marginLeft: '3px',
                        color: '#D3D3D3',
                      }}
                    >
                      Home <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: '#272D3B',
                        fontWeight: 'bold',
                      }}
                    >
                      Admin View
                    </span>
                  </div>
                  <div className="input-icons display-admin">
                    <i className="fa fa-user icon" />
                    <input
                      className="input-field"
                      type="text"
                      placeholder="Search by user's name, email,..."
                    />
                  </div>
                </div>
                <div className="display-admin" style={{marginBottom: '10px'}}>
                  <div className="display-admin admin-show">
                    <span>Show &nbsp;</span>
                    <select name="show" id="show" className="sort-by">
                      <option value="5" style={{width: '100px'}}>5</option>
                      <option value="10">10</option>
                      <option value="15">15</option>
                    </select>
                    <span>&nbsp; Entries</span>
                  </div>
                  <div className="display-admin admin-show">
                    {auth.authData ().permission &&
                      JSON.parse (auth.authData ().permission).includes (1)
                      ? <Link
                          to="./adminaddadmin/add"
                          className="add-btn"
                          style={{marginRight: '10px'}}
                        >
                          Add Admin
                        </Link>
                      : null}
                    <ExportToExcel apiData={data} fileName={fileName} />
                    {/*<span className='add-btn '>Export</span>*/}

                  </div>
                </div>
              </div>
              <div className="autoscroll">
                <Table borderless hover responsive style={{fontSize: '12px'}}>
                  <thead style={{background: '#E3E9EF', color: '#B3B8BD'}}>
                    <tr style={{textAlign: 'left'}}>
                      <th className="col-sm-2">User ID</th>
                      <th className="col-sm-3" />
                      <th className="col-sm-2">Country</th>
                      <th className="col-sm-2">E-Mail</th>
                      <th className="col-sm-2">Socials</th>
                      <th className="col-sm-1">Status</th>
                      <th className="col-sm-2">Online</th>
                      <th className="col-sm-1">Action</th>
                    </tr>
                  </thead>
                  {data
                    ? data.map ((cont, index) => {
                        return (
                          <tbody>
                            <tr style={{textAlign: 'left'}}>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  {index + 1}
                                </div>
                              </td>
                              <td style={{textAlign: 'left'}}>
                                <img
                                  src={
                                    cont.picture
                                      ? `${config.domain_name}/storage/${cont.picture}`
                                      : cont.avatar_google
                                          ? cont.avatar_google
                                          : placeholder
                                  }
                                  alt=""
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '50%',
                                  }}
                                />
                                {' '}
                                <a
                                  href="#"
                                  onClick={()=>handleDetails(cont.id)}
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                  }}
                                >
                                  {cont.full_name}
                                </a>
                              </td>
                              <td>
                                <img
                                  src={ugandaFlag}
                                  alt=""
                                  style={{
                                    width: '25px',
                                    height: '15px',
                                    borderRadius: '30%',
                                    paddingRight: '2px',
                                  }}
                                />
                                {' '}
                                Uganda
                              </td>
                              <td>{cont.email}</td>
                              <td>
                                <FontAwesomeIcon icon={faSkype} />
                                <FontAwesomeIcon icon={faInstagram} />
                                <FontAwesomeIcon icon={faFacebookF} />
                              </td>
                              <td>{cont.status}</td>
                              <td style={{width: '100px'}}>{cont.online}</td>
                              {auth.authData ().permission &&
                                JSON.parse (
                                  auth.authData ().permission
                                ).includes (1)
                                ? <td>
                                    {/* <Button className='admin-del-btn' size="sm">Delete</Button> */}
                                    <select
                                      onChange={e => redirectEdit (e, cont.id)}
                                      name="show"
                                      id="show"
                                      style={{
                                        width: '65px',
                                        border: '1px solid #EC2A82',
                                        height: '20px',
                                        borderRadius: '5px',
                                        color: '#EC2A82',
                                      }}
                                    >
                                      <option value="" style={{width: '100px'}}>
                                        Action
                                      </option>
                                      <option
                                        value={`d${cont.id}`}
                                        style={{width: '100px'}}
                                      >
                                        Delete
                                      </option>
                                      <option value={`s${cont.id}`}>
                                        Suspend
                                      </option>
                                      <option value={`e${cont.id}`}>
                                        Edit
                                      </option>
                                    </select>
                                  </td>
                                : null}
                            </tr>
                          </tbody>
                        );
                      })
                    : null}
                </Table>
              </div>

            </div>
          </div>
        </div>

        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          // style={{padding:'30px'}}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{color: '#FF0000', fontWeight: 'bold'}}>
              <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION!!!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{color: '#FF0000', fontSize: '11px'}}>
            Once you've deleted the record , the User will no longer be available. This action cannot be undone.
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleClose}
              style={{backgroundColor: '#D3D3D3', border: 'none'}}
            >
              No!
            </Button>
            <Button
              variant="primary"
              onClick={deleteAdmin}
              style={{
                marginRight: '20px',
                backgroundColor: '#3EB971',
                border: 'none',
              }}
            >
              Yes!
            </Button>
          </Modal.Footer>
        </Modal>

      </div>
    </LoadingOverlay>
  );
};

export default AdminView;
