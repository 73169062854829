import React, {useState} from 'react';
import {SidebarData} from './SidebarData';
import team2 from '../assets/images/placeholder.jpeg';
import auth from './auth/aut';
import LogoutIcon from '@mui/icons-material/Logout';
import LogoutOut from './shared/logout';

const Sidebar = () => {
  const logOut = () => {
    // alert("Ok")
    LogoutOut ();
  };

  return (
    <div>
      <div className="admin1-sidebar" style={{height: '80vh'}}>
        <div className="adminUser">
          <p className="adminUserPar">
            <img
              src={team2}
              alt=""
              id="imgIcon"
              style={{width: '40px', height: '40px', borderRadius: '50%'}}
            />
            {' '}
            &emsp;
            {' '}
            <span id="adminName">
              {auth.authData () ? auth.authData ().full_name : ''}
            </span>
          </p>
          <p className="pborder" />
        </div>

        <ul className="sidebarUl">

          {SidebarData.map ((val, key) => {
            if (val.title) {
              return (
                <li
                  key={key}
                  className="sidebarIl"
                  id={window.location.pathname === val.link ? 'active' : ''}
                  onClick={() => {
                    window.location.pathname = val.link;
                  }}
                >
                  <div id="icon"> {val.icon} </div>
                  <div id="title"> {val.title} </div>

                </li>
              );
              // <MenuItems items={val} key={key} />
            }
          })}
          <li style={{paddingBottom: '4rem'}}>
            <div className="logoutDiv" style={{cursor: 'pointer'}}>
              <div className="logInnDiv" onClick={logOut}>
                <LogoutIcon id="icon" />
                <span id="out">Logout</span>
              </div>
            </div>
          </li>
        </ul>

      </div>
    </div>
  );
};
export default Sidebar;
