import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { faChevronRight } from '@fortawesome/fontawesome-free-solid';
import team2 from '../assets/images/team2.png';
import abdul from '../assets/images/abdul.png';
import aysha from '../assets/images/aysha.png';
import { Link } from 'react-router-dom';
import AdminHeader from './AdminHeader';
import Sidebar from './Sidebar';

const AdminTestimonials = () => {
    return (
        <div className='admin-bg'>
            <div className='main' >
                <AdminHeader />
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className='admin-side'>
                            <Sidebar />
                        </div>
                        {/* <Col></Col> */}
                        <div className='userManager-cont' >
                            <div>
                                <div className='display-admin'>
                                    <div className='admin-title'>
                                        PAGES
                                        <Link to="/" className='admib-nav' style={{ fontSize: '14px', fontWeight: '400', textDecoration:'none', borderLeft:'1px solid #D3D3D3', paddingLeft:'3px', marginLeft:'3px', color:'#D3D3D3' }}>Home <FontAwesomeIcon icon={faChevronRight} /></Link>
                                        <Link to="/adminproducts" className='admib-nav' style={{ fontSize: '14px', fontWeight: '400', textDecoration:'none', borderLeft:'1px solid #D3D3D3', paddingLeft:'3px', marginLeft:'3px', color:'#D3D3D3' }}>Page View <FontAwesomeIcon icon={faChevronRight} /></Link>
                                        <span style={{ fontSize: '14px', fontWeight: '400', color:'#272D3B', fontWeight:'bold' }}>Testimonials</span>
                                    </div>
                                     <div class="display-admin">
                                         <Link to="./adminaddtestimonial">
                                             <Button size="sm" style={{background:'#3EB971', border:'none', fontWeight:'bold'}}>Add Testimonials</Button>
                                         </Link>
                                    </div>
                                </div>
                            </div>
                        <div className='overflow'  style={{ height: '55vh' }} >
                            <Row>
                                <Col md={4}>
                                    <Card className='tes-card'>
                                        <Card.Img variant="top" src={abdul} alt='' style={{width:'96px', height:'96px', borderRadius:'50%', alignSelf:'center'}} />
                                        <Card.Body>
                                            <div style={{textAlign:'center'}}>
                                                <Card.Title>Daniel Rock</Card.Title>
                                                <span style={{fontSize:'13px'}}>Kampala</span>
                                            </div>
                                           
                                            <Card.Text style={{marginTop:'10px', color:'#707070', fontSize:'13px'}}>
                                            Ever since I started using FARMSELL, my shopping for fruits and vegetables has moved from the market to my door. The Application has enabled me to connect the genuine sellers with very fresh products and the price is friendly as well.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card className='tes-card'>
                                        <Card.Img variant="top" src={team2} alt='' style={{width:'96px', height:'96px', borderRadius:'50%', alignSelf:'center'}} />
                                        <Card.Body>
                                            <div style={{textAlign:'center'}}>
                                                <Card.Title>Daniel Rock</Card.Title>
                                                <span style={{fontSize:'13px'}}>Kampala</span>
                                            </div>
                                           
                                            <Card.Text style={{marginTop:'10px', color:'#707070', fontSize:'13px'}}>
                                            Ever since I started using FARMSELL, my shopping for fruits and vegetables has moved from the market to my door. The Application has enabled me to connect the genuine sellers with very fresh products and the price is friendly as well.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card className='tes-card'>
                                        <Card.Img variant="top" src={aysha} alt='' style={{width:'96px', height:'96px', borderRadius:'50%', alignSelf:'center'}} />
                                        <Card.Body>
                                            <div style={{textAlign:'center'}}>
                                                <Card.Title>Daniel Rock</Card.Title>
                                                <span style={{fontSize:'13px'}}>Kampala</span>
                                            </div>
                                           
                                            <Card.Text style={{marginTop:'10px', color:'#707070', fontSize:'13px'}}>
                                            Ever since I started using FARMSELL, my shopping for fruits and vegetables has moved from the market to my door. The Application has enabled me to connect the genuine sellers with very fresh products and the price is friendly as well.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                            
                            
                        </div>
                </div>
            </div>
            
        </div>
    )
}

export default AdminTestimonials;