import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleRight} from '@fortawesome/fontawesome-free-solid';
import React, {useState} from 'react';
import Sidebar from '../Sidebar';
import AdminHeader from '../AdminHeader';
import '../../assets/css/dashboard.css';

import Request from '../utls/request';
import {useHistory, useParams} from 'react-router-dom';
import LoadingOverlay from 'react-loading-overlay';
import config from '../utls/constant';
import placeholder from '../../assets/images/placeholder.jpeg';
import {Button, Modal} from 'react-bootstrap';
import {faExclamationTriangle} from '@fortawesome/fontawesome-free-solid';
import ToastObject from '../shared/toast';
import Permission from '../shared/adminPermissionCheck';

const UserView = () => {
  const {id} = useParams ();
  const [data, setData] = useState (null);
  const [isActive, setIsActive] = useState (true);
  const [show, setShow] = useState (false);
  const [message, setMessage] = useState (false);

  const handleClose = () => setShow (false);
  const history = useHistory ();

  const confirmDelete = () => {
    setShow (true);
    //setUserId (id);
  };

  const handleUserProcucts = () => {
    history.push (`/userproducts/${id}`);
  };

  const verifyUser = () => {
    setMessage (true);

    Request.getMethod (`verify-user/${id}`)
      .then (response => {
        ToastObject.ToastSuccess ('User deleted Succesfully!!');
        // localStorage.setItem ('user', JSON.stringify (response.data.user));
        window.location.reload ();
        //setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  const deleteUser = () => {
    setShow (false);

    Request.getMethod (`delete-user/${id}`)
      .then (response => {
        ToastObject.ToastSuccess ('User deleted Succesfully!!');
        //const object = new Date ();

        //getUser ({value: object.getFullYear (), name: 'year'}, 20);

        setShow (false);
        history.push (`/usersmanager`);

        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        console.log (error.response);
      });
  };

  const getUser = () => {
    //console.log (body);

    Request.getMethod (`get-user/${id}`)
      .then (response => {
        // console.log (response.data.data);
        setData (response.data.data);
        setIsActive (false);
        //setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  React.useEffect (() => {
    getUser ();
  }, []);

  return (
    <LoadingOverlay active={isActive} spinner text="Loading data...">
      <div className="admin-bg">
        <div className="main">
          <AdminHeader />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{width: '25vw'}}>
              <Sidebar />
            </div>
            <div className="userManager-cont">
              <div>
                <div className="display-admin">
                  <div className="admin-title">
                    USERS' MANAGER
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        borderLeft: '1px solid #D3D3D3',
                        paddingLeft: '3px',
                        marginLeft: '3px',
                        color: '#D3D3D3',
                      }}
                    >
                      Home <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        borderLeft: '1px solid #D3D3D3',
                        paddingLeft: '3px',
                        marginLeft: '3px',
                        color: '#D3D3D3',
                      }}
                    >
                      User View<FontAwesomeIcon icon={faAngleRight} />
                    </span>
                    <span style={{fontSize: '14px', fontWeight: '400'}}>
                      View User
                    </span>
                  </div>
                  {data
                    ? <div className="follow">
                        <div className="item">
                          <span>{data.followers}</span><p>Followers</p>
                        </div>
                        <div className="item">
                          <span>{data.following}</span><p>Following</p>
                        </div>

                        {/* <button style={{border:'0',borderRadius:'5px'}} >SAVE CHANGES</button> */}
                      </div>
                    : null}
                </div>

              </div>
              {data
                ? <div className="content-container autoscroll">
                    <div className=" row user-details">
                      <div className="col-md-5">
                        <img
                          src={
                            data.picture
                              ? `${config.domain_name}/storage/${data.picture}`
                              : data.avatar_google
                                  ? data.avatar_google
                                  : placeholder
                          }
                          alt="User image"
                        />

                        <div className="user-btn">

                          {Permission ()
                            ? <button
                                type="button"
                                className="delete-user"
                                onClick={verifyUser}
                                disabled={message ? true : false}
                              >
                                {data.verification_status
                                  ? `${message ? 'Un Verifying..' : 'Un Verify'}`
                                  : `${message ? 'Verifying..' : 'Verify'}`}

                              </button>
                            : null}

                          {Permission ()
                            ? <button
                                type="button"
                                className="verify-user"
                                onClick={confirmDelete}
                                style={{color: 'red'}}
                              >
                                Delete{' '}
                              </button>
                            : null}
                          <div>
                            <button
                              onClick={handleUserProcucts}
                              type="button"
                              className="view-products"
                            >
                              View Products ({data.product})
                            </button>
                          </div>
                        </div>

                      </div>
                      <div className="col-md-7 user-portifolio">
                        <ul>
                          <li>
                            <div className="category">
                              Name:
                              {' '}
                              <span className="description">
                                {data.full_name}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="category">
                              E-mail
                              {' '}
                              <span className="description">
                                {data.email}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="category">
                              Account Status
                              {' '}
                              <span className="description">
                                {data.verification_status
                                  ? 'Verified'
                                  : 'Unverified'}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="category">
                              Country
                              {' '}
                              <span className="description">
                                {data.country}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="category">
                              Is Email verified?
                              {' '}
                              <span className="description">
                                {data.email_verified_at ? 'Yes' : 'No'}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="category">
                              Registration Type
                              {' '}
                              <span className="description">
                                {data.registered_from == 1
                                  ? 'Web'
                                  : 'Mobile App'}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="category">
                              Most Use
                              <span className="description">Mobile App</span>
                            </div>
                          </li>
                          <li>
                            <div className="category">
                              Registered Number
                              {' '}
                              <span className="description">
                                { data.country_code+ (data.phone&&data.phone.substring(1))}

                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="category">
                              Mobile Number Notification
                              <span className="description">
                                {data.notify_number ? 'On' : 'Off'}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="category">
                              Email Notification
                              <span className="description">
                                {data.notify_email ? 'On' : 'Off'}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="category">
                              Opened on
                              <span className="description">
                                {data.created}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="category">
                              Updated on
                              <span className="description">
                                {data.updated}
                              </span>
                            </div>
                          </li>

                        </ul>
                        <div className="user-about">
                          <h5>About user</h5>

                          {data.about_user}
                        </div>
                      </div>

                    </div>
                  </div>
                : null}

            </div>

          </div>
        </div>

      </div>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        // style={{padding:'30px'}}
      >
        <Modal.Header closeButton>
          <Modal.Title style={{color: '#FF0000', fontWeight: 'bold'}}>
            <FontAwesomeIcon icon={faExclamationTriangle} /> ATTENTION!!!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{color: '#FF0000', fontSize: '11px'}}>
          Once you've deleted the record , the User will no longer be available. This action cannot be undone.
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleClose}
            style={{backgroundColor: '#D3D3D3', border: 'none'}}
          >
            No!
          </Button>
          <Button
            variant="primary"
            onClick={deleteUser}
            style={{
              marginRight: '20px',
              backgroundColor: '#3EB971',
              border: 'none',
            }}
          >
            Yes!
          </Button>
        </Modal.Footer>
      </Modal>

    </LoadingOverlay>
  );
};

export default UserView;
