import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Button, Modal, Form} from 'react-bootstrap';
import {ChevronLeft} from 'react-bootstrap-icons';
import aut from './aut';
//import { Link } from 'react-router-dom';

import '../../assets/css/dashboard.css';
import Request from '../utls/request';
import ToastObject from '../shared/toast';

const Login = props => {
  const status = localStorage.getItem ('r_status');

  const [formData, setFormData] = useState ({
    email: '',
    password: '',
  });

  const [message, setMessage] = useState ('SIGN IN');
  const [loading, setLoader] = useState (false);

  const [recover, setRecoverStatus] = useState (status);

  const [recoverData, setRecoverData] = useState ({
    email: '',
    code: '',
    password: '',
    confirm_password: '',
  });

  const [loader_rec, setLoaderRec] = useState ({
    email: 'Send',
    code: 'Submit code',
    password: 'Send',
  });

  const [show, setShow] = useState (false);

  const handleClose = () => setShow (false);
  const handleShow = () => setShow (true);

  const [error, setError] = useState ({
    email: '',
    password: '',
    message: '',
    reset_pass: '',
  });

  //Redirect back to the home page when the user is logged in
  if (aut.isAuthenticated) {
    props.history.push ('/');
  }

  /*
    const handleSignin = () => {
        window.location.href = "/dashboardadmin"
    }
*/

  const handleReset = () => {
    localStorage.removeItem ('r_status');
    setRecoverStatus (false);
  };

  const onTextChange = e => {
    setFormData (prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    setError ({
      email: '',
      password: '',
      message: '',
    });

    // console.log(formData)
  };

  const onRecoverChange = e => {
    setRecoverData (prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    setError (prev => ({
      ...prev,
      reset_pass: '',
    }));

    // console.log(formData)
  };

  const handleEmail = () => {
    if (!recoverData.email) {
      setError (prev => ({
        ...prev,
        reset_pass: 'Field is required',
      }));
      return;
    }
    const body = recoverData;
    setLoaderRec (prev => ({
      ...prev,
      email: 'Sending',
    }));

    Request.postMethod ('send-otp', body)
      .then (response => {
        setLoaderRec (prev => ({
          ...prev,
          email: 'Send',
        }));
        if (response.data.error) {
          ToastObject.ToastError (response.data.error);
          return;
        }
        if (response.data.result) {
          localStorage.setItem ('r_status', 1);
          localStorage.setItem ('email', body.email);
          ToastObject.ToastSuccess ('Reset code sent to your email address');
          setRecoverStatus (1);
        }
      })
      .catch (error => {});
  };

  const handlePassword = () => {
    if (!recoverData.password || !recoverData.confirm_password) {
      setError (prev => ({...prev, reset_pass: 'field is required'}));
      return;
    }

    const regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;

    if (regex.exec (recoverData.password) == null) {
      setError (prev => ({
        ...prev,
        reset_pass: 'password should contain at least eight characters, including at least one number and includes both lower and uppercase letters and special characters',
      }));
      return;
    }

    if (recoverData.password !== recoverData.confirm_password) {
      setError (prev => ({
        ...prev,
        reset_pass: 'Password does not match',
      }));
      return;
    }
    const body = recoverData;

    setLoaderRec (prev => ({
      ...prev,
      password: 'Sending',
    }));

    body.email = localStorage.getItem ('email');

    Request.postMethod ('recover/password', body)
      .then (response => {
        setLoaderRec (prev => ({
          ...prev,
          password: 'Send',
        }));
        if (response.data.result) {
          localStorage.removeItem ('r_status');
          //localStorage.setItem ('email', body.email);
          ToastObject.ToastSuccess ('Password changed, You can now login');
          handleClose ();
          setRecoverStatus (false);
        }
      })
      .catch (error => {
        console.log (error.response);
      });
  };

  const handleCode = () => {
    if (!recoverData.code) {
      setError (prev => ({
        ...prev,
        reset_pass: 'Field is required',
      }));
      return;
    }
    const body = recoverData;

    setLoaderRec (prev => ({
      ...prev,
      code: 'Sending',
    }));

    Request.postMethod ('validate-token', body)
      .then (response => {
        setLoaderRec (prev => ({
          ...prev,
          code: 'Submit code',
        }));
        if (response.data.result) {
          localStorage.setItem ('r_status', 2);
          //localStorage.setItem ('email', body.email);
          ToastObject.ToastSuccess ('Code Verified');
          setRecoverStatus (2);
        } else {
          ToastObject.ToastError ('Invalid reset code');
        }
      })
      .catch (error => {});
  };

  const loginUser = event => {
    event.preventDefault ();
    if (!formData.email && !formData.password) {
      setError ({
        email: 'Email field can not be empty',
        password: 'Password  field can not empty',
      });
      return;
    }
    if (!formData.email) {
      setError ({
        email: 'Email field can not be empty',
      });
      return;
    }

    if (!formData.password) {
      setError ({
        password: 'Password  field can not empty',
      });
      return;
    }

    setLoader (true);
    setMessage ('Sending...');

    const body = formData;

    Request.postMethod ('login', body)
      .then (response => {
        if (
          response.data.error ||
          response.data.message ||
          response.data.user_type_error
        ) {
          setError ({
            email: '',
            password: '',
            message: response.data.error
              ? response.data.error
              : response.data.message
                  ? response.data.message
                  : response.data.user_type_error,
          });
          setLoader (false);
          setMessage ('SIGN IN');
          return;
        }

        localStorage.setItem ('user', JSON.stringify (response.data.user));
        localStorage.setItem ('Token', response.data.access_token);
        aut.login ();

        // props.history.push('/');
        window.location.reload ();
      })
      .catch (error => {
        //console.log(error.response)
      });
  };

  return (
    <div className="admin1-bg">
      <div className="sign-in-form">
        <h3>SIGN IN <FontAwesomeIcon icon={ChevronLeft} /> </h3>

        <div class="custom_validation">
          {error.message}
        </div>
        <Form autocomplete="off" onSubmit={loginUser}>
          <Form.Group className="mb-3 label">
            <input
              name="email"
              onChange={e => onTextChange (e)}
              type="email"
              placeholder="E-mail"
              className="login-input"
              autocomplete="off"
            />
            <div class="custom_validation">
              {error.email}
            </div>
          </Form.Group>

          <Form.Group className="mb-3 label">
            <Form.Control
              type="password"
              name="password"
              onChange={e => onTextChange (e)}
              placeholder="Password"
              className="login-input"
              style={{width: '100%'}}
              autocomplete="off"
            />

            <div class="custom_validation">
              {error.password}
            </div>
          </Form.Group>

          <Button
            type="submit"
            disabled={loading ? true : false}
            className="admin1-sigin-btn label"
            size="lg"
            block
          >
            {message}
          </Button>
          <div style={{cursor: 'pointer'}} onClick={handleShow}>
            <p>Forgot Password?</p>
          </div>

        </Form>
      </div>

      <Modal show={show} onHide={handleClose} centered>

        <Modal.Body>
          {!recover
            ? <Form.Group className="mb-6" controlId="formBasicEmail">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Input email"
                  value={recoverData.email}
                  onChange={e => onRecoverChange (e)}
                  required
                />
                {error.reset_pass
                  ? <Form.Control.Feedback
                      type="invalid"
                      style={{display: 'block'}}
                    >
                      {error.reset_pass}
                    </Form.Control.Feedback>
                  : null}
              </Form.Group>
            : null}

          {recover == 1
            ? <Form.Group className="mb-6" controlId="formBasicEmail">
                <Form.Label>Enter the code sent to your email</Form.Label>
                <Form.Control
                  type="number"
                  name="code"
                  placeholder="Reset Code"
                  value={recoverData.code}
                  onChange={e => onRecoverChange (e)}
                  required
                />

                {error.reset_pass
                  ? <Form.Control.Feedback
                      type="invalid"
                      style={{display: 'block'}}
                    >
                      {error.reset_pass}
                    </Form.Control.Feedback>
                  : null}

              </Form.Group>
            : null}

          {recover == 2
            ? <Form.Group className="mb-6" controlId="formBasicEmail">
                <Form.Label>New Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={recoverData.password}
                  onChange={e => onRecoverChange (e)}
                  required
                />

              </Form.Group>
            : null}

          {recover == 2
            ? <Form.Group className="mb-6" controlId="formBasicEmail">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  name="confirm_password"
                  placeholder="Confirm Password"
                  value={recoverData.confirm_password}
                  onChange={e => onRecoverChange (e)}
                  required
                />
                {error.reset_pass
                  ? <Form.Control.Feedback
                      type="invalid"
                      style={{display: 'block'}}
                    >
                      {error.reset_pass}
                    </Form.Control.Feedback>
                  : null}
              </Form.Group>
            : null}
        </Modal.Body>
        <Modal.Footer>

          {!recover
            ? <Button
                onClick={handleEmail}
                disabled={loader_rec.email !== 'Send'}
              >
                {loader_rec.email}
              </Button>
            : null}
          {recover == 1
            ? <div>
                <Button
                  variant="secondary"
                  style={{marginRight: 5}}
                  onClick={handleReset}
                >
                  Resend code{' '}
                </Button>
                <Button
                  onClick={handleCode}
                  disabled={loader_rec.code !== 'Submit code'}
                >
                  {loader_rec.code}
                </Button>

              </div>
            : null}
          {recover == 2
            ? <Button
                onClick={handlePassword}
                disabled={loader_rec.password !== 'Send'}
              >
                {loader_rec.password}
              </Button>
            : null}

        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default Login;
