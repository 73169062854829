import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import {faChevronRight} from '@fortawesome/fontawesome-free-solid';
import {Link} from 'react-router-dom';
import AdminHeader from '../AdminHeader';
import Sidebar from '../Sidebar';
import Request from '../utls/request';
import ToastObject from '../shared/toast';
import {faClose} from '@fortawesome/free-solid-svg-icons';
import config from '../utls/constant';

const SiteSettings = () => {
  const [formData, setFormData] = useState ({
    email: '',
    site_name: '',
    site_logo: '',
    phone: '',
    site_domain: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    youtube: '',
  });

  const [formFileData, setFormFileData] = useState ({
    site_logo: '',

    site_logo_footer: '',
    site_logo_fav: '',
  });

  const [message, setMessage] = useState ('Save');

  const onTextChange = e => {
    setFormData (prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    // console.log(formData)
  };

  const removeImage = index => {
    if (index == 1) {
      setFormFileData (prevState => ({
        ...prevState,
        site_logo: '',
      }));
    }

    if (index == 2) {
      setFormFileData (prevState => ({
        ...prevState,
        site_logo_footer: '',
      }));
    }

    if (index == 3) {
      setFormFileData (prevState => ({
        ...prevState,
        site_logo_fav: '',
      }));
    }
  };

  const onFileChange = e => {
    setFormFileData (prevState => ({
      ...prevState,
      [e.target.name]: e.target.files[0],
    }));

    //console.log(formFileData)
  };

  const getSettings = () => {
    Request.getMethod ('get-settings')
      .then (response => {
        console.log (response.data);
        if (response.data.setting.length !== 0) {
          //alert("ene")
          setFormData (response.data.setting[0]);
        }
      })
      .catch (error => {});
  };

  const saveInformation = () => {
    const body = {...formData, ...formFileData};
    setMessage ('Sending..');
    Request.postMethod ('save-settings', body)
      .then (response => {
        ToastObject.ToastSuccess (response.data.result);
        setMessage ('Save');
      })
      .catch (error => {
        ToastObject.ToastError ('Oops an error occured, try again latter');
        setMessage ('Save');
        console.log (error.response);
      });

    //console.log (body);
  };

  React.useEffect (() => {
    getSettings ();
  }, []);

  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>
          <div className="userManager-cont">
            <div>
              <div className="display-admin">
                <div className="admin-title">
                  SITE SETTINGS
                  <Link
                    to="/"
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#272D3B',
                      fontWeight: 'bold',
                    }}
                  >
                    Site Information
                  </span>
                </div>
              </div>
            </div>
            <div>
              <Row>
                <Col md={3}>
                  <div className="site-set-side" style={{}}>
                    <p style={{background: '#3EB971', color: '#fff'}}>
                      SITE INFORMATION
                    </p>
                    <p><Link to="./password-management">Password Settings</Link></p>
            
                    <p><Link to="./adminmainslide">MAIN BANNERS</Link></p>
                    <p>
                      <Link to="./categoriesbanner">CATEGORIES' BANNERS</Link>
                    </p>
                    <p><Link to="./languages">LANGUAGE</Link></p>
                    <p><Link to="./gif">GIF</Link></p>
                  </div>
                </Col>
                <Col />
                <Col md={8} className='autoscroll'>
                  <p className="site-set-m-title">SITE INFORMATION</p>
                  <Form>
                    <Row>

                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Site Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="site_name"
                            placeholder="Name"
                            value={formData.site_name}
                            onChange={e => onTextChange (e)}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Site Domain Name</Form.Label>
                          <Form.Control
                            placeholder="Enter site Domain"
                            name="site_domain"
                            type="text"
                            value={formData.site_domain}
                            onChange={e => onTextChange (e)}
                          />
                        </Form.Group>

                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            name="email"
                            type="email"
                            onChange={e => onTextChange (e)}
                            value={formData.email}
                            placeholder="Enter email"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Phone</Form.Label>
                          <Form.Control
                            placeholder="Phone"
                            name="phone"
                            type="text"
                            value={formData.phone}
                            onChange={e => onTextChange (e)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>

                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Facebook page url</Form.Label>
                          <Form.Control
                            type="text"
                            name="facebook"
                            value={formData.facebook}
                            onChange={e => onTextChange (e)}
                            placeholder="Facebook page url"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Twitter page url</Form.Label>
                          <Form.Control
                            name="twitter"
                            placeholder="Page url"
                            type="text"
                            value={formData.twitter}
                            onChange={e => onTextChange (e)}
                          />
                        </Form.Group>

                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Youtube Page url</Form.Label>
                          <Form.Control
                            name="youtube"
                            type="text"
                            value={formData.youtube}
                            onChange={e => onTextChange (e)}
                            placeholder="Enter Youtube page url"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Linkedin page Url</Form.Label>
                          <Form.Control
                            placeholder="Enter Linkedin page url"
                            name="linkedin"
                            type="text"
                            onChange={e => onTextChange (e)}
                            value={formData.linkedin}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>

                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Site Logo</Form.Label>

                            {formFileData.site_logo
                              ? <div className="top-banner artist-collection-photo">
                                  <FontAwesomeIcon
                                    icon={faClose}
                                    className="close"
                                    onClick={() => removeImage (1)}
                                  />

                                  <img
                                    src={URL.createObjectURL (
                                      formFileData.site_logo
                                    )}
                                    alt=""
                                    className="img-thumbnail"
                                  />
                                </div>
                              : formData.site_logo
                                  ? <div className="top-banner artist-collection-photo">
                                      <FontAwesomeIcon
                                        icon={faClose}
                                        className="close"
                                        onClick={() => removeImage (3)}
                                      />

                                      <img
                                        src={`${config.domain_name}/storage/settings/${formData.site_logo}`}
                                        alt=""
                                        className="img-thumbnail"
                                      />
                                    </div>
                                  : null}

                            <Form.Control
                              onChange={e => onFileChange (e)}
                              name="site_logo"
                              type="file"
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Site Footer Logo</Form.Label>

                            {formFileData.site_logo_footer
                              ? <div className="top-banner artist-collection-photo">
                                  <FontAwesomeIcon
                                    icon={faClose}
                                    className="close"
                                    onClick={() => removeImage (2)}
                                  />

                                  <img
                                    src={URL.createObjectURL (
                                      formFileData.site_logo_footer
                                    )}
                                    alt=""
                                    className="img-thumbnail"
                                  />
                                </div>
                              : formData.site_logo_footer
                                  ? <div className="top-banner artist-collection-photo">
                                      <FontAwesomeIcon
                                        icon={faClose}
                                        className="close"
                                        onClick={() => removeImage (3)}
                                      />

                                      <img
                                        src={`${config.domain_name}/storage/settings/${formData.site_logo_footer}`}
                                        alt=""
                                        className="img-thumbnail"
                                      />
                                    </div>
                                  : null}

                            <Form.Control
                              onChange={e => onFileChange (e)}
                              name="site_logo_footer"
                              type="file"
                            />
                          </Form.Group>
                        </Form.Group>

                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Site Favicon</Form.Label>

                          {formFileData.site_logo_fav
                            ? <div className="top-banner artist-collection-photo">
                                <FontAwesomeIcon
                                  icon={faClose}
                                  className="close"
                                  onClick={() => removeImage (3)}
                                />

                                <img
                                  src={URL.createObjectURL (
                                    formFileData.site_logo_fav
                                  )}
                                  alt=""
                                  className="img-thumbnail"
                                />
                              </div>
                            : formData.site_logo_fav
                                ? <div className="top-banner artist-collection-photo">
                                    <FontAwesomeIcon
                                      icon={faClose}
                                      className="close"
                                      onClick={() => removeImage (3)}
                                    />

                                    <img
                                      src={`${config.domain_name}/storage/settings/${formData.site_logo_fav}`}
                                      alt=""
                                      className="img-thumbnail"
                                    />
                                  </div>
                                : null}

                          <Form.Control
                            onChange={e => onFileChange (e)}
                            name="site_logo_fav"
                            type="file"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="reset-submit-btn" style={{}}>
                      <Button className="site-set-btn" size="sm">Reset</Button>
                      <Button
                        className="site-set-btn"
                        onClick={saveInformation}
                        size="sm"
                        disabled={message !== 'Save' ? true : false}
                      >
                        {message}
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

export default SiteSettings;
