import React from "react";
import { toast } from 'react-toastify';


const ToastObject = {

    ToastSuccess(message) {

        toast.success(message)

    },


    ToastError(message) {

        toast.error(message)

    },
    ToastWarning(message) {

        toast.warning(message)
    },

    ToastInfo(message) {
        toast.info(message)
    }


}

export default ToastObject;