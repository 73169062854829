import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faSearch, faUser } from '@fortawesome/fontawesome-free-solid';

const AdminHeader = () => {

    const goToFarmsell = () => {
        window.location.href = "https://farmsell.org"
    }

    const goHome = () => {
        window.location.href = "/"
    }

    return(
        <div className='admin-header display-admin' style={{}}>
            <span style={{background:'#3EB971', color:"#fff", borderRadius:'6px', fontWeight:'700', padding:'5px 15px', cursor:'pointer'}} onClick={goHome} >Farmsell</span>
            <div className='display-admin'>
                <span style={{background:'#fff', color:"#272D3B", borderRadius:'6px', fontWeight:'700', padding:'5px 15px', margin:'3px', cursor:'pointer'}} onClick={goToFarmsell} >View Website</span>
                <FontAwesomeIcon icon={faSearch} className='admin-header-icon' />
                <FontAwesomeIcon icon={faBell} className='admin-header-icon'/>
                <FontAwesomeIcon icon={faUser} className='admin-header-icon'/>
            </div>
        </div>
    )
}
export default AdminHeader;