import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState, useRef} from 'react';
import {Row, Col, Button, Form} from 'react-bootstrap';
import {
  faChevronRight,
  faPlusCircle,
} from '@fortawesome/fontawesome-free-solid';
import AdminHeader from '../AdminHeader';
import 'arch-editor/dist/arch-editor.css';

import JoditEditor from 'jodit-react';
import Sidebar from '../Sidebar';
import {Link, useParams} from 'react-router-dom';
import Request from '../utls/request';
import ToastObject from '../shared/toast';

const AdminAddPage = () => {
  const editor = useRef (null);

  const [page_content, setContent] = useState ('');

  const {id} = useParams ();

  const [add_status, setAddStatus] = useState (false);

  const [multi_title, setMultiTitle] = useState (false);
  const [editData, setEditData] = useState ([]);

  const [edit_status, setEditStatus] = useState ('');
  const [is_loading, setLoader] = useState (false);
  const [is_loading_del, setLoaderDel] = useState (false);

  const [formArray, setCount] = useState ([
    {
      title: '',
      page_content: '',
    },
  ]);

  const [page_type, setPageType] = useState (0);
  const [formData, setFormData] = useState ({
    page_type: 1,
    title: '',
  });

  const addField = () => {
    setCount (prevState => [
      ...prevState,
      {
        page_type: 1,
        title: '',
      },
    ]);
  };

  const config = {
    readonly: false,
    height: 300,
    width: 800,
  };

  const handleUpdate = contents => {
    // console.log (contents);

    setContent (contents);
  };

  const disCard = () => {
    setAddStatus (false);
    setPageType (0);
    setContent ('');

    setFormData ({
      page_type: 1,
      title: '',
    });
    setCount ([
      {
        title: '',
        page_content: '',
      },
    ]);
  };

  const setUpdatestatus = e => {
    if (e == 'add') {
      setAddStatus (true);
      setEditStatus ('');

      return;
    }
    const item = JSON.parse (e);
    //console.log(item)
    setPageType (item.page_type);
    //console.log (item);
    setEditStatus (item.id);
    if (item.page_type == 2) {
      setMultiTitle (true);
      setCount (JSON.parse (item.page_content));
      return;
    }
    if (item.page_type == 1) {
      setContent (JSON.parse (item.page_content));
    }

    // console.log(e.target.value)
  };

  const handleUpdateMul = (content, index) => {
    var data = formArray;
    //console.log (data);
    data[index] = {
      title: data[index].title,
      page_content: content,
    };

    setCount (data);
  };

  const handleUpdateMulTitle = (e, index) => {
    var data = formArray;
    //console.log(data)
    setMultiTitle (false);
    data[index] = {
      title: e.target.value,
      page_content: data[index].page_content,
    };
    setCount (data);
  };

  const onTextChangeSelect = e => {
    if (e == 'add') {
      //setAddStatus (true);
      return;
    }
    const item = JSON.parse (e);
    setFormData ({
      page_type: item.page_type,
      title: item.title,
    });

    console.log (formData);
    //setPageType (item.page_type);
  };

  const onTextChange = e => {
    setFormData (prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));

    //console.log (formData);
  };

  const getPageContent = () => {
    Request.getMethod (`get-page-content/${id}`)
      .then (response => {
        console.log (response);
        setEditData (response.data.page);

        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
      });
  };

  const deleteSubPage = () => {
    setLoaderDel (true);
    Request.getMethod (`delete-page-content/${edit_status}`).then (response => {
      if (response.data.result) {
        ToastObject.ToastSuccess ('Deleted succesfully');
        window.location.reload ();
      }
    });
  };

  const pageAdd = () => {
    const body = formData;
    if (body.page_type == 1) {
      body.page_content = JSON.stringify (page_content);
    }

    if (body.page_type == 2) {
      body.page_content = JSON.stringify (formArray);
    }
    body.id = edit_status;

    body.page_id = id;
    setLoader (true);
    Request.postMethod ('save-page-content', body)
      .then (r => {
        if (r.data.result && edit_status) {
          ToastObject.ToastSuccess ('Page content editted  successfully');
        } else {
          ToastObject.ToastSuccess ('Page content added successfully');
        }
        window.location.reload ();
      })
      .catch (error => {
        //alert("error")
        setLoader (false);
        ToastObject.ToastError ('Something went wrong, try again latter');
        console.log (error);
      });

    //console.log (body);
  };

  React.useEffect (() => {
    getPageContent ();
  }, []);

  return (
    <div className="admin-bg">
      <div className="main">
        <AdminHeader />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className="admin-side">
            <Sidebar />
          </div>
          <div className="userManager-cont">
            <div>
              <div className="display-admin">
                <div className="admin-title">
                  PAGES
                  <Link
                    to="/"
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Home <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <Link
                    to="/adminpages"
                    className="admib-nav"
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      textDecoration: 'none',
                      borderLeft: '1px solid #D3D3D3',
                      paddingLeft: '3px',
                      marginLeft: '3px',
                      color: '#D3D3D3',
                    }}
                  >
                    Page View <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                  <span
                    style={{
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#272D3B',
                      fontWeight: 'bold',
                    }}
                  >
                    Add Content
                  </span>
                </div>
                <div className="display-admin">

                  <Button
                    className="page-btn"
                    size="sm"
                    style={{background: '#3EB971'}}
                    onClick={pageAdd}
                    disabled={is_loading}
                  >
                    {is_loading ? 'Saving..' : 'Save'}

                  </Button>
                  <Button
                    className="page-btn"
                    size="sm"
                    style={{background: '#3EB971'}}
                    onClick={disCard}
                  >
                    Discard
                  </Button>

                  {edit_status
                    ? <Button
                        className="page-btn"
                        size="sm"
                        style={{background: '#FF0000', marginLeft: '1rem'}}
                        onClick={deleteSubPage}
                        disabled={is_loading_del}
                      >
                        {is_loading_del ? 'Deleting' : 'Delete'}
                      </Button>
                    : null}
                </div>
              </div>
            </div>

            <div className="autoscr">
              <Form>
                <Row>

                  <Col md={5}>
                    <span style={{color: '#707070', fontSize: '0.8rem'}}>
                      Select Page Type
                    </span>
                    <br />
                    <select
                      name="page_type"
                      id="show"
                      style={{
                        width: '90%',
                        border: '1px solid #707070',
                        height: '37px',
                        borderRadius: '2px',
                      }}
                      value={page_type}
                      onChange={e => {
                        setPageType (e.target.value);
                        onTextChange (e);
                      }}
                    >
                      <option value="" style={{width: '100px'}}>Select</option>
                      <option value="1">Single Page</option>
                      <option value="2">Multiple Page</option>

                    </select>
                  </Col>

                  {!add_status
                    ? <Col md={5}>
                        <span style={{color: '#707070', fontSize: '0.8rem'}}>
                          Select Page Content
                        </span>
                        <br />
                        <div style={{display: 'flex'}}>
                          <select
                            name="title"
                            id="show"
                            style={{
                              width: '90%',
                              border: '1px solid #707070',
                              height: '37px',
                              borderRadius: '2px',
                            }}
                            onChange={e => {
                              onTextChangeSelect (e.target.value);
                              setUpdatestatus (e.target.value);
                            }}
                          >
                            <option value="" style={{width: '100px'}}>
                              Select
                            </option>

                            {editData.map ((c, index) => (
                              <option
                                key={c.id + index}
                                value={JSON.stringify (c)}
                              >
                                {c.title}
                              </option>
                            ))}
                            <option value="add">Add New Page</option>

                          </select>
                          {formData.title
                            ? <Form.Check
                                style={{marginLeft: 5}}
                                onClick={() => setAddStatus (true)}
                                type="checkbox"
                                label="Edit"
                              />
                            : null}
                        </div>
                      </Col>
                    : <Col md={4}>
                        <span style={{color: '#707070', fontSize: '0.8rem'}}>
                          Page Sub title
                        </span>
                        <br />
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="text"
                            placeholder="Title"
                            name="title"
                            value={formData.title}
                            style={{
                              border: '1px solid #707070',
                              height: '37px',
                              borderRadius: '2px',
                            }}
                            onChange={onTextChange}
                          />
                        </Form.Group>
                      </Col>}

                </Row>

                {page_type == 1
                  ? <Row style={{paddingTop: 10}}>
                      <JoditEditor
                        ref={editor}
                        value={page_content}
                        config={config}
                        onBlur={handleUpdate}
                        onChange={newContent => {}}
                      />
                    </Row>
                  : null}

                {page_type == 2
                  ? formArray.map ((item, index) => (
                      <div key={index + 'mundruku'}>
                        <Row>

                          <Col md={4} className="mt-3">
                            <span
                              style={{color: '#707070', fontSize: '0.8rem'}}
                            >
                              Content title
                            </span>
                            <br />
                            <Form.Group
                              className="mb-2"
                              controlId="formBasicEmail"
                            >

                              {multi_title
                                ? <Form.Control
                                    type="text"
                                    placeholder="Title"
                                    value={item.title}
                                    style={{
                                      border: '1px solid #707070',
                                      height: '37px',
                                      borderRadius: '2px',
                                    }}
                                    onChange={e =>
                                      handleUpdateMulTitle (e, index)}
                                  />
                                : <Form.Control
                                    type="text"
                                    placeholder="Title"
                                    style={{
                                      border: '1px solid #707070',
                                      height: '37px',
                                      borderRadius: '2px',
                                    }}
                                    onChange={e =>
                                      handleUpdateMulTitle (e, index)}
                                  />}

                            </Form.Group>
                          </Col>
                        </Row>

                        <Row style={{paddingTop: 10}}>
                          <JoditEditor
                            config={config}
                            value={item.page_content}
                            onBlur={newContent =>
                              handleUpdateMul (newContent, index)}
                            onChange={newContent => {}}
                          />
                        </Row>
                      </div>
                    ))
                  : null}

                {page_type == 2
                  ? <div style={{display: 'flex'}}>
                      <div
                        style={{
                          marginLeft: 'auto',

                          marginRight: '55px',
                          marginTop: 15,
                        }}
                        onClick={addField}
                      >

                        <FontAwesomeIcon
                          style={{fontSize: '45px', cursor: 'pointer'}}
                          icon={faPlusCircle}
                        />
                      </div>
                    </div>
                  : null}

              </Form>
            </div>

          </div>
        </div>
      </div>

    </div>
  );
};

export default AdminAddPage;
