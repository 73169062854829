import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {Table, Button, Form, Row, Col, Modal} from 'react-bootstrap';
import Sidebar from '../Sidebar';

import AdminHeader from '../AdminHeader';
import '../../assets/css/dashboard.css';

import '../../assets/css/modal.css';
import Request from '../utls/request';

import auth from '../auth/aut';
import ToastObject from '../shared/toast';

import LoadingOverlay from 'react-loading-overlay';

import {useHistory} from 'react-router-dom';

var User = auth.authData ();

var UserToken = User ? User.Token : null;

const FarmsellAgent = () => {
  const [message, setMessage] = useState ('');
  const [subject, setSubject] = useState ('');
  const [email, setEmail] = useState ('');

  const [error, setError] = useState ('');

  const [data, setData] = useState ([]);
  const [show, setShow] = useState (false);

  const [sending, setSend] = useState (false);

  const [viewData, setViewData] = useState ({});

  const [isActive, setIsActive] = useState (true);

  //pagination

  //const [sort_by, setSortBy] = useState();
  const handleClose = () => setShow (false);

  const confirmDelete = id => {};

  const history = useHistory ();

  const handleDetails = id => {
    history.push (`/userview/${id}`);
  };

  ///getCountry ('+256');

  const exportToCSV = () => {
    const XLSX = require ('xlsx');

    var dataArray = [];

    //push the header for the first record

    var header = [
      ' ',
      '1.0 Personal Details ',
      '2.0 Contact Details ',
      '3.0 Social Media Numbers ',
      '4.0 Education ',
      '5.0 Work Experience ',
      '6.0 Soft Professional Skills ',
      '7.0 Motivation',
      '8.0 Knowing Farmsell',
    ];
    dataArray.push (header);

    // created sub headers

    var sub_header = [
      ' ',
      ' 1.1 First Name',
      ' 1.2 Other Names ',
      ' 1.3 Gender',
      ' 1.4 Country',
      ' 1.5 District ',
      ' 1.6 Sub County ',
      ' 2.1 Phone',
      ' 2.2 WhatSap',
      ' 2.3 Email Address',
      ' 2.4 Skype Address',
      ' 2.5 Physical Address',
      ' 2.6 Current Residence',
      ' 3.1 TikTok',
      ' 3.3 Twitter ',
      ' 3.3 Pinterest',
      ' 3.4 LinkedIn',
      ' 3.5 Facebook',
      ' 3.6 YouTube',
      ' 3.7 Instagram',
      ' 3.8 Additional ',
      ' 4.1 Primary Level ',
      ' 4.2 Secondary - O’ Level ',
      ' 4.3 Secondary - A’ Level  ',
      ' 4.4 Highest Qualifications',
      ' 4.5 Do you allow Farmsell to verify your academic documents.',
      ' 5.1 Employment Status',
      ' 5.2 Year of working ',
      ' 5.3 Current/Last Employer ',
      ' 5.4 Current/Last Job Title',
      ' 5.5 Current/Last Supervisor',
      ' 5.6 Current/Last Duty Station',
      ' 6.1 Teamwork',
      ' 6.2 Content Writing',
      ' 6.3 Problem Solving',
      ' 6.4 Communication',
      ' 6.5 Attention to Details',
      ' 6.6 People mobilization',
      ' 6.7 Emotional intelligence',
      ' 6.8 Professional Work Ethic',
      ' 6.9 Social Media Engagement',
      ' 7.1 Why do you want to join Farmsell',
      ' 7.1 On a scale of (0-5); How likely can you volunteer2 with Farmsell',
      ' 8.1 Where was the first time you learned about this opportunity',
      ' 8.1  Before the advert for Farmsell Agents, had you ever heard about Farmsell? ',
    ];

    //push the sub header to an array
    dataArray.push (sub_header);

    ///first data

    data.map ((item, index) => {
      //push records into an array

      //initialize data

      var first = item.personal_details ? item.personal_details : {};
      var second = item.contact_details ? item.contact_details : {};
      var third = item.social_media_numbers ? item.social_media_numbers : {};
      var forth = item.education ? item.education : {};
      var fifth = item.experience ? item.experience : {};
      var sixth = item.soft_profesional_skills
        ? item.soft_profesional_skills
        : {};
      var seventh = item.motivation ? item.motivation : {};
      var eighth = item.knowing_farmsell ? item.knowing_farmsell : {};

      var record = [
        `FA0000${index + 1}`,
        first.name,
        first.other_names,
        first.gender,
        first.country,
        first.district,
        first.sub_county,
        second.phone,
        second.whatsapp_phone,
        second.email,
        second.skype_address,
        second.physical_address,
        second.residence,
        third.tik_tok,
        third.twitter,
        third.pininterest,
        third.linkedin,
        third.facebook,
        third.youtube,
        third.instagram,
        third.others,
        forth.primary_level,
        forth.o_level,
        forth.a_level,
        forth.highest_qualification,
        forth.verification_status,
        fifth.employment_status,
        fifth.years_of_experience,
        fifth.current_employer,
        fifth.last_job_title,
        fifth.last_suppervisor,
        fifth.duty_station,
        sixth.team_work,
        sixth.content_writing,
        sixth.problem_solving,
        sixth.communication,
        sixth.attention_to_details,
        sixth.people_mobilization,
        sixth.emotional_intelligence,
        sixth.professional_work_ethic,
        sixth.social_media_engament,
        seventh.reason_for_joining,
        seventh.scale_voluteer,
        eighth.known_from,
        eighth.known_before,
      ];

      dataArray.push (record);
    });

    var worksheet = XLSX.utils.aoa_to_sheet (dataArray);

    const workbook = XLSX.utils.book_new ();

    XLSX.utils.book_append_sheet (workbook, worksheet, 'Dates');

    XLSX.writeFile (workbook, 'Presidents.xlsx');
  };

  const getApplication = () => {
    //alert (filter_count);
    //alert("Trecking")

    Request.getMethod ('get-agent-applications')
      .then (response => {
        console.log (response.data);

        setData (response.data.result);

        //alert(JSON.stringify(response.data.email))

        //alert('ok')
        setIsActive (false);
        //alert(JSON.stringify(data))
        // console.log(response.data)
      })
      .catch (error => {
        // console.log(error.response)
        // alert("Ok")
      });
  };

  React.useEffect (() => {
    getApplication ();

    // alert('mm')

    //console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  }, []);

  // Export tableto excel

  const setView = data => {
    setViewData ({...viewData, ...data});
    setShow (true);
  };

  return (
    <LoadingOverlay active={isActive} spinner text="Loading data...">
      <div className="admin-bg">
        <div className="main">
          <AdminHeader />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{width: '25vw'}}>
              <Sidebar />
            </div>

            <div className="userManager-cont" id="top_div_item">
              <div style={{display:'flex'}}>

                <div
                  className="display-admin"
                  style={{
                    marginBottom: '10px',
                    marginLeft:'auto'
                  }}
                >

                  <span onClick={e => exportToCSV ()} className="add-btn">
                    Export
                  </span>

                </div>

              </div>

              <div className="adminScroll">

                <Table borderless hover responsive style={{fontSize: '12px'}}>
                  <thead style={{background: '#E3E9EF', color: '#B3B8BD'}}>
                    <tr style={{textAlign: 'left', width: '100%'}}>
                      <th className="col-sm-3">No</th>

                      <th className="col-sm-3">First Name</th>
                      <th className="col-sm-3">Other Names</th>

                      <th className="col-sm-3">
                        Form
                        {' '}
                        {viewData.personal_details &&
                          viewData.personal_details.sub_county}
                      </th>

                      <th className="col-sm-3">Created</th>
                      <th className="col-sm-3" />

                    </tr>
                  </thead>
                  {data
                    ? data.map ((cont, index) => {
                        return (
                          <tbody key={index}>
                            <tr
                              style={{textAlign: 'left', cursor: 'pointer'}}
                              //onClick={handleDetails}
                            >
                              <td>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  {index + 1}
                                </div>
                              </td>
                              <td
                                style={{textAlign: 'left'}}
                                onClick={() => setView (cont)}
                              >

                                {' '}
                                <a
                                  href="#"
                                  style={{
                                    cursor: 'pointer',
                                    textDecoration: 'none',
                                  }}
                                >
                                  {cont.personal_details &&
                                    cont.personal_details.name}
                                </a>
                              </td>

                              <td>
                                {cont.personal_details &&
                                  cont.personal_details.other_names}
                              </td>

                              <td>
                                {cont.form_stage == 9
                                  ? <span style={{color: 'green'}}>
                                      Completed
                                    </span>
                                  : 'Incomplete'}
                              </td>

                              <td style={{fontSize: '10px'}}>
                                {cont.created_at}
                              </td>

                              <td>
                                <Button
                                  className="admin-del-btn"
                                  size="sm"
                                  onClick={() => setView (cont)}
                                >
                                  View Email
                                </Button>
                              </td>

                            </tr>
                          </tbody>
                        );
                      })
                    : null}
                </Table>

              </div>

            </div>
          </div>
        </div>

        <Modal
          style={{maxWidth: '95% !important'}}
          show={show}
          onHide={handleClose}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Application Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div style={{border: '1px solid #dee2e6', marginTop: '1rem'}}>

              <div style={{marginTop: 10}}>

                <h4>1.0 Personal Details </h4>
              </div>

              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  1.1 First Name*
                </div>

                <div class="col-6 padding">
                  {viewData.personal_details && viewData.personal_details.name}
                </div>
              </div>

              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  1.2 Other Names{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.personal_details &&
                    viewData.personal_details.other_names}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  1.3 Gender*{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.personal_details &&
                    viewData.personal_details.gender}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  1.4 Country*
                </div>

                <div class="col-6 padding">
                  {viewData.personal_details &&
                    viewData.personal_details.country}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  1.5 District*
                </div>

                <div class="col-6 padding">
                  {viewData.personal_details &&
                    viewData.personal_details.district}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  1.6 Sub County *
                </div>

                <div class="col-6 padding">
                  {viewData.personal_details &&
                    viewData.personal_details.sub_county}
                </div>
              </div>

              <div style={{marginTop: 10}}>

                <h4>2.0 Contact Details </h4>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  2.1 Phone*{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.contact_details && viewData.contact_details.phone}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  2.2 WhatsApp*{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.contact_details &&
                    viewData.contact_details.whatsapp_phone}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  2.3  Email Address*
                </div>

                <div class="col-6 padding">
                  {viewData.contact_details && viewData.contact_details.email}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  2.4 Skype Address
                </div>

                <div class="col-6 padding">
                  {viewData.contact_details &&
                    viewData.contact_details.skype_address}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  2.5 Physical Address{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.contact_details &&
                    viewData.contact_details.physical_address}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  2.6 Current Residence*{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.contact_details &&
                    viewData.contact_details.residence}
                </div>
              </div>

              <div style={{marginTop: 10}}>

                <h4>3.0 Social Media Numbers </h4>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  3.1 TikTok*
                </div>

                <div class="col-6 padding">
                  {viewData.social_media_numbers &&
                    viewData.social_media_numbers.tik_tok}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  3.2 Twitter *{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.social_media_numbers &&
                    viewData.social_media_numbers.twitter}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  3.3 Pinterest*{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.social_media_numbers &&
                    viewData.social_media_numbers.pininterest}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  3.4 LinkedIn*{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.social_media_numbers &&
                    viewData.social_media_numbers.linkedin}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  3.5 Facebook*
                </div>

                <div class="col-6 padding">
                  {viewData.social_media_numbers &&
                    viewData.social_media_numbers.facebook}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  3.6 YouTube*{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.social_media_numbers &&
                    viewData.social_media_numbers.youtube}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  3.7 Instagram*
                </div>

                <div class="col-6 padding">
                  {viewData.social_media_numbers &&
                    viewData.social_media_numbers.instagram}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  3.8 Others
                </div>

                <div class="col-6 padding">
                  {viewData.social_media_numbers &&
                    viewData.social_media_numbers.others}
                </div>
              </div>

              <div style={{marginTop: 10}}>

                <h4>4.0 Education </h4>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  4.1 Primary Level *{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.education && viewData.education.primary_level}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  4.2 Secondary - O’ Level *{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.education && viewData.education.o_level}
                </div>
              </div>

              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  4.3 Secondary - A’ Level{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.education && viewData.education.a_level}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  4.4 Highest Qualifications *
                </div>

                <div class="col-6 padding">
                  {viewData.education &&
                    viewData.education.highest_qualification}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  4.5 Do you allow Farmsell to verify your academic documents*
                </div>

                <div class="col-6 padding">
                  {viewData.education && viewData.education.verification_status}
                </div>
              </div>

              <div style={{marginTop: 10}}>

                <h4>5.0 Work Experience </h4>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  5.1 Employment Status*
                </div>

                <div class="col-6 padding">
                  {viewData.experience && viewData.experience.employment_status}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  5.2 Year of working{' '}
                </div>

                <div class="col-6 padding">
                  {viewData.experience &&
                    viewData.experience.years_of_experience}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  5.3 Current/Last Employer
                </div>

                <div class="col-6 padding">
                  {viewData.experience && viewData.experience.current_employer}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  5.4 Current/Last Job Title*
                </div>

                <div class="col-6 padding">
                  {viewData.experience && viewData.experience.last_job_title}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  5.5 Current/Last Supervisor
                </div>

                <div class="col-6 padding">
                  {viewData.experience && viewData.experience.last_suppervisor}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  5.6 Current/Last Duty Station
                </div>

                <div class="col-6 padding">
                  {viewData.experience && viewData.experience.duty_station}
                </div>
              </div>

              <div style={{marginTop: 10}}>

                <h4>6.0 Soft Professional Skills </h4>
              </div>

              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  6.1 Teamwork*
                </div>

                <div class="col-6 padding">
                  {viewData.soft_profesional_skills &&
                    viewData.soft_profesional_skills.team_work}
                </div>
              </div>

              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  6.2 Content Writing*
                </div>

                <div class="col-6 padding">
                  {viewData.soft_profesional_skills &&
                    viewData.soft_profesional_skills.content_writing}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  6.3 Problem Solving*
                </div>

                <div class="col-6 padding">
                  {viewData.soft_profesional_skills &&
                    viewData.soft_profesional_skills.problem_solving}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  6.4 Communication*
                </div>

                <div class="col-6 padding">
                  {viewData.soft_profesional_skills &&
                    viewData.soft_profesional_skills.communication}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  6.5 Attention to Details*
                </div>

                <div class="col-6 padding">
                  {viewData.soft_profesional_skills &&
                    viewData.soft_profesional_skills.attention_to_details}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  6.6 People mobilization *
                </div>

                <div class="col-6 padding">
                  {viewData.soft_profesional_skills &&
                    viewData.soft_profesional_skills.people_mobilization}
                </div>
              </div>

              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  6.7 Professional Work Ethic*
                </div>

                <div class="col-6 padding">
                  {viewData.soft_profesional_skills &&
                    viewData.soft_profesional_skills.professional_work_ethic}
                </div>
              </div>

              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  6.8 Emotional intelligence*
                </div>

                <div class="col-6 padding">
                  {viewData.soft_profesional_skills &&
                    viewData.soft_profesional_skills.emotional_intelligence}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  6.9 Social Media Engagement*
                </div>

                <div class="col-6 padding">
                  {viewData.soft_profesional_skills &&
                    viewData.soft_profesional_skills.social_media_engament}
                </div>
              </div>

              <div style={{marginTop: 10}}>

                <h4>7.0 Motivation </h4>
              </div>

              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  7.1 Please provide information for your motivation to join Farmsell.
                </div>

                <div class="col-6 padding">
                  {viewData.motivation &&
                    Array.isArray (viewData.motivation.reason_for_joining) &&
                    viewData.motivation.reason_for_joining.toString ()}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  7.2 On a scale of (0-5) How likely can you volunteer2 with Farmsell*

                </div>

                <div class="col-6 padding">
                  {viewData.motivation && viewData.motivation.scale_voluteer}
                </div>
              </div>

              <div style={{marginTop: 10}}>

                <h4>8.0 Knowing Farmsell </h4>
              </div>

              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  8.1 Where was the first time you learned about this opportunity*
                  {' '}
                </div>

                <div class="col-6 padding">
                  {viewData.knowing_farmsell &&
                    viewData.knowing_farmsell.known_from}
                </div>
              </div>
              <div class="border-bottom" style={{display: 'flex'}}>
                <div class="border-right padding col-6">
                  8.2 Before the advert for Farmsell Agents, had you ever heard about Farmsell?
                </div>

                <div class="col-6 padding">
                  {viewData.knowing_farmsell &&
                    viewData.knowing_farmsell.known_before}
                </div>
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer />
        </Modal>

      </div>
    </LoadingOverlay>
  );
};

export default FarmsellAgent;
