import React from 'react';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ListIcon from '@mui/icons-material/List';
import PasswordIcon from '@mui/icons-material/Password';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import Permission from './shared/adminPermissionCheck';

export const SidebarData = [
  {
    title: 'Dashboard - Home',
    icon: <DashboardCustomizeIcon />,
    link: '/',
    open: false,
  },

  //Disbale the admin side bar item when not permitted
  Permission ()
    ? {
        title: 'Admin',
        icon: <AdminPanelSettingsIcon />,
        link: '/adminview',
        open: false,
      }
    : {},

  Permission ()
    ? {
        title: 'Agent',
        icon: <AdminPanelSettingsIcon />,
        link: '/farmsell-agent',
        open: false,
      }
    : {},

    {
      title: 'Orders',
      icon: <PersonOutlineIcon />,
      link: '/orders',
      open: false,
    },
  
  {
    title: 'User',
    icon: <PersonOutlineIcon />,
    link: '/usersmanager',
    open: false,
  },
  {
    title: 'Products',
    icon: <AccountTreeIcon />,
    link: '/adminproducts',
    open: false,
  },
  {
    title: 'Pending Products',
    icon: <AssistantPhotoIcon />,
    link: '/pendingproducts',
    open: false,
  },
  {
    title: 'Categories',
    icon: <ListIcon />,
    link: '/admincategories',
    open: false,
  },
  {
    title: 'Direct Adverts',
    icon: <PasswordIcon />,
    link: '',
    open: false,
  },
  {
    title: 'Google Adverts',
    icon: <PasswordIcon />,
    link: '',
    open: false,
  },
  {
    title: 'Product Promo',
    icon: <PasswordIcon />,
    link: '',
    open: false,
  },
  {
    title: 'Notification',
    icon: <PasswordIcon />,
    link: '/user-notification',
    open: false,
  },
  {
    title: 'Engage',
    icon: <PasswordIcon />,
    link: '/phone-engagement-statistics',
    open: false,
  },
  {
    title: 'Pages',
    icon: <ErrorOutlineIcon />,
    link: '/adminpages',
    open: false,
  },
  {
    title: 'Site Settings',
    icon: <SettingsIcon />,
    link: '/sitesettings',
    open: false,
  },
  {
    title: 'Test PP',
    icon: <HelpOutlineIcon />,
    link: '',
    submenu: [
      {title: 'Pending', to: '#'},
      {title: 'Approved', to: '#'},
      {title: 'Rejected', to: '#'},
    ],
    open: false,
  },
  {
    title: 'Help',
    icon: <HelpOutlineIcon />,
    link: '',
    open: false,
  },
];
